<template>
  <div class="flex flex-col justify-center items-center gap-6 h-full">
    <h1>{{ $t('pageNotFound.header') }}</h1>

    <h3>{{ $t('pageNotFound.text') }}</h3>
  </div>
</template>

<script>
export default {
  name: 'PageNotFound',
};
</script>
