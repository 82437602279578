<template>
  <Modal
    :name="modalName"
    width="350"
    height="auto"
    :classes="['!rounded-xl']"
  >
    <div class="w-full h-3 bg-primary" />
    <div class="flex flex-col items-center gap-4 text-center px-3 py-4">
      <h2 class="w-7/12">{{ title }}</h2>

      <p v-if="message">{{ message }}</p>

      <Btn
        class="w-full"
        color="secondary"
        size="md"
        @click="onConfirm"
      >
        {{ okButton }}
      </Btn>

      <Btn
        class="w-full"
        prepend-icon="arrow-left"
        size="md"
        @click="onCancel"
      >
        {{ cancelButton }}
      </Btn>
    </div>
  </Modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('auth');

export default {
  name: 'ConfirmationModal',

  props: {
    id: {
      type: [String, Number],
      required: false,
    },
  },

  data() {
    return {
      modalName: this.id ? `confirmation-modal-${this.id}` : 'confirmation-modal',

      title: undefined,
      message: undefined,
      okButton: undefined,
      cancelButton: this.$t('cancel'),

      // Private variables
      resolvePromise: undefined,
      rejectPromise: undefined,
    };
  },

  methods: {
    show(opts = {}) {
      this.title = opts.title;
      this.message = opts.message;
      this.okButton = opts.okButton;
      if (opts.cancelButton) {
        this.cancelButton = opts.cancelButton;
      }

      this.$modal.show(this.modalName);

      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },

    onConfirm() {
      this.$modal.hide(this.modalName);
      this.resolvePromise(true);
    },

    onCancel() {
      this.$modal.hide(this.modalName);
      this.resolvePromise(false);
    },
  },
};
</script>

<style lang="scss" scoped>
  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    padding: 15px;
    z-index: 1000;
    border-radius: 30px;
  }
</style>
