<template>
  <Modal
    :name="modalName"
    width="100%"
    height="100%"
    :classes="['bg-overlay', 'text-light', '!rounded-none', '!max-w-md', 'mx-auto']"
    @before-open="beforeOpen"
    @closed="onClosed"
  >
    <div class="flex flex-col h-full">
      <div class="flex justify-between m-4">
        <div class="flex flex-col gap-2">
          <div class="font-medium">
            {{ header }}
          </div>
          <div>
            {{ hint }}
          </div>
        </div>

        <SvgIcon
          name="x"
          size="lg"
          @click="closeModal"
        />
      </div>

      <div class="flex-grow flex justify-center items-center">
        <img
          style="max-height: 500px"
          :src="selectedImage"
          :alt="selectedImage"
        >
      </div>

      <div
        v-if="!solo"
        class="flex gap-2 mx-3 mb-10 overflow-x-scroll"
      >
        <div
          v-for="(image, key) in images"
          :key="image"
          class="bg-cover bg-center w-12 h-12 flex-shrink-0 rounded-md mb-4"
          :class="{'border-2 border-solid border-primary': key === selectedImageIndex}"
          :style="{'background-image': `url(${image})`}"
          @click="setSelectedImage(key)"
        />
      </div>

      <div
        v-if="!solo"
        class="flex justify-center gap-8 mb-8"
      >
        <Btn
          icon="chevron-left"
          class="w-fit bg-gray-0 !rounded-xl"
          size="md"
          color="gray-light"
          @click="goToPreviousImage"
        />

        <Btn
          icon="chevron-right"
          class="w-fit bg-gray-0 !rounded-xl"
          size="md"
          color="gray-light"
          @click="goToNextImage"
        />
      </div>
    </div>
  </Modal>
</template>

<script>
export default {
  name: 'ImageGalleryOverlay',

  data() {
    return {
      modalName: 'image-gallery',
      header: '',
      hint: '',
      selectedImageIndex: 0,
      selectedImage: null,
      images: [],
      solo: false,
    };
  },

  methods: {
    beforeOpen(e) {
      this.header = e.params.header;
      this.hint = e.params.hint;
      this.images = e.params.images;
      this.selectedImageIndex = e.params.selectedImageIndex;
      this.selectedImage = e.params.images[e.params.selectedImageIndex];
      this.solo = e.params.solo;
    },

    onClosed() {
      this.header = '';
      this.hint = '';
      this.selectedImageIndex = 0;
      this.selectedImage = null;
      this.images = [];
      this.solo = false;
    },

    goToPreviousImage() {
      if (this.selectedImageIndex !== 0) {
        this.selectedImageIndex--;
        this.selectedImage = this.images[this.selectedImageIndex];
      }
    },

    goToNextImage() {
      if (this.selectedImageIndex !== (this.images.length - 1)) {
        this.selectedImageIndex++;
        this.selectedImage = this.images[this.selectedImageIndex];
      }
    },

    setSelectedImage(imageIndex) {
      this.selectedImageIndex = imageIndex;
      this.selectedImage = this.images[this.selectedImageIndex];
    },

    closeModal() {
      this.$modal.hide(this.modalName);
    },
  },
};
</script>
