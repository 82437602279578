<template>
  <div class="card">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'Card',
};
</script>

<style lang="scss" scoped>
  .card {
    @apply bg-white border-1 border-solid border-gray-4 rounded-xl;
  }
</style>
