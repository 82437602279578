import * as Types from '@/store/tacts/mutation-types';
import Vue from 'vue';

export default {
  [Types.SET_DAYS](state, payload) {
    state.days = payload.sort((a, b) => (
      a.date > b.date ? 1 : -1
    ));
  },

  [Types.SET_ALL_TACTS](state, payload) {
    state.allTacts = payload.sort((a, b) => {
      if (a.start_time.slice(0, 10) === b.start_time.slice(0, 10)) {
        return a.position > b.position ? 1 : -1;
      }
      return a.start_time.slice(0, 10) > b.start_time.slice(0, 10) ? 1 : -1;
    });
  },

  [Types.SET_SELECTED_DAY_TACTS](state, payload) {
    state.selectedDayTacts = payload.sort((a, b) => (a.position > b.position ? 1 : -1));
  },

  [Types.SET_SELECTED_DAY](state, payload) {
    state.selectedDay = payload;
  },

  [Types.SET_SELECTED_TACT](state, payload) {
    if (payload) {
      payload.contents.forEach((item) => {
        Vue.set(item, 'showTextarea', !!item.text);
        Vue.set(item, 'showBtn', !item.text);
      });
    }

    state.selectedTact = payload;
  },

  [Types.CLEAR_TACTS_STORE](state) {
    state.days = [];
    state.allTacts = [];
    state.selectedDayTacts = [];
    state.selectedDay = null;
    state.selectedTact = null;
  },

  [Types.ADD_TACT_TO_SELECTED_DAY](state, payload) {
    state.selectedDayTacts.push(payload);
  },

  [Types.INSERT_TACT_INTO_SELECTED_DAY](state, payload) {
    const i = state.selectedDayTacts.findIndex((t) => t.position === payload.position);

    state.selectedDayTacts.splice(i, 0, payload);

    for (let k = i + 1; k < state.selectedDayTacts.length; k++) {
      state.selectedDayTacts[k].position++;
    }
  },

  [Types.REPLACE_TACT_IN_ALL_TACTS](state, payload) {
    const i = state.allTacts.findIndex((t) => t.id === payload.id);
    state.allTacts.splice(i, 1, payload);
  },

  [Types.REPLACE_TACT_IN_SELECTED_DAY](state, payload) {
    const i = state.selectedDayTacts.findIndex((t) => t.id === payload.id);
    state.selectedDayTacts.splice(i, 1, payload);
  },

  [Types.REMOVE_TACT_FROM_SELECTED_DAY](state, payload) {
    const i = state.selectedDayTacts.findIndex((t) => t.id === payload.id);
    state.selectedDayTacts.splice(i, 1);
  },

  [Types.REMOVE_TACT_FROM_ALL_TACTS](state, payload) {
    const i = state.allTacts.findIndex((t) => t.id === payload.id);
    state.allTacts.splice(i, 1);
  },
};
