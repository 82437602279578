<template>
  <h3 class="italic text-gray-1">
    {{ $t('underDevelopment') }}
  </h3>
</template>

<script>
export default {
  name: 'AdminNotifications',

  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>

</style>
