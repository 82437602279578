<template>
  <div
    class="fixed bottom-24 w-11/12 max-w-sm left-1/2 -translate-x-1/2 pt-3 px-3 rounded-xl shadow-sm"
    :class="bgClass"
  >
    <div class="flex items-center justify-between w-full mb-2">
      <span class="font-monospace text-lg text-white">{{ timeLeft }}</span>

      <div class="flex gap-3">
        <Input
          :value="tactInterval"
          type="number"
          min="1"
          max="999"
          size="sm"
          prepend-icon="time-1"
          class="font-monospace text-lg text-gray-0"
          max-width="50px"
          min-width="50px"
          auto-width
          @click.stop
          @change="changeIntervalWithDebounce"
          @input="e => tactInterval = e"
        />

        <Btn
          color="secondary"
          :loading="isLoading"
          @click="finishCurrentTact"
        >
          {{ $t('currentTactPage.finishTactBtn') }}
        </Btn>
      </div>
    </div>

    <div
      class="h-1 relative"
      :class="progressBarClass"
      :style="{ width: `${percentsPassed}%` }"
    />
  </div>
</template>

<script>
import Input from '@/components/ui/inputs/Input';
import { SET_SELECTED_TACT } from '@/store/tacts/mutation-types';
import moment from 'moment';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'CurrentTactTimer',
  components: { Input },
  props: {
    currentTact: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      timeLeft: 0,
      percentsPassed: 0,
      bgClass: 'bg-snackbar',
      progressBarClass: 'bg-secondary-green',
      isLoading: false,
      tactInterval: 0,
      timeout: null,
    };
  },

  computed: {
    ...mapState('events', ['selectedEvent']),
    ...mapState('tacts', ['selectedTact', 'selectedDayTacts']),
  },

  mounted() {
    this.calculateTime();
    setInterval(() => this.calculateTime(), 1000);
    this.tactInterval = this.currentTact.interval;
  },

  methods: {
    ...mapActions('tacts', [
      'changeTactInterval',
      'getTacts',
    ]),

    ...mapMutations({
      setSelectedTact: `tacts/${SET_SELECTED_TACT}`,
    }),

    calculateTime() {
      const tactDuration = this.currentTact.interval;
      const diffPassed = moment().diff(this.currentTact.start_time, 'seconds');
      const diffLeft = moment(this.currentTact.end_time).diff(moment(), 'seconds');
      const secondsLeft = String(moment.duration(diffLeft * 1000).seconds()).padStart(2, '0');
      const minutesLeft = String(moment.duration(diffLeft * 1000).minutes()).padStart(2, '0');

      if (minutesLeft < 0 || secondsLeft < 0) {
        this.percentsPassed = 100;
        this.timeLeft = '00:00';
      } else {
        this.percentsPassed = Math.round(100 / (tactDuration * 60) * diffPassed);
        this.timeLeft = `${minutesLeft}:${secondsLeft}`;
      }

      if (minutesLeft < 5) {
        this.bgClass = 'bg-secondary-red bg-opacity-90';
        this.progressBarClass = 'bg-dark';
      } else {
        this.bgClass = 'bg-snackbar';
        this.progressBarClass = 'bg-secondary-green';
      }
    },

    changeIntervalWithDebounce(e) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.tactInterval = e.target.value;

      this.timeout = setTimeout(() => {
        this.changeInterval();
      }, 1000);
    },

    async changeInterval() {
      try {
        this.isLoading = true;

        await this.changeTactInterval({
          event_id: this.selectedEvent.id,
          tact_id: this.currentTact.id,
          interval: +this.tactInterval,
        });
        await this.getTacts(this.selectedEvent.id);

        this.$toast(this.$t('notification.success.successfullyUpdated'));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading = false;
      }
    },

    async finishCurrentTact() {
      try {
        this.isLoading = true;

        const interval = moment().diff(this.currentTact.start_time, 'minutes');

        if (interval < 1) {
          this.$toast.error(this.$t('notification.error.cantFinishTact'));
          return;
        }

        await this.changeTactInterval({
          event_id: this.selectedEvent.id,
          tact_id: this.currentTact.id,
          interval,
        });

        if (this.selectedTact?.id === this.currentTact.id) {
          const index = this.selectedDayTacts.findIndex((t) => t.id === this.currentTact.id);
          if (this.selectedDayTacts[index + 1]) {
            this.setSelectedTact(this.selectedDayTacts[index + 1]);
          } else {
            this.setSelectedTact(null);
          }
        }

        this.$toast(this.$t('notification.success.tactFinished', { tactName: this.currentTact.name }));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .pointer-triangle {
    position: absolute;
    z-index: 10;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid black;
  }
</style>
