<script>
import { mapActions } from 'vuex';

export default {
  name: 'VerifyEmail',
  props: {
    token: String,
  },

  async mounted() {
    if (this.$route.query.token) {
      await this.verifyEmail();
    } else {
      this.$router.push({ name: 'login' });
    }
  },

  methods: {
    ...mapActions('email', ['confirmEmail']),

    async verifyEmail() {
      try {
        await this.confirmEmail(this.$route.query.token);

        this.$toast(this.$t('notification.success.emailConfirmed'));
        await this.$router.push({ name: 'login' });
      } catch (e) {
        // console.log(e);
      }
    },
  },
};
</script>
