import App from '@/App';
import Btn from '@/components/ui/buttons/Button';
import SvgIcon from '@/components/ui/common/SvgIcon';
import AdminLayout from '@/layouts/AdminLayout';
import BlankLayout from '@/layouts/BlankLayout';
import DefaultLayout from '@/layouts/DefaultLayout';
import '@/mixins/copyToClipBoardMixin';
import '@/plugins/floating-vue';
import i18n from '@/plugins/i18n';
import '@/plugins/v-calendar';
import '@/plugins/vee-validate';
import '@/plugins/vue-autowidth';
import '@/plugins/vue-modal';
import '@/plugins/vue-textarea-autosize';
import '@/plugins/vue-toastification';
import router from '@/router';
import store from '@/store';
import Vue from 'vue';
import './assets/styles/main.scss';

Vue.config.productionTip = false;

Vue.component('DefaultLayout', DefaultLayout);
Vue.component('AdminLayout', AdminLayout);
Vue.component('BlankLayout', BlankLayout);

Vue.component('SvgIcon', SvgIcon);
Vue.component('Btn', Btn);

window.eventBus = new Vue();

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount('#app');
