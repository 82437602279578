<template>
  <div class="flex flex-col gap-4">
    <div class="flex justify-end items-center flex-wrap gap-4 -mt-16">
      <!--      <div class="flex justify-end gap-4">-->
      <!--        <TabList-->
      <!--          v-if="selectedSet"-->
      <!--          :active="selectedSet.id"-->
      <!--          :tabs="sets"-->
      <!--          class="flex-wrap"-->
      <!--          nav-line-->
      <!--          numbered-->
      <!--          @click="setSelectedSet"-->
      <!--        />-->

      <!--        <Btn-->
      <!--          class="mt-2"-->
      <!--          color="gray-light"-->
      <!--          link-->
      <!--          prepend-icon="plus"-->
      <!--          @click="addSet"-->
      <!--        >-->
      <!--          {{ $t('adminEventsView.participantsTab.set') }}-->
      <!--        </Btn>-->
      <!--      </div>-->

      <div class="flex gap-4 w-5/12">
        <Btn
          :loading="isLoading.createEvent"
          prepend-icon="plus"
          @click="onCreateGroup"
        >
          {{ $t('adminEventsView.participantsTab.group') }}
        </Btn>

        <Input
          v-model="search"
          :placeholder="$t('adminEventsView.participantsTab.search')"
          class="flex-grow"
          prepend-icon="search"
          @submit="fetchData"
        />
      </div>
    </div>

    <ListWithLoader
      :empty-list-text="$t('adminEventsView.participantsTab.emptyList')"
      :is-loading="isLoading.loadGroups"
      :length="groups.length"
    >
      <AdminGroupsListItem
        v-for="group in groups"
        :key="group.id"
        :source-group="group"
        @reload-groups="fetchData"
      />
    </ListWithLoader>
  </div>
</template>

<script>
import AdminGroupsListItem from '@/components/project/admin/members/groupsList/AdminGroupsListItem';
import ListWithLoader from '@/components/ui/common/ListWithLoader';
import Input from '@/components/ui/inputs/Input';
import { SET_SELECTED_SET } from '@/store/members/mutation-types';
import moment from 'moment';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'AdminParticipants',

  components: { AdminGroupsListItem, Input, ListWithLoader },

  data() {
    return {
      sets: [],
      isLoading: {
        loadGroups: false,
        createGroup: false,
      },
      search: '',
    };
  },

  watch: {
    'selectedEvent.groups_set'() {
      this.fillSetsByEventSets();
    },

    selectedSet() {
      this.fetchData();
    },
  },

  computed: {
    ...mapState('events', ['selectedEvent']),
    ...mapState('members', ['groups', 'selectedSet']),
  },

  mounted() {
    this.fillSetsByEventSets();
    this.setSelectedSet(this.sets[0]);
  },

  methods: {
    ...mapActions('members', [
      'getGroupsOfEvent',
      'createGroup',
      'createSetGroup',
    ]),

    ...mapMutations({
      setSelectedSet: `members/${SET_SELECTED_SET}`,
    }),

    async fetchData() {
      try {
        this.isLoading.loadGroups = true;

        await this.getGroupsOfEvent({
          event_id: this.selectedEvent.id,
          group_set_id: this.selectedSet.id,
          q: this.search || null,
        });
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.loadGroups = false;
      }
    },

    async onCreateGroup() {
      try {
        this.isLoading.createGroup = true;

        await this.createGroup({
          event_id: this.selectedEvent.id,
          group_set_id: this.selectedSet.id,
          name: this.$t('adminEventsView.participantsTab.newGroup.name'),
        });
        await this.fetchData();
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.createGroup = false;
      }
    },

    async addSet() {
      const newSet = await this.createSetGroup({
        event_id: this.selectedEvent.id,
        start_datetime: moment().format(),
      });

      this.selectedEvent.groups_set.push(newSet);
      this.fillSetsByEventSets();
    },

    fillSetsByEventSets() {
      this.sets = [];
      for (
        let setNum = this.sets.length + 1;
        setNum < this.selectedEvent.groups_set.length + 1;
        setNum++
      ) {
        const setData = this.selectedEvent.groups_set[setNum - 1];

        this.sets.push({
          id: setData.id,
          name: setData.id,
          title: `${this.$t('adminEventsView.participantsTab.set')}`,
          startDateTime: setData.start_datetime,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
