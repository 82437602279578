<template>
  <nav class="sticky bottom-0 h-20">
    <div class="nav">
      <RouterLink
        v-for="item in navItems"
        :key="item.routeName"
        :to="{name: item.routeName}"
        class="nav-item"
        @click.native="scrollToTop"
      >
        <SvgIcon
          :name="item.icon"
          :class="item.class"
          size="md"
        />
      </RouterLink>
      <span
        class="nav-item"
        @click="onLogout"
      >
        <SvgIcon
          name="logout"
          class="text-gray-0 cursor-pointer"
          size="md"
        />
      </span>
    </div>

    <ConfirmationModal ref="logoutModal"/>
  </nav>
</template>

<script>
import ConfirmationModal from '@/components/ui/modals/ConfirmationModal';
import { mapActions } from 'vuex';

export default {
  name: 'NavMenu',
  components: { ConfirmationModal },
  data() {
    return {};
  },

  computed: {
    navItems() {
      return [
        {
          icon: 'bars-staggered-solid',
          class: 'text-gray-0',
          routeName: 'eventList',
        },
        {
          icon: 'list',
          class: 'text-gray-0',
          routeName: 'programPage',
        },
        {
          icon: 'logo-x',
          class: 'text-primary',
          routeName: 'currentTactPage',
        },
        {
          icon: 'team',
          class: 'text-gray-0',
          routeName: 'membersPage',
        },
      ];
    },
  },

  methods: {
    ...mapActions('auth', ['logout']),
    ...mapActions('events', ['clearEvent']),
    ...mapActions('tacts', ['clearTacts']),

    scrollToTop() {
      const top = document.getElementById('top');
      top.scrollIntoView({ behavior: 'smooth' });
    },

    async onLogout() {
      const ok = await this.$refs.logoutModal.show({
        title: `${this.$t('logout.header')}?`,
        okButton: this.$t('logout.confirmBtn'),
        cancelButton: this.$t('logout.cancelBtn'),
      });

      if (ok) {
        this.logout();
        this.clearEvent();
        this.clearTacts();

        this.$router.push({ name: 'login' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .nav {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    height: 100%;
    @apply bg-white border-1 border-solid border-gray-3;
  }

  .nav-item {
    @apply border-t-2 border-solid border-transparent;
    width: 60px;
    height: 60px;
    padding: 16px;

    &.router-link-active {
      background: linear-gradient(180deg, #fffdd8 0%, rgba(237, 230, 99, 0) 100%);
      @apply border-t-2 border-solid border-primary-dark;
    }
  }
</style>
