<template>
  <label
    :class="computedClasses"
    class="checkbox"
  >
    <span class="checkbox__in">
      <input
        :id="id"
        :checked="shouldBeChecked"
        :disabled="disabled"
        :value="value"
        class="checkbox__input hidden"
        type="checkbox"
        @change="updateInput($event)"
      >
      <span class="checkbox__pseudo" />
      <span class="checkbox__text">
        <slot />
      </span>
    </span>
  </label>
</template>

<script>
export default {
  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    value: {
      type: [String, Number, Object],
      default: '',
    },
    id: {
      type: Number,
    },
    modelValue: {
      type: [Boolean, Array],
      default: false,
    },
    size: {
      type: String,
      default: 'md',
    },
    trueValue: {
      type: Boolean,
      default: true,
    },
    falseValue: {
      type: Boolean,
      default: false,
    },
    switcher: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    shouldBeChecked() {
      if (this.modelValue instanceof Array) {
        return this.modelValue.includes(this.value);
      }

      return this.modelValue === this.trueValue;
    },

    computedClasses() {
      return [
        `checkbox_${this.size}`,
        {
          checkbox_switcher: this.switcher,
          checkbox_tag: this.tag,
          checkbox_disabled: this.disabled,
        },
      ];
    },
  },

  methods: {
    updateInput(e) {
      let isChecked = e.target.checked;

      if (this.modelValue instanceof Array) {
        let newValue = [...this.modelValue];

        if (isChecked) {
          newValue.push(this.value);
        } else {
          newValue.splice(newValue.indexOf(this.value), 1);
        }

        this.$emit('change', newValue);
      } else {
        this.$emit('change', isChecked ? this.trueValue : this.falseValue);
      }

      this.$emit('change-event', e);
    },
  },
};
</script>

<style lang="scss" scoped>
  .checkbox {
    display: block;
    line-height: 18px;
    white-space: nowrap;
    text-align: left;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &__in {
      position: relative;
      display: flex;
      align-items: center;
      vertical-align: top;
      @apply transition transition-all duration-300;
    }

    &__input {
      &:checked {
        ~ .checkbox__pseudo {
          @apply border-secondary-blue bg-secondary-blue;

          &:after {
            opacity: 1;
          }
        }
      }
    }

    &__pseudo {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      width: 20px;
      height: 20px;
      vertical-align: top;
      background-color: transparent;
      @apply border-2 border-solid border-gray-2;
      border-radius: 3px;
      backface-visibility: hidden;
      transition: background-color ease .1s, border-color ease .1s;

      &:hover {
        @apply border-secondary-blue;
      }

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 11px;
        height: 6px;
        margin-top: -1px;
        @apply border-b-2 border-l-2 border-white;
        transform: translate(-50%, -50%) rotate(-45deg);
        opacity: 0;
        transition: opacity linear .1s;
        content: "";
      }
    }

    &__text {
      display: inline-block;
      flex-shrink: 1;
      padding-left: 6px;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      white-space: normal;
      vertical-align: middle;
      user-select: none;
      @apply text-gray-0;
    }

    &_disabled {
      cursor: auto;

      input {
        cursor: auto;
      }

      .checkbox__text {
        @apply text-gray-1;
      }

      .checkbox__pseudo {
        @apply border-gray-3;
      }
    }

    &_switcher & {
      position: relative;

      &__label {
        display: flex;
        min-height: 16px;
      }

      &__input {
        &:checked + .checkbox__pseudo {
          @apply bg-secondary-blue;

          &:before {
            left: 14px;
            @apply bg-white;
          }
        }
      }

      &__pseudo {
        min-width: 27px;
        width: 27px;
        height: 16px;
        @apply bg-gray-2;
        border: none;
        border-radius: 18px;
        box-shadow: none;
        cursor: pointer;

        &:after {
          display: none;
        }

        &:before {
          position: absolute;
          top: 3px;
          left: 3px;
          width: 10px;
          height: 10px;
          @apply bg-white;
          border-radius: 50%;
          transition: transform .1s, left .1s, background-color .1s;
          content: '';
        }
      }
    }

    &_tag & {
      position: relative;

      &__label {
        display: flex;
        min-height: 16px;
      }

      &__in {
        position: relative;
        display: flex;
        align-items: center;
        vertical-align: top;
        @apply bg-light rounded;
        padding: 7px 8px;
        border-radius: 100px;

        &:hover {
          @apply bg-gray-5 text-gray-4;
        }
      }

      &__input {
        @apply bg-light rounded;

        &:checked {
          ~ .checkbox__pseudo {
            @apply border-secondary-blue bg-secondary-blue;

            &:after {
              opacity: 1;
            }
          }

          ~ .checkbox__text {
            @apply text-dark;
          }
        }
      }

      &__pseudo {
        position: relative;
        display: inline-block;
        box-sizing: border-box;
        min-width: 18px;
        width: 18px;
        height: 18px;
        vertical-align: top;
        background-color: transparent;
        @apply border-2 border-solid border-gray-2;
        border-radius: 100px;
        backface-visibility: hidden;
        transition: background-color ease .1s, border-color ease .1s;

        &:hover {
          @apply border-secondary-blue;
        }

        &:after {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 9px;
          height: 5px;
          margin-top: -1px;
          @apply border-b-2 border-l-2 border-white;
          transform: translate(-50%, -50%) rotate(-45deg);
          opacity: 0;
          transition: opacity linear .1s;
          content: "";
        }
      }

      &__text {
        display: inline-block;
        padding-left: 6px;
        font-size: 14px;
        font-weight: 400;
        line-height: 18px;
        white-space: normal;
        vertical-align: middle;
        user-select: none;
        @apply text-gray-0;
      }
    }
  }
</style>
