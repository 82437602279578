export default {
  CreateInviteCode: (event_id) => `/events/${event_id}/code`,
  ActivateInviteCode: (event_id) => `/events/${event_id}/code/activate`,
  JoinGroupViaCodeIdentifier: (event_id, invite_code) => `/events/${event_id}/join/${invite_code}`,
  ChangeMemberRole: (event_id) => `/events/${event_id}/change-role`,

  AddMember: (event_id, group_id) => `/events/${event_id}/groups/${group_id}/members`,
  GetMembersOfEvent: (event_id) => `/events/${event_id}/members`,
  UpdateMembersOfGroup: (event_id, group_id) => `/events/${event_id}/groups/${group_id}`,
  UpdateSelfInfo: (event_id) => `/events/${event_id}/me`,
  GetCompletedTasks: (event_id, member_id) => `/events/${event_id}/members/${member_id}/completed-tasks`,

  GetGroupsOfEvent: (event_id) => `/events/${event_id}/groups`,
  CreateGroup: (event_id) => `/events/${event_id}/groups`,
  UpdateGroup: (event_id, group_id) => `/events/${event_id}/groups/${group_id}`,
  DeleteGroups: (event_id) => `/events/${event_id}/groups`,
  GetCurrentGroup: (event_id) => `/events/${event_id}/current-group`,

  CreateSetGroup: (event_id) => `/events/${event_id}/groups-set`,
  DeleteSetGroup: (event_id, group_set_id) => `/events/${event_id}/groups-set/${group_set_id}`,
};
