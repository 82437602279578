<template>
  <div
    v-if="tact"
    class="tact-list-item"
    :class="isRest ? 'bg-secondary-blue' : 'bg-white'"
  >
    <div
      class="tact-list-item__side-line"
      :class="sideLineColor"
    />

    <div
      :class="isRest ? 'text-gray-4' : 'text-gray-0'"
      class="tact-list-item__time w-fit ml-2 mr-4 flex flex-col flex-shrink-0"
    >
      <div v-if="tact.is_before_start">{{ $t('programPage.tactListItem.before') }}</div>
      <div v-else>{{ startTimeFormatted }}–</div>
      <div>{{ endTimeFormatted }}</div>
    </div>

    <div
      class="flex flex-col flex-grow gap-1.5"
    >
      <div
        class="font-semibold break-words"
        :class="isRest ? 'text-white' : 'text-dark'"
      >
        {{ tact.name }}
      </div>

      <div class="flex justify-between">
        <div
          v-if="!tact.is_before_start"
          class="flex items-center gap-1.5"
          :class="isRest ? 'text-white' : 'text-gray-0'"
        >
          <SvgIcon name="time-1" />
          {{ tact.interval }} {{ $t('programPage.tactListItem.minutes') }}
        </div>

        <Tag
          v-if="tact.is_locked && iAmModerator"
          prepend-icon="elephant"
          color="error"
        >
          {{ $t('programPage.tactListItem.cantMove') }}
        </Tag>
      </div>

      <div class="flex items-center gap-2">
        <Tag
          v-if="tact.need_photo"
          color="success"
        >
          {{ $t('programPage.tactListItem.tagPhoto') }}
        </Tag>

        <Tag
          v-if="tact.questionnaire.is_mandatory"
          :color="tact.questionnaire.questions.length ? 'success' : 'secondary'"
          :prepend-icon="tact.questionnaire.questions.length ? '' : 'x'"
        >
          {{ $t('programPage.tactListItem.tagQuestionnaire') }}
        </Tag>
      </div>
    </div>
  </div>
</template>

<script>
import Tag from '@/components/ui/buttons/Tag';
import tactTypes from '@/constatns/tact-types';
import userRoles from '@/constatns/user-roles';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'TactListItem',

  components: { Tag },

  props: {
    tact: {
      type: Object,
      required: true,
    },
    isFirst: Boolean,
  },

  computed: {
    ...mapState('auth', ['me', 'isAdmin']),
    ...mapState('tacts', ['selectedTact']),

    startTimeFormatted() {
      return moment(this.tact.start_time).format('HH:mm');
    },

    endTimeFormatted() {
      return moment(this.tact.end_time).format('HH:mm');
    },

    sideLineColor() {
      const currentTime = moment().format();

      if (currentTime > this.tact.end_time) {
        return 'bg-secondary-green';
      }
      if (currentTime > this.tact.start_time && currentTime < this.tact.end_time) {
        return 'bg-primary';
      }
      return '';
    },

    isRest() {
      return this.tact.tact_type === tactTypes.rest;
    },

    iAmModerator() {
      return this.me.role === userRoles.moderator || this.isAdmin;
    },
  },
};
</script>

<style lang="scss" scoped>
  .tact-list-item {
    @apply border-b-1 border-solid border-gray-4 py-4 pr-4;
    @apply flex items-start gap-2 h-fit;
    min-height: 55px;

    &__side-line {
      min-height: 55px;
      height: 100%;
      width: 6px;
    }

    &__time {
      @apply font-monospace text-xs;
      line-height: 16px;
      font-size: 13px;
    }
  }
</style>
