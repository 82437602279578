<template>
  <div class="flex flex-col gap-3">
    <h1 class="py-1">{{ $t('adminEventsList.heading') }}</h1>
    <div class="flex justify-between items-center mt-5">
      <TabList
        :tabs="tabs"
        :active="activeTab"
        @click="setActiveTab"
      />

      <div class="flex gap-4">
        <Btn
          prepend-icon="plus"
          :loading="isLoading.createEvent"
          @click="onCreateEvent"
        >
          {{ $t('adminEventsList.createEventBtn') }}
        </Btn>

        <Input
          v-model="search"
          prepend-icon="search"
          :placeholder="$t('adminEventsList.search')"
          @submit="fetchData"
        />
      </div>
    </div>

    <ListWithLoader
      :length="events.length"
      :is-loading="isLoading.loadEventsList"
      :empty-list-text="$t('adminEventsList.emptyList')"
      class="events-list"
    >
      <div class="events-list-item events-list-header">
        <div>
          {{ $t('adminEventsList.listHeader.name') }}
        </div>
        <div>
          {{ $t('adminEventsList.listHeader.client') }}
        </div>
        <div>
          {{ $t('adminEventsList.listHeader.place_and_date') }}
        </div>
        <div>
          {{ $t('adminEventsList.listHeader.status') }}
        </div>
        <div />
      </div>

      <AdminEventsListItem
        v-for="event in events"
        :key="event.id"
        :event="event"
        class="events-list-item"
        @click.native="$router.push({ name: 'adminEventsView', params: { eventId: event.id }})"
        @delete-event="deleteEventItem"
      />
    </ListWithLoader>

    <ConfirmationModal ref="confirmationModal" />
  </div>
</template>

<script>
import AdminEventsListItem from '@/components/project/admin/events/eventsList/AdminEventsListItem';
import ListWithLoader from '@/components/ui/common/ListWithLoader';
import Input from '@/components/ui/inputs/Input';
import ConfirmationModal from '@/components/ui/modals/ConfirmationModal';
import TabList from '@/components/ui/selectors/TabList';
import eventStatuses from '@/constatns/event-statuses';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AdminEventsList',

  components: {
    ConfirmationModal,
    AdminEventsListItem,
    ListWithLoader,
    Input,
    TabList,
  },

  data() {
    return {
      tabs: [
        { name: 'all', title: this.$t('adminEventsList.tabs.all') },
        { name: eventStatuses.draft, title: this.$t(`adminEventsList.tabs.${eventStatuses.draft}`) },
        { name: eventStatuses.future, title: this.$t(`adminEventsList.tabs.${eventStatuses.future}`) },
        { name: eventStatuses.in_progress, title: this.$t(`adminEventsList.tabs.${eventStatuses.in_progress}`) },
        { name: eventStatuses.completed, title: this.$t(`adminEventsList.tabs.${eventStatuses.completed}`) },
      ],
      activeTab: '',
      isLoading: {
        createEvent: false,
        deleteEvent: false,
        loadEventsList: false,
      },
      search: '',
    };
  },

  watch: {
    async activeTab() {
      await this.fetchData();
    },
  },

  computed: {
    ...mapState('events', ['events']),
  },

  mounted() {
    this.setActiveTab(this.tabs[0]);
  },

  methods: {
    ...mapActions('events', [
      'getEvents',
      'createEvent',
      'deleteEvent',
    ]),

    async fetchData() {
      try {
        this.isLoading.loadEventsList = true;
        await this.getEvents({
          page: 1,
          size: 100,
          q: this.search || null,
          status: this.activeTab === 'all' ? null : this.activeTab,
        });
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.loadEventsList = false;
      }
    },

    async onCreateEvent() {
      try {
        this.isLoading.createEvent = true;

        const createdEvent = await this.createEvent({
          name: this.$t('adminEventsList.newEvent.name'),
          place: this.$t('adminEventsList.newEvent.place'),
          client: {
            name: this.$t('adminEventsList.newEvent.client'),
          },
        });

        this.$router.push({
          name: 'adminEventsView',
          params: { eventId: createdEvent.id, createdEvent: createdEvent },
        });
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.createEvent = false;
      }
    },

    async deleteEventItem(event) {
      const ok = await this.$refs.confirmationModal.show({
        title: this.$t('eventDeleteModal.title'),
        message: this.$t('eventDeleteModal.message'),
        okButton: this.$t('eventDeleteModal.okBtn'),
      });

      if (ok) {
        try {
          this.isLoading.deleteEvent = true;

          await this.deleteEvent({
            event_id: event.id,
          });

          this.$toast(this.$t(
            'notification.success.eventDeleted',
            { eventName: event.name },
          ));
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading.deleteEvent = false;
        }
      }
    },

    setActiveTab(tab) {
      this.activeTab = tab.name;
    },
  },
};
</script>

<style lang="scss" scoped>
  .events-list {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    gap: 8px
  }

  .events-list-header {
    font-size: 12px;
    line-height: 140%;
    @apply text-gray-1;
    height: 32px;
    align-items: center !important;
  }

  .events-list-item {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr 1fr 16px;
    align-items: flex-start;
    padding-left: 16px;
    padding-right: 16px;
    gap: 32px;
  }
</style>
