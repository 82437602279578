<template>
  <div class="no-data">
    {{ text }}
  </div>
</template>

<script>
export default {
  name: 'EmptyList',

  props: {
    text: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss" scoped>
  .no-data {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    font-style: italic;
    @apply text-gray-0;
    margin: 36px 0;
  }
</style>
