<template>
  <div class="relative max-w-7xl mx-auto pb-4 md:pb-10 lg:pb-24 px-4 md:px-10 lg:px-24">
    <AdminHeader />
    <main class="pt-16 w-full h-full">
      <slot />
    </main>
  </div>
</template>

<script>
import AdminHeader from '@/components/ui/admin/AdminHeader';

export default {
  components: { AdminHeader },
};
</script>
