import * as Types from '@/store/events/mutation-types';

export default {
  [Types.SET_EVENTS](state, payload) {
    state.events = payload;
  },

  [Types.REMOVE_EVENT_FROM_LIST](state, payload) {
    const i = state.events.findIndex((e) => e.id === payload.event_id);

    if (i) state.events.splice(i, 1);
  },

  [Types.SET_SELECTED_EVENT](state, payload) {
    state.selectedEvent = payload;
  },

  [Types.SET_SELECTED_DAY](state, payload) {
    state.selectedDay = payload;
  },
};
