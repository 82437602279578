import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import auth from './auth';
import clients from './clients';
import email from './email';
import events from './events';
import files from './files';
import members from './members';
import tacts from './tacts';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    clients,
    email,
    events,
    files,
    members,
    tacts,
  },
  plugins: [
    createPersistedState({
      paths: ['auth'],
    }),
  ],
});
