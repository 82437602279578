<template>
  <div
    class="avatar"
    @click="showFullSize"
  >
    <img
      v-if="image"
      :src="image"
      alt="avatar"
      class="rounded-full object-cover"
      :class="`avatar--${size}`"
    >
    <div
      v-else
      class="flex items-center justify-center bg-gray-4 rounded-full"
      :class="`avatar--${size}`"
    >
      <SvgIcon
        name="avatar-placeholder"
        :size="faceSize"
      />
    </div>

    <div
      v-if="interactive"
      class="absolute top-0 left-0 w-full h-full flex justify-center items-center rounded-full
             bg-overlay-light z-10 transition transition-opacity duration-300 cursor-pointer"
      :class="showOverlay ? 'opacity-1' : 'opacity-0'"
      @mouseenter="showOverlay = true"
      @mouseleave="showOverlay = false"
      @click="$emit('avatar-click')"
    >
      <SvgIcon
        name="photo"
        :size="faceSize"
        class="text-white"
      />
    </div>

    <SvgIcon
      v-if="isModerator"
      class="avatar__badge"
      name="avatar-badge"
    />
  </div>
</template>

<script>
const sizes = {
  sm: '40px',
  md: '60px',
  lg: '80px',
};

export default {
  props: {
    image: {
      type: String,
      default: null,
    },
    isModerator: {
      type: Boolean,
      default: false,
    },
    interactive: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'sm',
      validator: (val) => Object.keys(sizes).includes(val),
    },
  },

  data() {
    return {
      showOverlay: false,
      sizes,
    };
  },

  computed: {
    faceSize() {
      if (this.size === 'sm') {
        return 'md';
      }
      if (this.size === 'md') {
        return 'lg';
      }
      if (this.size === 'lg') {
        return 'lg';
      }
      return 'sm';
    },
  },

  methods: {
    showFullSize() {
      if (this.image) {
        this.$modal.show('image-gallery', {
          images: [this.image],
          selectedImageIndex: 0,
          solo: true,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .avatar {
    position: relative;

    &--sm {
      width: 40px;
      height: 40px;
    }

    &--md {
      width: 60px;
      height: 60px;
    }

    &--lg {
      width: 80px;
      height: 80px;
    }

    &__badge {
      position: absolute;
      bottom: -3px;
      right: -3px;
      z-index: 20;
    }
  }
</style>
