<template>
  <div
    class="time-input__box"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
    @wheel.prevent="handleMouseWheel"
  >
    <label
      v-if="fieldLabel"
      :for="$attrs.id"
      class="time-input__label"
    >
      {{ fieldLabel }}
    </label>

    <div
      :class="computedClassList"
      @click="focus"
    >
      <transition name="triangles">
        <div
          v-if="hovered || focused"
          class="text-gray-0"
        >
          <SvgIcon
            class="absolute -top-3.5 left-2 cursor-pointer"
            name="triangle-up"
            @click.native.stop="incrementHour"
          />
          <SvgIcon
            class="absolute -top-3.5 right-2 cursor-pointer"
            name="triangle-up"
            @click.native.stop="incrementMinute"
          />
        </div>
      </transition>

      <input
        ref="input"
        :value="value"
        type="time"
        v-bind="$attrs"
        v-on="listeners"
        @input.prevent.stop="e => $emit('input', e.target.value)"
        @change.prevent.stop="e => $emit('change', e.target.value)"
      >

      <transition name="triangles">
        <div
          v-if="hovered || focused"
          class="text-gray-0"
        >
          <SvgIcon
            class="absolute -bottom-3.5 left-2 cursor-pointer"
            name="triangle-down"
            @click.native.stop="decrementHour"
          />
          <SvgIcon
            class="absolute -bottom-3.5 right-2 cursor-pointer"
            name="triangle-down"
            @click.native.stop="decrementMinute"
          />
        </div>
      </transition>
    </div>

    <Tag
      v-if="isTagActive || (tagIcon && (hovered || focused))"
      :color="isTagActive ? 'error' : 'light'"
      :icon="tagIcon"
      @click.native.stop="$emit('click-on-tag')"
    />
  </div>
</template>

<script>
import Tag from '@/components/ui/buttons/Tag';

export default {
  components: { Tag },
  inheritAttrs: false,
  props: {
    value: {
      type: String,
      required: true,
      default: '00:00',
    },
    fieldLabel: {
      type: String,
      default: '',
    },
    tagIcon: {
      type: String,
      default: '',
    },
    isTagActive: Boolean,
  },

  data() {
    return {
      hovered: false,
      focused: false,
    };
  },

  computed: {
    computedClassList() {
      return [
        'time-input__input',
        {
          'time-input__input_focused': this.focused,
          'time-input__input_hovered': this.hovered,
        },
      ];
    },

    listeners() {
      return {
        ...this.$listeners,
        focus: (e) => {
          this.onFocus();
          if (this.$listeners.focus) {
            this.$listeners.focus(e);
          }
        },
        blur: (e) => {
          this.onBlur();
          if (this.$listeners.blur) {
            this.$listeners.blur(e);
          }
        },
      };
    },
  },

  methods: {
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    onMouseEnter() {
      this.hovered = true;
    },
    onMouseLeave() {
      this.hovered = false;
    },
    focus() {
      this.focused = true;
      this.$refs.input.focus();
    },
    blur() {
      this.focused = false;
      this.$refs.input.blur();
    },

    incrementHour() {
      const hour = this.value.slice(0, 2);
      const newHour = +hour === 23 ? 0 : +hour + 1;
      const newTime = String(newHour).padStart(2, '0') + this.value.slice(-3);

      this.$emit('change', newTime);
    },

    incrementMinute() {
      const minutes = this.value.slice(-2);

      let newMinutes;
      let newTime;

      if (+minutes === 59) {
        newMinutes = 0;
        const hour = this.value.slice(0, 2);
        const newHour = +hour === 23 ? 0 : +hour + 1;
        newTime = `${String(newHour).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
      } else {
        newMinutes = +minutes + 1;
        newTime = this.value.slice(0, 3) + String(newMinutes).padStart(2, '0');
      }

      this.$emit('change', newTime);
    },

    decrementHour() {
      const hour = this.value.slice(0, 2);
      const newHour = +hour === 0 ? 23 : +hour - 1;
      const newTime = String(newHour).padStart(2, '0') + this.value.slice(-3);

      this.$emit('change', newTime);
    },

    decrementMinute() {
      const minutes = this.value.slice(-2);

      let newMinutes;
      let newTime;

      if (+minutes === 0) {
        newMinutes = 59;
        const hour = this.value.slice(0, 2);
        const newHour = +hour === 0 ? 23 : +hour - 1;
        newTime = `${String(newHour).padStart(2, '0')}:${String(newMinutes).padStart(2, '0')}`;
      } else {
        newMinutes = +minutes - 1;
        newTime = this.value.slice(0, 3) + String(newMinutes).padStart(2, '0');
      }

      this.$emit('change', newTime);
    },

    handleMouseWheel(e) {
      if (!this.focused) return;

      if (e.wheelDelta < 0) {
        this.decrementMinute();
      } else {
        this.incrementMinute();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .time-input {
    &__box {
      display: flex;
      align-items: center;
      gap: 2px;
    }

    &__label {
      font-size: 10px;
    }

    &__input {
      position: relative;
      box-sizing: border-box;
      display: inline-flex;
      flex-shrink: 1;
      align-items: center;
      @apply bg-transparent rounded-lg;
      @apply transition transition-all duration-300;
      @apply font-monospace;
      height: 32px;
      font-size: 14px;
      cursor: text;

      input {
        background-color: transparent;
        border: none;
        outline: none;
        cursor: text;
        padding: 12px 6px;
        width: 58px;

        &::-webkit-calendar-picker-indicator {
          display: none;
        }
      }

      &_hovered,
      &_focused {
        @apply bg-light text-gray-0;
      }
    }
  }

  .triangles-enter-active,
  .triangles-leave-active {
    @apply transition transition-all duration-300;
    opacity: 1;
  }

  .triangles-enter,
  .triangles-leave-to {
    opacity: 0;
  }
</style>
