<template>
  <div class="absolute top-0 left-0 max-w-md bg-light w-full h-screen z-50">
    <Btn
      link
      prepend-icon="arrow-left"
      color="gray"
      class="mx-3 my-5"
      @click="$emit('close')"
    >
      {{ $t('currentTactPage.questionnaireRespond.backLink') }}
    </Btn>

    <div class="flex flex-col gap-1.5 mx-3 mb-6">
      <h2>
        {{ $t('currentTactPage.questionnaireRespond.header') }}
      </h2>

      <div class="text-gray-0">
        {{ $t('currentTactPage.questionnaireRespond.questions') }}: {{ questions.length }}
      </div>

      <div
        v-if="selectedQuestion"
        class="flex gap-1 mt-2"
      >
        <div
          v-for="block in answers"
          :key="block.id"
          class="h-2 w-full"
          :class="getBlockColor(block)"
        />
      </div>
    </div>

    <div
      v-if="selectedQuestion"
      class="bg-white pt-5 px-3 pb-3"
    >
      <div class="font-medium text-lg">
        {{ selectedQuestion.text }}
      </div>

      <div class="text-gray-0">
        {{ $t(`currentTactPage.questionnaireRespond.${selectedQuestion.question_type}Hint`) }}
      </div>

      <div class="w-full border-b-1 border-solid border-gray-3 my-4" />

      <Textarea
        v-if="selectedAnswer.question_type === questionTypes.text"
        v-model="selectedAnswer.answer"
        :placeholder="$t('currentTactPage.questionnaireRespond.textareaPlaceholder')"
        rows="7"
        class="flex mb-5"
      />

      <template v-else-if="selectedQuestion.question_type === questionTypes.checkbox">
        <Checkbox
          v-for="option in selectedQuestion.options"
          :key="option.id"
          :value="option.id"
          :model-value="selectedAnswer.options"
          class="flex mb-5"
          @change="updatedArray => selectedAnswer.options = updatedArray"
        >
          {{ option.value }}
        </Checkbox>
      </template>

      <template v-else-if="selectedQuestion.question_type === questionTypes.radio">
        <Radio
          v-for="option in selectedQuestion.options"
          :key="option.id"
          :value="option.id"
          :model-value="selectedAnswer.radio"
          class="flex mb-5"
          @change="id => selectedAnswer.radio = id"
        >
          {{ option.value }}
        </Radio>
      </template>
    </div>

    <div class="fixed bottom-0 w-full max-w-md px-3 pb-6 flex justify-between">
      <Btn
        v-if="!isFirstQ"
        icon="chevron-left"
        class="w-fit"
        size="md"
        color="secondary"
        @click="goToPreviousQuestion"
      />

      <Btn
        append-icon="arrow-right"
        size="md"
        :disabled="selectedQuestionNotAnswered"
        :class="isFirstQ ? 'w-full' : 'w-fit'"
        :loading="isLoading.sendAnswers"
        @click="goToNextQuestion"
      >
        {{ formBtnText }}
      </Btn>
    </div>
  </div>
</template>

<script>
import Textarea from '@/components/ui/inputs/Textarea';
import Checkbox from '@/components/ui/selectors/Checkbox';
import Radio from '@/components/ui/selectors/Radio';
import questionTypes from '@/constatns/question-types';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'QuestionnaireRespond',
  components: { Textarea, Radio, Checkbox },
  props: {
    questions: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      selectedQuestion: null,
      selectedAnswer: null,
      answers: [],
      isLoading: {
        sendAnswers: false,
      },
      questionTypes,
    };
  },

  computed: {
    ...mapState('events', ['selectedEvent']),
    ...mapState('tacts', ['selectedTact']),

    formBtnText() {
      return this.isLastQ
        ? this.$t('currentTactPage.questionnaireRespond.finishFormBtn')
        : this.$t('currentTactPage.questionnaireRespond.nextQuestionBtn');
    },

    isFirstQ() {
      return this.selectedQuestion?.id === this.questions.at(0).id;
    },

    isLastQ() {
      return this.selectedQuestion?.id === this.questions.at(-1).id;
    },

    selectedQuestionNotAnswered() {
      switch (this.selectedAnswer?.question_type) {
        case questionTypes.text:
          return !this.selectedAnswer.answer;
        case questionTypes.radio:
          return !this.selectedAnswer.radio;
        case questionTypes.checkbox:
          return !this.selectedAnswer.options.length;
        default:
          return false;
      }
    },
  },

  mounted() {
    this.questions.forEach((q) => this.answers.push({
      question_id: q.id,
      question_type: q.question_type,
      answer: '',
      radio: null,
      options: [],
    }));
    this.selectedQuestion = this.questions.at(0);
    this.selectedAnswer = this.answers.at(0);
  },

  methods: {
    ...mapActions('tacts', ['respondToQuestionnaire']),

    goToNextQuestion() {
      if (this.isLastQ) {
        this.sendAnswers();
      } else {
        const index = this.questions.findIndex((q) => q.id === this.selectedQuestion.id);
        this.selectedQuestion = this.questions.at(index + 1);
        this.selectedAnswer = this.answers.at(index + 1);
      }
    },

    goToPreviousQuestion() {
      if (!this.isFirstQ) {
        const index = this.questions.findIndex((q) => q.id === this.selectedQuestion.id);
        this.selectedQuestion = this.questions.at(index - 1);
        this.selectedAnswer = this.answers.at(index - 1);
      }
    },

    getBlockColor(block) {
      if (!!block.answer || !!block.options.length || !!block.radio) {
        return 'bg-secondary-green';
      }
      if (block.question_id === this.selectedAnswer.question_id) {
        return 'bg-primary-dark';
      }

      return 'bg-gray-3';
    },

    async sendAnswers() {
      try {
        this.isLoading.sendAnswers = true;

        this.answers.forEach((answer) => {
          if (answer.radio) {
            answer.options.push(answer.radio);
          }
          delete answer.radio;
        });

        await this.respondToQuestionnaire({
          event_id: this.selectedEvent.id,
          questionnaire_id: this.selectedTact.questionnaire.id,
          questionnaire_response: this.answers,
        });

        this.$toast(this.$t('notification.success.formSent'));
        this.$emit('success-form-submit');
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.sendAnswers = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
