<template>
  <div class="form-field__box">
    <label
      v-if="fieldLabel"
      :for="$attrs.id"
      class="form-field__label"
    >
      {{ fieldLabel }}
      <span
        v-if="fieldRequired"
        class="form-field__label_required"
      >
        *
      </span>
    </label>
    <div
      :class="computedClassList"
      @click="focus"
      @mouseenter="onMouseEnter"
      @mouseleave="onMouseLeave"
      @keydown.enter="submit"
    >
      <SvgIcon
        v-if="prependIcon"
        :name="prependIcon"
        :size="size"
        class="cursor-pointer text-gray-0"
        @click="submit"
      />
      <input
        v-if="autoWidth"
        ref="input"
        v-autowidth="{maxWidth: maxWidth, minWidth: minWidth, comfortZone: 6}"
        :disabled="disabled"
        :value="value"
        v-bind="$attrs"
        v-on="listeners"
      >
      <input
        v-else
        ref="input"
        :disabled="disabled"
        :value="value"
        v-bind="$attrs"
        v-on="listeners"
      >
    </div>
    <div
      v-if="error"
      class="form-field__error-text"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    fieldLabel: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'sm',
    },
    error: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    minWidth: {
      type: String,
      default: '200px',
    },
    maxWidth: {
      type: String,
      default: '200px',
    },
    fieldRequired: Boolean,
    invisible: Boolean,
    grayBg: Boolean,
    text: Boolean,
    disabled: Boolean,
    autoWidth: Boolean,
    autoSelect: Boolean,
  },

  data() {
    return {
      hovered: false,
      focused: false,
    };
  },

  computed: {
    computedClassList() {
      return [
        'form-field__input',
        `form-field__input_${this.size}`,
        {
          'form-field__input_focused': this.focused,
          'form-field__input_hovered': this.hovered,
          'form-field__input_invisible': this.invisible,
          'form-field__input_gray-bg': this.grayBg,
          'form-field__input_text': this.text,
          'form-field__error': this.error,
        },
      ];
    },

    listeners() {
      return {
        ...this.$listeners,
        input: (e) => {
          this.$emit('input', e.target.value);
        },
        focus: (e) => {
          this.onFocus();
          if (this.$listeners.focus) {
            this.$listeners.focus(e);
          }
        },
        blur: (e) => {
          this.onBlur();
          if (this.$listeners.blur) {
            this.$listeners.blur(e);
          }
        },
      };
    },
  },

  methods: {
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    onMouseEnter() {
      this.hovered = true;
    },
    onMouseLeave() {
      this.hovered = false;
    },
    focus() {
      this.$refs.input.focus();
      if (this.autoSelect) this.$refs.input.select();
    },
    blur() {
      this.$refs.input.blur();
    },
    submit() {
      this.$emit('submit', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .form-field {
    &__box {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    &__label {
      font-size: 12px;
    }

    &__input {
      box-sizing: border-box;
      padding: 12px 8px;
      width: 100%;
      display: inline-flex;
      flex-grow: 1;
      flex-shrink: 1;
      align-items: center;
      gap: 5px;
      cursor: text;
      @apply bg-white;
      @apply border-1 border-solid border-gray-2;
      border-radius: 10px;
      outline: none;
      @apply transition transition-all duration-300;

      input {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;

        &::placeholder {
          @apply text-gray-2;
        }

        &[type=number]::-webkit-inner-spin-button,
        &[type=number]::-webkit-outer-spin-button {
          opacity: 0.5;
        }
      }

      &_xs {
        height: fit-content;
        padding: 4px 8px;
      }

      &_sm {
        height: 40px;
        min-height: 40px;
      }

      &_md {
        height: 48px;
        min-height: 48px;
      }

      &_lg {
        padding: 4px 20px;
        height: 72px;
        min-height: 72px;
        @apply bg-light;
        border-radius: 15px;
      }

      &[disabled] {
        opacity: .5;
      }

      &_hovered {
        @apply border-gray-12;
      }

      &_focused {
        @apply border-primary-dark;
        isolation: isolate;
      }

      &_invisible {
        border: 1px solid transparent;
        @apply bg-transparent;

        &.form-field__input_hovered {
          @apply bg-light;
          border: 1px solid transparent;
        }

        &.form-field__input_focused {
          @apply bg-primary-light;
          @apply border-1 border-solid border-primary-dark;
        }
      }

      &_gray-bg {
        border: 1px solid transparent;
        @apply bg-light;

        &.form-field__input_hovered {
          @apply bg-gray-5;
          border: 1px solid transparent;
        }

        &.form-field__input_focused {
          @apply bg-white;
          @apply border-1 border-solid border-primary-dark;
        }
      }

      &_text {
        padding: 0;
        border: 1px solid transparent;
        border-radius: 0;
        @apply bg-transparent;

        &.form-field__input_hovered {
          @apply bg-gray-4;
        }
      }
    }

    &__error {
      @apply border-secondary-red;
    }

    &__error-text {
      font-size: 12px;
      @apply text-secondary-red;
    }
  }
</style>
