<template>
  <div class="loader-container">
    <div class="loader" />
  </div>
</template>

<script>
export default {
  name: 'Loader',
};
</script>

<style lang="scss" scoped>
  .loader-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 14px;
    margin: 36px 0;
  }

  .loader {
    position: relative;
    width: 1.5em;
    height: 1.5em;
    margin: -25px auto 0;
    @apply text-secondary-blue;
    font-size: 10px;
    text-indent: -9999em;
    border-radius: 50%;
    transform: translateZ(0);
    animation-name: load7;
    animation-duration: 1.8s;
    animation-timing-function: ease-in-out;
    animation-delay: -.16s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;

    &:before {
      position: absolute;
      top: 0;
      left: -2.5em;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      animation-name: load7;
      animation-duration: 1.8s;
      animation-timing-function: ease-in-out;
      animation-delay: -.32s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      content: "";
    }

    &:after {
      position: absolute;
      top: 0;
      left: 2.5em;
      width: 1.5em;
      height: 1.5em;
      border-radius: 50%;
      animation-name: load7;
      animation-duration: 1.8s;
      animation-timing-function: ease-in-out;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      content: "";
    }
  }

  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }

    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
</style>
