<template>
  <Card class="p-3">
    <template v-if="selectedDayTacts.length">
      <div class="flex justify-between gap-0.5 w-full">
        <div
          v-for="block in blocks"
          :key="block.id"
          class="h-2 relative"
          :class="getBlockColor(block)"
          :style="{ width: `${block.length}%` }"
        >
          <div
            v-if="block.pointerTrianglePosition !== null"
            class="pointer-triangle"
            :style="{ left: `${block.pointerTrianglePosition}%` }"
          />
        </div>

      </div>

      <div class="flex justify-between text-gray-0 mt-2 text-xs">
        <div>{{ eventStartTime }}</div>

        <div>{{ eventEndTime }}</div>
      </div>
    </template>
    <template v-else>
      <p class="text-center">
        {{ $t('eventView.noTactsToday') }}
      </p>
    </template>
  </Card>
</template>

<script>
import Card from '@/components/ui/common/Card';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Timeline',

  components: { Card },

  data() {
    return {
      currentEventIntervalId: null,
    };
  },

  watch: {
    'this.selectedEvent.id'() {
      clearInterval(this.currentEventIntervalId);
      this.currentEventIntervalId = setInterval(() => this.fetchData(), 10 * 1000);
    },
  },

  computed: {
    ...mapState('tacts', ['selectedDayTacts']),
    ...mapState('events', ['selectedEvent']),

    eventStartTime() {
      return this.selectedDayTacts.length
        ? moment(this.selectedDayTacts.at(0).start_time).format('HH:mm')
        : '00:00';
    },

    eventEndTime() {
      return this.selectedDayTacts.length
        ? moment(this.selectedDayTacts.at(-1).end_time).format('HH:mm')
        : '00:00';
    },

    blocks() {
      const blocks = [];

      if (!this.selectedDayTacts.length) return blocks;

      const eventDuration = moment(this.selectedDayTacts.at(-1)?.end_time)
        .diff(this.selectedDayTacts.at(0).start_time, 'minutes');

      for (const i in this.selectedDayTacts) {
        if (this.selectedDayTacts[i]) {
          const tactDuration = this.selectedDayTacts[i].interval;
          const currentTime = moment().format();

          let pointerTrianglePosition = null;

          if (
            currentTime > this.selectedDayTacts[i].start_time
            && currentTime < this.selectedDayTacts[i].end_time
          ) {
            const timePassedFromTactStart = moment(currentTime)
              .diff(this.selectedDayTacts[i].start_time, 'minutes');

            pointerTrianglePosition = 100 / tactDuration * timePassedFromTactStart;
          }

          blocks.push({
            id: this.selectedDayTacts[i].id,
            length: 100 / eventDuration * tactDuration,
            start_time: this.selectedDayTacts[i].start_time,
            end_time: this.selectedDayTacts[i].end_time,
            pointerTrianglePosition,
          });
        }
      }

      return blocks;
    },
  },

  mounted() {
    this.fetchData();
    this.currentEventIntervalId = setInterval(() => this.fetchData(), 10 * 1000);
  },

  destroyed() {
    clearInterval(this.currentEventIntervalId);
  },

  methods: {
    ...mapActions('tacts', ['getTacts']),

    fetchData() {
      if (document.visibilityState === 'visible') {
        this.getTacts(this.selectedEvent.id);
      }
    },

    getBlockColor(block) {
      const currentTime = moment().format();

      if (currentTime > block.end_time) {
        return 'bg-secondary-green';
      }
      if (currentTime > block.start_time && currentTime < block.end_time) {
        return 'bg-primary';
      }
      return 'bg-gray-4';
    },
  },
};
</script>

<style lang="scss" scoped>
  .pointer-triangle {
    position: absolute;
    z-index: 10;
    width: 0;
    height: 0;
    border-top: 4px solid transparent;
    border-bottom: 4px solid transparent;
    border-left: 4px solid black;
  }
</style>
