import http from '@/api/axios';
import ApiRoutes from '@/api/routes';

const {
  GetAllEvents,
  CreateEvent,
  GetEvent,
  UpdateEvent,
  DeleteEvent,
} = ApiRoutes.Events;

export const getAllEvents = (data) => http.get(GetAllEvents, { params: data });

export const getEvent = (event_id) => http.get(GetEvent(event_id));

export const createEvent = (data) => http.post(CreateEvent, data);

export const updateEvent = ({ event_id, ...data }) => http.put(UpdateEvent(event_id), data);

export const deleteEvent = ({ event_id }) => http.delete(DeleteEvent(event_id));
