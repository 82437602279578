<template>
  <div class="bg-primary-light h-screen flex flex-col justify-center items-center">
    <h2>
      {{ $t('login.heading') }}
    </h2>

    <Card class="mt-4 py-6 px-6 max-w-md w-11/12 md:w-7/12 lg:w-1/3 relative">
      <div class="absolute w-full -top-60 left-0 flex justify-center">
        <SvgIcon
          name="logo"
          self-size
        />
      </div>

      <form
        class="flex flex-col gap-6"
        @submit.prevent="onSubmit"
      >
        <Input
          v-model="form.username"
          type="email"
          required
          :placeholder="$t('login.email')"
        />
        <Input
          v-model="form.password"
          type="password"
          required
          :placeholder="$t('login.password')"
        />

        <Btn
          type="submit"
          :loading="isLoading"
        >
          {{ $t('login.loginBtn') }}
        </Btn>
      </form>

      <div class="mt-6">
        <RouterLink
          :to="{name: 'resetPassword'}"
          class="text-secondary-blue hover:text-gray-0 hover:underline"
        >
          {{ $t('login.forgotPassword') }}
        </RouterLink>
      </div>

      <div class="mt-6 text-gray-0">
        {{ $t('login.notRegistered') }}

        <RouterLink
          :to="{name: 'registration'}"
          class="text-secondary-blue hover:text-gray-0 hover:underline"
        >
          {{ $t('login.toRegistration') }}
        </RouterLink>
      </div>
    </Card>

    <img
      src="@/assets/images/x.png"
      class="absolute bottom-0 left-0 invisible lg:visible"
      alt="logo"
    >
  </div>
</template>

<script>
import Card from '@/components/ui/common/Card';
import Input from '@/components/ui/inputs/Input';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Login',

  components: { Input, Card },

  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      error: '',
      isLoading: false,
    };
  },

  computed: {
    ...mapGetters('auth', ['isAdmin']),
  },

  mounted() {
    if (this.$route.query.email) this.form.username = this.$route.query.email;
    if (this.$route.params.email) this.form.username = this.$route.params.email;
  },

  methods: {
    ...mapActions('auth', [
      'login',
      'getMe',
    ]),

    /* eslint-disable no-restricted-globals */
    async onSubmit() {
      try {
        this.isLoading = true;
        await this.login(this.form);
        await this.getMe();

        if (location.origin === process.env.VUE_APP_ADMIN_BASE_URL) {
          this.isAdmin
            ? await this.$router.push({ name: 'adminEventsList' })
            : await this.$router.push({ name: 'accessDenied' });
        }

        if (location.origin === process.env.VUE_APP_APP_BASE_URL) {
          await this.$router.push({ name: 'eventView' });
        }
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
