<template>
  <div class="form-field__box">
    <label
      v-if="fieldLabel"
      :for="$attrs.id"
      class="form-field__label"
    >
      {{ fieldLabel }}
      <span
        v-if="fieldRequired"
        class="form-field__label_required"
      >
        *
      </span>
    </label>

    <textarea-autosize
      ref="textarea"
      :rows="1"
      :class="computedClasses"
      :value="value"
      v-bind="$attrs"
      @input.native="event => $emit('input', event.target.value)"
      @focus.native="event => $emit('focus', event.target.value)"
      @blur.native="event => $emit('blur', event.target.value)"
      @submit.native="event => $emit('submit', event.target.value)"
      @keydown.native="handleKeypress"
    />

    <div
      v-if="error"
      class="form-field__error-text"
    >
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,

  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    fieldLabel: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    fieldRequired: {
      type: Boolean,
      default: false,
    },
    text: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses() {
      return [
        'form-field__textarea',
        {
          'form-field__textarea_invisible': this.invisible,
          'form-field__textarea_gray-bg': this.grayBg,
          'form-field__textarea_text': this.text,
          'form-field__error': this.error,
        },
      ];
    },
  },

  methods: {
    handleKeypress(e) {
      if (e.keyCode === 13 && e.ctrlKey) {
        this.$emit('input', `${e.target.value}\n`);
        return;
      }
      if (e.keyCode === 13 && !e.shiftKey && this.value !== '') {
        e.preventDefault();
        this.$emit('submit');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .form-field {
    &__box {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
    }

    &__label {
      font-size: 12px;
    }

    &__textarea {
      box-sizing: border-box;
      padding: 12px 8px;
      width: 100%;
      display: inline-flex;
      flex-shrink: 1;
      align-items: center;
      gap: 5px;
      cursor: text;
      @apply bg-white;
      @apply border-1 border-solid border-gray-2;
      border-radius: 10px;
      outline: none;
      @apply transition transition-all duration-300;

      textarea {
        background-color: transparent;
        border: none;
        outline: none;
        width: 100%;
      }

      &[disabled] {
        opacity: .5;
      }

      &:hover {
        @apply border-gray-12;
      }

      &:focus {
        @apply border-primary-dark;
        isolation: isolate;
      }

      &::placeholder {
        @apply text-gray-3;
      }

      &_gray-bg {
        border: 1px solid transparent;
        background-color: transparent;

        &:hover {
          @apply bg-gray-5;
          border: 1px solid transparent;
        }

        &:focus {
          @apply bg-white;
          @apply border-1 border-solid border-primary-dark;

          textarea {
            @apply bg-secondary-blue-light;
          }
        }
      }

      &_text {
        padding: 0;
        border-radius: 0;
        @apply bg-transparent border-none;

        &:hover {
          @apply bg-gray-5;
        }

        &:focus {
          @apply bg-transparent ;
        }
      }
    }

    &__error {
      @apply border-secondary-red;
    }

    &__error-text {
      font-size: 12px;
      @apply text-secondary-red;
    }
  }
</style>
