<template>
  <Loader
    v-if="isLoading.loadTacts"
    class="!my-80"
  />

  <div v-else-if="tact">
    <div class="flex flex-col gap-1.5 mx-3 mb-8">
      <h2 class="break-words">
        {{ tact.name }}
      </h2>

      <div class="flex items-center gap-2 text-gray-0">
        <template v-if="!tact.is_before_start">
          <SvgIcon name="time-1" />

          {{ tact.interval }} {{ $t('currentTactPage.minutes') }}

          <div class="bg-gray-0 w-1 h-1 rounded-full" />
        </template>

        <div v-if="tact.is_before_start">
          {{ $t('currentTactPage.before') }} {{ endTimeFormatted }}
        </div>

        <div v-else>
          {{ startTimeFormatted }}–{{ endTimeFormatted }}
        </div>
      </div>
    </div>

    <h2
      v-if="tact.tact_type === tactTypes.rest"
      class="flex justify-center items-center mt-32 mx-10 text-center"
    >
      {{ $t('currentTactPage.isRest') }}
    </h2>

    <div
      v-else
      class="bg-white px-3 mb-24"
    >
      <div
        v-if="tact.tact_type === tactTypes.tact && (tact.need_photo || tact.questionnaire.is_mandatory)"
        class="flex flex-col gap-5 border-b-1 border-solid border-gray-3"
      >
        <div class="flex gap-2 pt-6">
          <SvgIcon
            name="lightning"
            size="md"
            class="text-gray-1"
          />

          <span class="font-medium text-base">
            {{ $t('currentTactPage.toDo') }}
          </span>
        </div>

        <div
          v-if="tact.need_photo"
          class="flex flex-col"
        >
          <div class="flex items-center gap-1">
            <SvgIcon
              v-if="tact.member_photos.length"
              name="tick"
              color="green"
            />
            <div
              v-else
              class="w-2 h-2 mx-1 rounded-full bg-secondary-red"
            />
            <span
              class="font-medium"
              :class="{'line-through': tact.member_photos.length, 'text-gray-1': tact.member_photos.length}"
            >
              {{ $t('currentTactPage.addPhoto') }}
            </span>
          </div>

          <div class="flex gap-4 py-4 overflow-x-scroll">
            <FileUploadInput
              ref="photo"
              @input="uploadPhotos"
            >
              <div
                class="drop-zone"
                @click="$refs.photo.$refs.file.click()"
              >
                <SvgIcon
                  v-if="isLoading.photoUploading"
                  name="spinner-solid"
                  size="md"
                  class="animate-spin"
                />
                <SvgIcon
                  v-else
                  name="photo"
                  size="md"
                />
              </div>
            </FileUploadInput>

            <div
              v-for="(photo, key) in tact.member_photos"
              :key="photo"
              class="bg-cover bg-center relative flex-shrink-0 w-24 h-24 rounded-lg"
              :style="{'background-image': `url(${photo})`}"
              @click="showGallery(key)"
            >
              <SvgIcon
                name="delete-photo"
                size="md"
                class="absolute -top-2 -right-2 cursor-pointer"
                @click.native="deletePhoto(photo)"
              />

              <div
                v-if="isLoading.photoDeleting === photo"
                class="absolute w-full h-full rounded-lg flex justify-center items-center bg-primary-light-50"
              >
                <SvgIcon
                  name="spinner-solid"
                  size="md"
                  class="animate-spin"
                />
              </div>
            </div>
          </div>
        </div>

        <div
          v-if="tact.questionnaire.is_mandatory"
          class="flex flex-col"
        >
          <div class="flex items-center gap-1 mb-3">
            <SvgIcon
              v-if="tact.questionnaire.has_responded"
              name="tick"
              color="green"
            />
            <div
              v-else
              class="w-2 h-2 mx-1 rounded-full bg-secondary-red"
            />
            <span
              class="font-medium"
              :class="{'line-through': tact.questionnaire.has_responded, 'text-gray-1': tact.questionnaire.has_responded}"
            >
              {{ fillForm }}
            </span>
          </div>

          <Btn
            v-if="!tact.questionnaire.has_responded"
            prepend-icon="edit"
            class="w-fit ml-5 mb-5"
            @click="isShowQuestionnaire = true"
          >
            {{ $t('currentTactPage.fillQuestionnaireBtn') }}
          </Btn>
        </div>
      </div>

      <div
        v-else-if="tact.tact_type === tactTypes.meeting"
        class="flex flex-col gap-5 border-b-1 border-solid border-gray-3"
      >
        <div class="flex gap-2 pt-6">
          <SvgIcon
            name="lightning"
            size="md"
            class="text-gray-1"
          />

          <span class="font-medium text-base">
            {{ $t('currentTactPage.toDo') }}
          </span>
        </div>

        <div class="flex flex-col">
          <div class="flex items-center gap-1">
            <SvgIcon
              v-if="me.photo"
              name="tick"
              color="green"
            />
            <div
              v-else
              class="w-2 h-2 mx-1 rounded-full bg-secondary-red"
            />
            <span
              class="font-medium"
              :class="{'line-through': me.photo, 'text-gray-1': me.photo}"
            >
              {{ $t('currentTactPage.addAvatar') }}
            </span>
          </div>

          <FileUploadInput
            ref="avatar"
            class="py-4"
            @input="addAvatar"
          >
            <div
              v-if="me.photo"
              class="bg-cover bg-center flex-shrink-0 w-24 h-24 ml-5 rounded-full"
              :style="{'background-image': `url(${me.photo})`}"
              @click="$refs.avatar.$refs.file.click()"
            />
            <div
              v-else
              class="drop-zone !rounded-full"
              @click="$refs.avatar.$refs.file.click()"
            >
              <SvgIcon
                v-if="isLoading.avatarUploading"
                name="spinner-solid"
                size="md"
                class="animate-spin"
              />
              <SvgIcon
                v-else
                name="photo"
                size="md"
              />
            </div>
          </FileUploadInput>

          <div class="flex items-center gap-1">
            <SvgIcon
              v-if="me.position"
              name="tick"
              color="green"
            />
            <div
              v-else
              class="w-2 h-2 mx-1 rounded-full bg-secondary-red"
            />

            <span
              class="font-medium"
              :class="{'line-through': me.position, 'text-gray-1': me.position}"
            >
              {{ $t('currentTactPage.addTitle') }}
            </span>
          </div>

          <Textarea
            v-model="me.position"
            class="flex gap-4 py-4 ml-5"
          />

          <Btn
            append-icon="arrow-right"
            size="md"
            :disabled="!me.position"
            class="w-fit ml-5 mb-5"
            :loading="isLoading.updateSelf"
            @click="updateSelf"
          >
            {{ $t('currentTactPage.saveBtn') }}
          </Btn>
        </div>
      </div>

      <router-link
        class="flex gap-2 py-6 cursor-pointer"
        :to="{ name: 'tactDetails', params: { tactId: tact.id } }"
      >
        <SvgIcon
          name="info"
          size="md"
          class="text-gray-1"
        />

        <span class="font-medium text-base flex-grow">
          {{ $t('currentTactPage.linkToTactDetails') }}
        </span>

        <SvgIcon
          size="md"
          name="chevron-right"
        />
      </router-link>
    </div>

    <CurrentTactTimer
      v-if="iAmModerator"
      :current-tact="tact"
    />

    <QuestionnaireRespond
      v-if="isShowQuestionnaire"
      :questions="tact.questionnaire.questions"
      @close="isShowQuestionnaire = false"
      @success-form-submit="onSuccessFormFilling"
    />
  </div>

  <h2
    v-else
    class="flex justify-center items-center mt-32 mx-10 text-center"
  >
    {{ $t('currentTactPage.noActiveTact') }}
  </h2>
</template>

<script>
import CurrentTactTimer from '@/components/project/mobile/currentTact/CurrentTactTimer';
import QuestionnaireRespond from '@/components/project/mobile/currentTact/QuestionnaireRespond';
import Loader from '@/components/ui/common/Loader';
import FileUploadInput from '@/components/ui/inputs/FileUploadInput';
import Textarea from '@/components/ui/inputs/Textarea';
import tactTypes from '@/constatns/tact-types';
import userRoles from '@/constatns/user-roles';
import moment from 'moment/moment';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'CurrentTactPage',

  components: {
    CurrentTactTimer,
    Textarea,
    QuestionnaireRespond,
    FileUploadInput,
    Loader,
  },

  data() {
    return {
      tact: null,
      isLoading: {
        loadTacts: false,
        photoUploading: false,
        photoDeleting: false,
        updateSelf: false,
        avatarUploading: false,
      },
      isShowQuestionnaire: false,
      tactTypes,
    };
  },

  watch: {
    selectedTact() {
      this.tact = JSON.parse(JSON.stringify(this.selectedTact));
    },
  },

  computed: {
    ...mapState('auth', ['me', 'isAdmin']),
    ...mapState('tacts', ['selectedTact']),
    ...mapState('events', ['selectedEvent']),

    startTimeFormatted() {
      return moment(this.tact.start_time).format('HH:mm');
    },

    endTimeFormatted() {
      return moment(this.tact.end_time).format('HH:mm');
    },

    fillForm() {
      return this.$t(
        'currentTactPage.fillForm',
        { questionsCount: this.tact.questionnaire.questions.length },
      );
    },

    iAmModerator() {
      return this.me.role === userRoles.moderator || this.isAdmin;
    },
  },

  async mounted() {
    if (this.selectedTact) {
      this.tact = JSON.parse(JSON.stringify(this.selectedTact));
    } else {
      this.$toast.error(this.$t('currentTactPage.noActiveTact'));
      await this.$router.push({ name: 'programPage' });
    }
  },

  methods: {
    ...mapActions('files', [
      'uploadAvatar',
    ]),
    ...mapActions('members', [
      'updateSelfInfo',
    ]),
    ...mapActions('tacts', [
      'getTacts',
      'getTact',
      'uploadMemberPhotos',
      'deleteMemberPhotos',
    ]),

    async uploadPhotos(files) {
      try {
        this.isLoading.photoUploading = true;

        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
          let file = files.item(i);
          formData.append('uploaded_files', file);
        }

        this.tact.member_photos = await this.uploadMemberPhotos({
          event_id: this.selectedEvent.id,
          tact_id: this.selectedTact.id,
          formData,
        });

        this.$toast(this.$t('notification.success.photoUploaded'));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.photoUploading = false;
      }
    },

    async addAvatar(files) {
      try {
        this.isLoading.avatarUploading = true;

        const formData = new FormData();
        formData.append('file', files[0]);

        this.me.photo = await this.uploadAvatar(formData);

        await this.updateSelf();

        this.$toast(this.$t(
          'notification.success.avatarUploaded',
          { memberName: this.me.name },
        ));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.avatarUploading = false;
      }
    },

    async updateSelf() {
      try {
        this.isLoading.updateSelf = true;

        await this.updateSelfInfo({
          event_id: this.selectedEvent.id,
          name: this.me.name,
          position: this.me.position,
          photo: this.me.photo,
        });

        this.$toast(this.$t('notification.success.successfullyUpdated'));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.updateSelf = false;
      }
    },

    async deletePhoto(photoUrl) {
      try {
        this.isLoading.photoDeleting = photoUrl;

        const photos = [photoUrl];

        this.tact.member_photos = await this.deleteMemberPhotos({
          event_id: this.selectedEvent.id,
          tact_id: this.selectedTact.id,
          photos,
        });

        this.$toast(this.$t('notification.success.photoDeleted'));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.photoDeleting = false;
      }
    },

    showGallery(selectedImageIndex) {
      this.$modal.show('image-gallery', {
        header: this.tact.name,
        images: this.tact.member_photos,
        selectedImageIndex,
      });
    },

    onSuccessFormFilling() {
      this.tact.questionnaire.has_responded = true;
      this.isShowQuestionnaire = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .drop-zone {
    @apply w-24 h-24 ml-5;
    @apply flex flex-col justify-center items-center;
    @apply border-1 border-dashed border-gray-1 rounded-lg;
    @apply bg-light text-gray-1;
    @apply cursor-pointer;
  }
</style>
