import addMsToCurrentTime from '@/utils/addMsToCurrentTime';
import { getCookie, removeCookie, setCookie } from '@/utils/cookies';

const WEEK_IN_MS = 6.048e+8;

const ACCESS_TOKEN_ID = 'access_token';
const REFRESH_TOKEN_ID = 'refresh_token';

export const getAccessToken = () => getCookie(ACCESS_TOKEN_ID);

export const setAccessToken = (token) => {
  setCookie(ACCESS_TOKEN_ID, token, {
    path: '/',
    expires: addMsToCurrentTime(WEEK_IN_MS),
  });
};

export const removeAccessToken = () => {
  removeCookie(ACCESS_TOKEN_ID, {
    path: '/',
    expires: '-1',
  });
};

export const getRefreshToken = () => getCookie(REFRESH_TOKEN_ID);

export const setRefreshToken = (token) => {
  setCookie(REFRESH_TOKEN_ID, token, {
    path: '/',
  });
};

export const removeRefreshToken = () => {
  removeCookie(REFRESH_TOKEN_ID);
};

export const parseJwt = (token) => {
  let base64Url = token.split('.')[1];
  let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  let jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) => `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));

  return JSON.parse(jsonPayload);
};
