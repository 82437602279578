import { extend, localize, ValidationObserver, ValidationProvider } from 'vee-validate';
import { confirmed, email, max, min, numeric, regex, required } from 'vee-validate/dist/rules';
import Vue from 'vue';
import en from 'vee-validate/dist/locale/en.json';
import ru from 'vee-validate/dist/locale/ru.json';

// Add the required rule
extend('required', required);
extend('regex', regex);
extend('email', email);
extend('confirmed', confirmed);
extend('min', min);
extend('max', max);
extend('numeric', numeric);

extend('url', (value) => {
  const reg = /((?:http|https):\/\/)?([\w-]+(?:\.[\w-]+)+)(?:[\w.,@?^=%&amp;:~+#-]*[\w@?^=%&amp;~+#-])?/;

  if (reg.test(value)) {
    return true;
  }

  return 'Неверный url';
});

extend('phone', (value) => {
  const reg = /(^8|7|\+7)((\d{10})|(\s\(\d{3}\)\s\d{3}\s\d{2}\s\d{2}))/;
  if (reg.test(value)) {
    return true;
  }

  return 'Неверный номер телефона';
});

localize({
  ru: {
    messages: {
      min: (fieldName, values) => `Поле ${fieldName} должно быть не меньше ${values.length}`,
      max: (fieldName, values) => `Поле ${fieldName} должно быть не больше ${values.length}`,
      regex: (fieldName) => `Значение поля ${fieldName} имеет неверный формат`,
      confirmed: () => 'Пароли не совпадают',
      required: () => 'Обязательное поле',
      email: () => 'Неверный email',
      decimal: () => 'Неверное значение, значение должно быть целым числом',
      numeric: (fieldName) => `Значение поля ${fieldName} неверно.`,
    },
  },
});

localize('ru');
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
