<template>
  <div class="relative max-w-md mx-auto h-screen">
    <main class="main">
      <div
        id="top"
        class="mb-4"
      />

      <slot />
    </main>

    <NavMenu />

    <ImageGalleryOverlay />
  </div>
</template>

<script>
import ImageGalleryOverlay from '@/components/project/mobile/ImageGalleryOverlay';
import NavMenu from '@/components/ui/mobile/NavMenu';

export default {
  components: { ImageGalleryOverlay, NavMenu },
};
</script>

<style lang="scss" scoped>
  .main {
    width: 100%;
    height: calc(100% - 80px);
    overflow-y: scroll;
    font-size: 14px;
  }
</style>
