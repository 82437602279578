<template>
  <Card class="p-3 flex gap-2 mx-3 mb-3 shadow-md cursor-pointer">
    <div>
      <div
        :title="event.name"
        class="font-medium break-words max-h-28 overflow-hidden"
      >
        {{ cutField(event.name) }}
      </div>
      <div>
        {{ eventDates }}
      </div>
    </div>
    <div class="flex-grow">
      <div
        :title="event.client.name"
        class="break-words max-h-28 overflow-hidden"
      >
        {{ cutField(event.client.name) }}
      </div>

      <div class="text-sm">

        <div
          :title="event.place"
          class="text-gray-0 break-words max-h-20 overflow-hidden"
        >
          {{ cutField(event.place) }}
        </div>
      </div>
    </div>

    <Tag
      :color="eventStatus.color"
      :prepend-icon="eventStatus.icon"
    >
      {{ eventStatus.title }}
    </Tag>
  </Card>
</template>

<script>
import Tag from '@/components/ui/buttons/Tag';
import Card from '@/components/ui/common/Card';
import eventStatuses from '@/constatns/event-statuses';
import moment from 'moment/moment';

export default {
  name: 'EventListItem',

  components: { Tag, Card },

  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      statuses: [
        {
          name: eventStatuses.draft,
          title: this.$t(`adminEventsList.statuses.${eventStatuses.draft}`),
          icon: 'pen',
          color: 'secondary',
        },
        {
          name: eventStatuses.future,
          title: this.$t(`adminEventsList.statuses.${eventStatuses.future}`),
          icon: 'play',
          color: 'primary',
        },
        {
          name: eventStatuses.in_progress,
          title: this.$t(`adminEventsList.statuses.${eventStatuses.in_progress}`),
          icon: 'play',
          color: 'primary',
        },
        {
          name: eventStatuses.completed,
          title: this.$t(`adminEventsList.statuses.${eventStatuses.completed}`),
          icon: 'tick',
          color: 'green',
        },
      ],
    };
  },

  computed: {
    eventDates() {
      const startDay = moment(this.event.event_start_datetime).date();
      const startMonth = moment(this.event.event_start_datetime).format('MMMM');

      const endDay = moment(this.event.event_end_datetime).date();
      const endMonth = moment(this.event.event_end_datetime).format('MMMM');

      if (startMonth !== endMonth) {
        return `${startDay} ${startMonth} – ${endDay} ${endMonth} `;
      }
      if (startDay !== endDay) {
        return `${startDay}-${endDay} ${startMonth}`;
      }
      return `${startDay} ${startMonth}`;
    },

    eventStatus() {
      return this.statuses.find((s) => s.name === this.event.status);
    },
  },

  methods: {
    cutField(field) {
      return field.length < 90 ? field : `${field.slice(0, 90)}...`;
    },
  },
};
</script>
