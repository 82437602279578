<template>
  <div class="bg-primary-light h-screen flex justify-center items-center">
    <loader v-if="isLoading" />

    <h1
      v-else
      class="w-1/2"
    >
      {{ $t('joinGroupPage.notJoined') }}
    </h1>
  </div>
</template>

<script>
import Loader from '@/components/ui/common/Loader';
import { mapActions } from 'vuex';
import { getAccessToken as hasToken } from '@/utils/system';

export default {
  name: 'JoinGroupByInviteLink',

  components: { Loader },

  props: {
    eventId: String,
    inviteCode: String,
  },

  data() {
    return {
      isLoading: true,
    };
  },

  async mounted() {
    await this.tryToJoinGroup();
  },

  methods: {
    ...mapActions('members', ['joinGroup']),

    async tryToJoinGroup() {
      try {
        this.isLoading = true;

        await this.joinGroup({
          event_id: this.eventId,
          invite_code: this.inviteCode,
        });

        if (hasToken()) {
          await this.$router.push({ name: 'eventView' });
        } else {
          await this.$router.push({
            name: 'registration',
            params: {
              eventId: this.eventId,
              inviteCode: this.inviteCode,
            },
          });
        }
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
