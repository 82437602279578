<template>
  <div v-if="member">
    <div class="flex gap-4 items-center font-medium">
      <FileUploadInput
        ref="avatar"
        @input="addAvatar"
      >
        <Avatar
          :image="member.photo"
          :isModerator="isModerator"
          interactive
          @avatar-click="$refs.avatar.$refs.file.click()"
        />
      </FileUploadInput>

      <span class="break-words">{{ member.name }}</span>
    </div>

    <div class="break-words">
      {{ member.email }}
    </div>

    <div class="flex items-center gap-3">
      <Tag
        v-if="isMe"
        size="sm"
        color="error"
      >
        {{ $t('userRoles.admin') }}
      </Tag>

      <Tag
        v-if="isModerator"
        size="sm"
      >
        {{ $t('userRoles.moderator') }}
      </Tag>

      <div v-else>
        {{ member.position }}
      </div>
    </div>

    <div class="flex justify-end gap-3">
      <Dropdown
        v-if="groups.length > 1"
        :options="groupsForTransfer"
        @click="handleGroupTransferMenuClick"
      >
        <template #activator>
          <Btn
            color="gray"
            link
            prepend-icon="arrows"
          />
        </template>
      </Dropdown>

      <Dropdown
        :options="memberListItemActions"
        @click="handleMemberListItemMenuClick"
      >
        <template #activator>
          <Btn
            color="gray"
            link
            prepend-icon="overflow"
          />
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script>
import Tag from '@/components/ui/buttons/Tag';
import Avatar from '@/components/ui/common/Avatar';
import Dropdown from '@/components/ui/common/Dropdown';
import FileUploadInput from '@/components/ui/inputs/FileUploadInput';
import userRoles from '@/constatns/user-roles';
import copyToClipBoardMixin from '@/mixins/copyToClipBoardMixin';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AdminMembersListItem',

  mixins: [copyToClipBoardMixin],

  components: { FileUploadInput, Avatar, Dropdown, Tag },

  props: {
    sourceMember: {
      type: Object,
      required: true,
    },
    group: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      member: null,
      memberListItemActions: [
        {
          name: 'invitePersonally',
          title: this.$t('dropdownMenu.invite'),
          icon: 'duplicate',
        },
        {
          name: 'editMember',
          title: this.$t('dropdownMenu.edit'),
          icon: 'edit',
        },
        {
          name: 'deleteMember',
          title: this.$t('dropdownMenu.delete'),
          icon: 'bin',
          color: 'error',
        },
      ],
      isLoading: {
        moveMember: false,
        deleteMember: false,
        duplicateMember: false,
        avatarUploading: false,
      },
    };
  },

  watch: {
    sourceMember: {
      deep: true,
      handler() {
        this.member = JSON.parse(JSON.stringify(this.sourceMember));
      },
    },
  },

  computed: {
    ...mapState('events', ['selectedEvent']),
    ...mapState('members', ['groups']),
    ...mapState('auth', ['me']),

    isModerator() {
      return this.member?.role === userRoles.moderator;
    },

    isMe() {
      return this.member?.user_id === this.me.user_id;
    },

    groupsForTransfer() {
      const groups = this.groups
        .filter((g) => g.id !== this.group.id)
        .map((g) => (
          { name: g.id, title: g.name }
        ));

      groups.unshift({
        title: this.$t(
          'adminEventsView.participantsTab.groupListItem.membersListItem.groupTransferDropdownHeader',
        ),
        disabled: true,
      });

      return groups;
    },
  },

  mounted() {
    this.member = JSON.parse(JSON.stringify(this.sourceMember));
  },

  methods: {
    ...mapActions('members', [
      'addMember',
      'updateMembersOfGroup',
    ]),
    ...mapActions('files', [
      'uploadAvatar',
    ]),

    handleMemberListItemMenuClick(e) {
      this[e]();
    },

    async handleGroupTransferMenuClick(newGroupId) {
      try {
        this.isLoading.moveMember = true;

        await this.deleteMember();

        const newGroup = this.groups.find((g) => g.id === newGroupId);
        const member_ids = newGroup.members.map((member) => member.id);
        member_ids.push(this.member.id);

        await this.updateMembersOfGroup({
          event_id: this.selectedEvent.id,
          group_id: newGroupId,
          member_ids,
        });

        this.$toast(this.$t(
          'notification.success.memberAdded',
          { memberName: this.member.name },
        ));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.moveMember = false;
      }
    },

    async deleteMember() {
      try {
        this.isLoading.deleteMember = true;

        const member_ids = this.group.members
          .map((member) => member.id)
          .filter((id) => id !== this.member.id);

        await this.updateMembersOfGroup({
          event_id: this.selectedEvent.id,
          group_id: this.group.id,
          member_ids,
        });

        this.$toast(this.$t(
          'notification.success.memberDeleted',
          { memberName: this.member.name },
        ));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.deleteMember = false;
      }
    },

    invitePersonally() {
      const inviteLink = `${process.env.VUE_APP_APP_BASE_URL}/${this.member.user_id}/${this.member.invite_code}`;

      this.copyToClipboard(inviteLink);
      this.$toast(`${this.isCopied}: ${inviteLink}`);
    },

    async addAvatar(files) {
      try {
        this.isLoading.avatarUploading = true;

        const formData = new FormData();
        formData.append('file', files[0]);

        this.member.photo = await this.uploadAvatar(formData);

        await this.addMember({
          event_id: this.selectedEvent.id,
          group_id: this.group.id,
          ...this.member,
        });

        this.$toast(this.$t(
          'notification.success.avatarUploaded',
          { memberName: this.member.name },
        ));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.avatarUploading = false;
      }
    },

    async editMember() {
      this.$emit('edit-member');
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
