import { createClient, updateClientName } from '@/api/modules/clients';

export default {
  async createClient({ commit }, payload) {
    const { data } = await createClient(payload);
    return data;
  },

  async updateClientName({ commit }, payload) {
    await updateClientName(payload);
  },
};
