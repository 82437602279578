import http from '@/api/axios';
import ApiRoutes from '@/api/routes';

const {
  GetTacts,
  GetTact,
  CreateTact,
  UpdateTact,
  DeleteTact,
  UpdateTactContents,
  ChangeEventDates,
  ChangeTactPosition,
  ChangeTactInterval,
  UploadMemberPhotos,
  DeleteMemberPhotos,
  GetEventQuestionnaire,
  CreateEventQuestionnaire,
  UpdateQuestionsAndOptions,
  RespondToQuestionnaire,
} = ApiRoutes.Tacts;

export const getTacts = (event_id) => http.get(GetTacts(event_id));

export const getTact = ({ event_id, tact_id }) => http.get(GetTact(event_id, tact_id));

export const createTact = ({ event_id, ...data }) => http.post(CreateTact(event_id), data);

export const updateTact = ({ event_id, tact_id, ...data }) => http.patch(UpdateTact(event_id, tact_id), data);

export const deleteTact = ({ event_id, tact_id }) => http.delete(DeleteTact(event_id, tact_id));

export const updateTactContents = ({ event_id, tact_id, content_type, ...data }) => http.patch(UpdateTactContents(event_id, tact_id), data, { params: { content_type } });

export const changeEventDates = ({ event_id, ...data }) => http.patch(ChangeEventDates(event_id), data);

export const changeTactPosition = ({ event_id, tact_id, position }) => http.patch(ChangeTactPosition(event_id, tact_id), { position });

export const changeTactInterval = ({ event_id, tact_id, ...data }) => http.patch(ChangeTactInterval(event_id, tact_id), data);

export const uploadMemberPhotos = ({ event_id, tact_id, formData }) => http.post(UploadMemberPhotos(event_id, tact_id), formData, { headers: { 'Content-Type': 'multipart/form-data' } });

export const deleteMemberPhotos = ({ event_id, tact_id, ...data }) => http.delete(DeleteMemberPhotos(event_id, tact_id), { data: data.photos });

export const getEventQuestionnaire = ({ event_id, questionnaire_id }) => http.get(GetEventQuestionnaire(event_id, questionnaire_id));

export const createEventQuestionnaire = ({ event_id, tact_id, ...data }) => http.post(CreateEventQuestionnaire(event_id), data, { params: { tact_id } });

export const updateQuestionsAndOptions = ({ event_id, questionnaire_id, ...data }) => http.put(UpdateQuestionsAndOptions(event_id, questionnaire_id), data);

export const respondToQuestionnaire = ({ event_id, questionnaire_id, ...data }) => http.post(RespondToQuestionnaire(event_id, questionnaire_id), data.questionnaire_response);
