<template>
  <Loader
    v-if="isLoading"
    class="!my-80"
  />

  <div v-else-if="selectedEvent">
    <Timeline
      class="mx-3 mb-8 shadow-md cursor-pointer"
      @click.native="goToProgram"
    />

    <router-view />
  </div>

  <h2
    v-else
    class="flex justify-center items-center mt-32 mx-10 text-center"
  >
    {{ $t('eventView.noActiveEvent') }}
  </h2>
</template>

<script>
import Timeline from '@/components/project/mobile/Timeline';
import Loader from '@/components/ui/common/Loader';
import eventStatuses from '@/constatns/event-statuses';
import { SET_SELECTED_EVENT } from '@/store/events/mutation-types';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'EventView',

  components: { Loader, Timeline },

  props: {
    eventId: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      isLoading: false,
    };
  },

  computed: {
    ...mapState('auth', ['me', 'isAdmin']),
    ...mapState('events', ['events', 'selectedEvent']),
  },

  async created() {
    await this.fetchData();

    if (this.selectedEvent) {
      document.title = `Spaakl – ${this.selectedEvent.name}`;
    }
  },

  methods: {
    ...mapActions('auth', ['getMe']),
    ...mapActions('events', ['getEvents', 'getEvent']),

    ...mapMutations({
      setSelectedEvent: `events/${SET_SELECTED_EVENT}`,
    }),

    async fetchData() {
      try {
        this.isLoading = true;

        if (this.eventId) {
          await this.getEvent(this.eventId);
        } else {
          await this.getEvents({ status: eventStatuses.in_progress });

          // по идее, со статусом in_progress у одного юзера может быть только одна сессия,
          // по этому так
          const lastActiveEvent = this.events.at(-1);

          if (lastActiveEvent) {
            await this.getMe({ event_id: lastActiveEvent.id });

            if (this.me.role !== null) {
              this.setSelectedEvent(lastActiveEvent);
            }
          } else {
            this.$toast.error(this.$t('eventView.noActiveEvent'));
            await this.$router.push({ name: 'eventList' });
          }
        }
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading = false;
      }
    },

    goToProgram() {
      if (this.$route.name !== 'programPage') this.$router.push({ name: 'programPage' });
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
