export const SET_DAYS = 'SET_DAYS';
export const SET_ALL_TACTS = 'SET_ALL_TACTS';
export const SET_SELECTED_DAY_TACTS = 'SET_SELECTED_DAY_TACTS';
export const SET_SELECTED_DAY = 'SET_SELECTED_DAY';
export const SET_SELECTED_TACT = 'SET_SELECTED_TACT';
export const CLEAR_TACTS_STORE = 'CLEAR_TACTS_STORE';

export const ADD_TACT_TO_SELECTED_DAY = 'ADD_TACT_TO_SELECTED_DAY';
export const INSERT_TACT_INTO_SELECTED_DAY = 'INSERT_TACT_INTO_SELECTED_DAY';

export const REPLACE_TACT_IN_ALL_TACTS = 'REPLACE_TACT_IN_ALL_TACTS';
export const REPLACE_TACT_IN_SELECTED_DAY = 'REPLACE_TACT_IN_SELECTED_DAY';

export const REMOVE_TACT_FROM_SELECTED_DAY = 'REMOVE_TACT_FROM_SELECTED_DAY';
export const REMOVE_TACT_FROM_ALL_TACTS = 'REMOVE_TACT_FROM_ALL_TACTS';
