<template>
  <div
    v-if="question"
    class="flex flex-col gap-3 w-full"
  >
    <div class="flex justify-between items-center flex-wrap gap-3 w-full">
      <div
        class="flex gap-4 flex-grow basis-32 text-gray-0 text-sm"
        @mouseenter="showDeleteQuestionBtn = true"
        @mouseleave="showDeleteQuestionBtn = false"
      >
        <div>
          {{ $t('adminEventsView.programTab.questionnaire.question') }}:
          {{ question.order }}/{{ totalQuestions }}
        </div>

        <transition name="delete-btn">
          <div
            v-show="showDeleteQuestionBtn"
            class="flex items-center gap-1.5 text-secondary-red cursor-pointer"
            @click="$emit('delete-question')"
          >
            <SvgIcon name="x" />
          </div>
        </transition>
      </div>

      <TabList
        :active="activeTab"
        :tabs="tabs"
        grayPill
        @click="changeQuestionType"
      />
    </div>

    <Input
      v-model="question.text"
      @blur="questionTextFieldChange"
      @focus="onQuestionTextFieldFocus"
      @submit="questionTextFieldChange"
    />

    <div
      v-if="question.question_type !== questionTypes.text"
      class="flex flex-col gap-2"
    >
      <div
        v-for="option in question.options"
        :key="option.id"
        class="flex items-center"
        @mouseenter="showDeleteQuestionOptionBtn = option.id"
        @mouseleave="showDeleteQuestionOptionBtn = null"
      >
        <Checkbox
          v-if="question.question_type === questionTypes.checkbox"
          disabled
        />
        <Radio
          v-else-if="question.question_type === questionTypes.radio"
          disabled
        />

        <Input
          v-model="option.value"
          auto-width
          max-width="300px"
          min-width="30px"
          size="xs"
          text
          @blur="questionTextFieldChange"
          @focus="onQuestionTextFieldFocus"
          @submit="questionTextFieldChange"
        />

        <transition name="delete-btn">
          <div
            v-show="showDeleteQuestionOptionBtn === option.id"
            class="flex items-center gap-1.5 text-secondary-red cursor-pointer"
            @click="deleteOption(option.id)"
          >
            <SvgIcon name="x" />
          </div>
        </transition>
      </div>
      <div class="flex items-center">
        <Checkbox
          v-if="question.question_type === questionTypes.checkbox"
          disabled
        />
        <Radio
          v-else-if="question.question_type === questionTypes.radio"
          disabled
        />

        <Btn
          class="!font-normal"
          color="gray-light"
          link
          @click="addOption"
        >
          {{ $t('adminEventsView.programTab.questionnaire.addOption') }}
        </Btn>
      </div>

    </div>
  </div>
</template>

<script>
import Input from '@/components/ui/inputs/Input';
import Checkbox from '@/components/ui/selectors/Checkbox';
import Radio from '@/components/ui/selectors/Radio';
import TabList from '@/components/ui/selectors/TabList';
import questionTypes from '@/constatns/question-types';
import { mapState } from 'vuex';

export default {
  name: 'AdminQuestionsListItem',

  components: { Radio, Checkbox, Input, TabList },

  props: {
    sourceQuestion: {
      type: Object,
      required: true,
    },

    totalQuestions: {
      type: Number,
      required: true,
      default: 0,
    },
  },

  data() {
    return {
      question: null,
      showDeleteQuestionBtn: false,
      showDeleteQuestionOptionBtn: null,
      tabs: [
        {
          name: questionTypes.text,
          title: this.$t(`adminEventsView.programTab.questionnaire.questionTypes.${questionTypes.text}`),
        },
        {
          name: questionTypes.radio,
          title: this.$t(
            `adminEventsView.programTab.questionnaire.questionTypes.${questionTypes.radio}`,
          ),
        },
        {
          name: questionTypes.checkbox,
          title: this.$t(
            `adminEventsView.programTab.questionnaire.questionTypes.${questionTypes.checkbox}`,
          ),
        },
      ],
      activeTab: '',
      questionSnapshot: null,
      questionTypes,
    };
  },

  watch: {
    sourceQuestion: {
      deep: true,
      handler() {
        this.question = JSON.parse(JSON.stringify(this.sourceQuestion));
      },
    },
  },

  computed: {
    ...mapState('tacts', ['selectedTact']),
  },

  mounted() {
    this.question = JSON.parse(JSON.stringify(this.sourceQuestion));
    this.setActiveTab(this.tabs.find((tab) => tab.name === this.question.question_type));
  },

  methods: {
    changeQuestionType(tab) {
      this.setActiveTab(tab);

      const i = this.selectedTact.questionnaire.questions.findIndex(
        (q) => q.id === this.question.id,
      );

      if (i !== null && typeof i !== 'undefined') {
        this.question.question_type = tab.name;
        switch (tab.name) {
          case questionTypes.radio:
          case questionTypes.checkbox:
            if (!this.question.options.length) {
              this.question.options = [
                {
                  order: 1,
                  value: `${this.$t('adminEventsView.programTab.questionnaire.defaultQuestionOptionText')} 1`,
                }, {
                  order: 2,
                  value: `${this.$t('adminEventsView.programTab.questionnaire.defaultQuestionOptionText')} 2`,
                },
              ];
            }

            break;
          case questionTypes.text:
          default:
            this.question.options = [];
            break;
        }

        this.selectedTact.questionnaire.questions.splice(i, 1, this.question);
        this.$emit('update-questionnaire');
      }
    },

    addOption() {
      const i = this.selectedTact.questionnaire.questions.findIndex(
        (q) => q.id === this.question.id,
      );

      if (i !== null && typeof i !== 'undefined') {
        let order = 1;

        if (this.question.options) {
          order = this.question.options.at(-1).order + 1;
        }
        const newOption = {
          value: `${this.$t('adminEventsView.programTab.questionnaire.defaultQuestionOptionText')} ${order}`,
        };

        this.question.options.push({ ...newOption, order });
        this.selectedTact.questionnaire.questions.splice(i, 1, this.question);

        this.$emit('update-questionnaire');
      }
    },

    deleteOption(id) {
      if (this.question.options.length <= 2) {
        this.$toast.error(this.$t('notification.error.lessThanTwoOptionsNotAvailable'));
        return;
      }
      const i = this.question.options.findIndex((q) => q.id === id);

      if (i !== null && typeof i !== 'undefined') {
        this.question.options.splice(i, 1);

        const k = this.selectedTact.questionnaire.questions.findIndex(
          (q) => q.id === this.question.id,
        );

        if (k !== null && typeof k !== 'undefined') {
          this.selectedTact.questionnaire.questions.splice(k, 1, this.question);
        }

        this.$emit('update-questionnaire');
      }
    },

    questionTextFieldChange() {
      if (JSON.stringify(this.question) !== JSON.stringify(this.questionSnapshot)) {
        const i = this.selectedTact.questionnaire.questions.findIndex(
          (q) => q.id === this.question.id,
        );

        if (i !== null && typeof i !== 'undefined') {
          this.selectedTact.questionnaire.questions.splice(i, 1, this.question);
          this.questionSnapshot = JSON.parse(JSON.stringify(this.question));
          this.$emit('update-questionnaire');
        }
      } else {
        this.questionSnapshot = null;
      }
    },

    onQuestionTextFieldFocus() {
      this.questionSnapshot = JSON.parse(JSON.stringify(this.question));
    },

    setActiveTab(tab) {
      this.activeTab = tab.name;
    },
  },
};
</script>

<style lang="scss" scoped>
  .delete-btn-enter-active,
  .delete-btn-leave-active {
    @apply transition transition-all duration-300;
  }

  .delete-btn-enter,
  .delete-btn-leave-to {
    opacity: 0;
  }
</style>
