<template>
  <Card class="flex flex-col items-start gap-3 px-4 pt-3 pb-5 mb-5 h-fit max-h-screen overflow-y-scroll">
    <div class="flex flex-col gap-0.5 w-full">
      <div class="flex justify-between gap-4 my-2">
        <Textarea
          v-model="selectedTact.name"
          class="font-semibold flex-grow"
          text
          @blur="editTact"
          @focus="onTextareaFocus"
          @submit="editTact"
        />

        <Btn
          color="error"
          link
          prepend-icon="bin"
          @click="removeTact"
        />
      </div>

      <div
        v-if="selectedTact.is_before_start"
        class="text-gray-2"
      >
        {{ $t('adminEventsView.programTab.tactListItem.before') }} {{ endTimeFormatted }}
      </div>
      <div
        v-else
        class="text-gray-0"
      >
        {{ startTimeFormatted }}–{{ endTimeFormatted }}
      </div>
    </div>

    <div class="flex justify-between items-center flex-wrap gap-3 w-full">
      <TabList
        :active="activeTab"
        :tabs="tabs"
        grayPill
        @click="changeTactType"
      />

      <Checkbox
        v-if="selectedTact.tact_type === tactTypes.tact"
        :model-value="selectedTact.need_photo"
        tag
        @change="setIsNeedPhoto"
      >
        {{ $t('adminEventsView.programTab.tactDetails.needPhoto') }}
      </Checkbox>
    </div>

    <template v-if="!isRest">
      <div
        v-if="selectedTact.tact_type === tactTypes.tact"
        class="divider"
      />

      <div
        v-if="selectedTact.tact_type === tactTypes.tact"
        class="flex justify-between items-center w-full"
      >
        <Checkbox
          :model-value="selectedTact.questionnaire.is_mandatory"
          switcher
          @change="updateQuestionnaireStatus"
        >
          {{ $t('adminEventsView.programTab.tactDetails.needToFillQuestionnaire') }}
        </Checkbox>

        <div
          v-if="selectedTact.questionnaire.is_mandatory"
          class="flex items-center gap-1 -my-4"
        >
          <Tag
            v-if="!selectedTact.questionnaire.questions.length"
            color="error"
            prepend-icon="exclamation"
          >
            {{ $t('adminEventsView.programTab.tactDetails.tagNoQuestions') }}
          </Tag>

          <Btn
            color="gray"
            icon="chevron-right"
            @click="$emit('open-questionnaire')"
          />
        </div>
      </div>

      <template v-for="contentItem in selectedTact.contents">
        <div
          :key="`divider-${contentItem.id}`"
          class="divider"
        />

        <div
          :key="`item-${contentItem.id}`"
          class="flex flex-col gap-2 text-sm w-full"
        >
          <div class="flex items-center gap-2 font-medium">
            <SvgIcon
              :name="getIconName(contentItem.type)"
              class="text-gray-1"
            />

            {{ $t(`adminEventsView.programTab.tactDetails.content.${contentItem.type}`) }}
          </div>
          <div class="ml-6">
            <Textarea
              v-show="contentItem.showTextarea"
              :ref="contentItem.type"
              v-model="contentItem.text"
              text
              @focus="onTextareaFocus"
              @blur="editTactContents(contentItem)"
              @submit="editTactContents(contentItem)"
            />

            <div
              v-show="contentItem.showBtn"
              class="cursor-pointer text-gray-2 hover:underline"
              @click="onShowTextareaClick(contentItem)"
            >
              {{ $t('adminEventsView.programTab.tactDetails.addContent') }}
            </div>
          </div>
        </div>
      </template>
    </template>

    <ConfirmationModal
      id="tact-details"
      ref="confirmationModal"
    />
  </Card>
</template>

<script>
import Tag from '@/components/ui/buttons/Tag';
import Card from '@/components/ui/common/Card';
import Textarea from '@/components/ui/inputs/Textarea';
import ConfirmationModal from '@/components/ui/modals/ConfirmationModal';
import Checkbox from '@/components/ui/selectors/Checkbox';
import TabList from '@/components/ui/selectors/TabList';
import tactContentTypes from '@/constatns/tact-content-types';
import tactTypes from '@/constatns/tact-types';
import userRoles from '@/constatns/user-roles';
import { SET_SELECTED_TACT } from '@/store/tacts/mutation-types';
import moment from 'moment';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'AdminTactDetails',

  components: { ConfirmationModal, Tag, Card, Textarea, Checkbox, TabList },

  data() {
    return {
      tactSnapshot: null,
      tabs: [
        {
          name: tactTypes.tact,
          title: this.$t(`adminEventsView.programTab.tactDetails.tabs.${tactTypes.tact}`),
        },
        {
          name: tactTypes.meeting,
          title: this.$t(`adminEventsView.programTab.tactDetails.tabs.${tactTypes.meeting}`),
        },
        {
          name: tactTypes.rest,
          title: this.$t(`adminEventsView.programTab.tactDetails.tabs.${tactTypes.rest}`),
        },
      ],
      tactTypes,
      activeTab: '',
      isLoading: {
        updateTact: false,
        deleteTact: false,
        updateTactContents: false,
        updateQuestionnaire: false,
      },
      showTextarea: false,
    };
  },

  computed: {
    ...mapState('auth', ['me']),
    ...mapState('tacts', ['selectedTact']),
    ...mapState('events', ['selectedEvent']),

    isRest() {
      return this.selectedTact.tact_type === tactTypes.rest;
    },

    startTimeFormatted() {
      return moment(this.selectedTact.start_time).format('HH:mm');
    },

    endTimeFormatted() {
      return moment(this.selectedTact.end_time).format('HH:mm');
    },
  },

  mounted() {
    this.setActiveTab(this.tabs.find((tab) => tab.name === this.selectedTact.tact_type));
  },

  methods: {
    ...mapActions('tacts', [
      'updateTact',
      'deleteTact',
      'updateTactContents',
      'updateQuestionsAndOptions',
    ]),

    ...mapMutations({
      setSelectedTact: `tacts/${SET_SELECTED_TACT}`,
    }),

    onTextareaFocus() {
      this.tactSnapshot = JSON.parse(JSON.stringify(this.selectedTact));
    },

    async editTact() {
      if (JSON.stringify(this.selectedTact) !== JSON.stringify(this.tactSnapshot)) {
        try {
          this.isLoading.updateTact = true;

          await this.updateTact({
            event_id: this.selectedEvent.id,
            tact_id: this.selectedTact.id,
            name: this.selectedTact.name,
            need_photo: this.selectedTact.need_photo,
            tact_type: this.selectedTact.tact_type,
          });

          this.tactSnapshot = JSON.parse(JSON.stringify(this.selectedTact));
          this.$toast(this.$t('notification.success.successfullyUpdated'));
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading.updateTact = false;
        }
      } else {
        this.tactSnapshot = null;
      }
    },

    async changeTactType(tab) {
      this.setActiveTab(tab);
      this.selectedTact.tact_type = tab.name;

      await this.updateTact();
    },

    async setIsNeedPhoto() {
      this.selectedTact.need_photo = !this.selectedTact.need_photo;
      await this.updateTact();
    },

    async editTactContents(item) {
      if (!item.text) {
        item.showTextarea = false;
        item.showBtn = true;
      }

      if (JSON.stringify(this.selectedTact) !== JSON.stringify(this.tactSnapshot)) {
        try {
          this.isLoading.updateTactContents = true;

          await this.updateTactContents({
            event_id: this.selectedEvent.id,
            tact_id: this.selectedTact.id,
            content_type: item.type,
            text: item.text,
            role: this.me?.role || userRoles.guest,
          });

          this.tactSnapshot = JSON.parse(JSON.stringify(this.selectedTact));
          this.$toast(this.$t('notification.success.successfullyUpdated'));
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading.updateTactContents = false;
        }
      } else {
        this.tactSnapshot = null;
      }
    },

    async removeTact() {
      const ok = await this.$refs.confirmationModal.show({
        title: this.$t('tactDeleteModal.title'),
        message: this.$t('tactDeleteModal.message'),
        okButton: this.$t('tactDeleteModal.okBtn'),
      });

      if (ok) {
        try {
          this.isLoading.deleteTact = true;

          await this.deleteTact({
            event_id: this.selectedEvent.id,
            tact_id: this.selectedTact.id,
          });
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading.deleteTact = false;
        }
      }
    },

    async updateQuestionnaireStatus() {
      try {
        this.isLoading.updateQuestionnaire = true;
        this.selectedTact.questionnaire.is_mandatory = !this.selectedTact.questionnaire.is_mandatory;

        await this.updateQuestionsAndOptions({
          event_id: this.selectedEvent.id,
          questionnaire_id: this.selectedTact.questionnaire.id,
          ...this.selectedTact.questionnaire,
        });
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.updateQuestionnaire = false;
      }
    },

    setActiveTab(tab) {
      this.activeTab = tab.name;
    },

    getIconName(type) {
      switch (type) {
        case tactContentTypes.result:
          return 'target';
        case tactContentTypes.instruction:
          return 'info';
        case tactContentTypes.moderator:
          return 'point';
        case tactContentTypes.notes:
          return 'pen';
        default:
          return 'x';
      }
    },

    onShowTextareaClick(contentItem) {
      contentItem.showBtn = false;
      contentItem.showTextarea = true;
      this.$nextTick(() => {
        this.$refs[contentItem.type][0].$el.children[0].focus();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .divider {
    @apply border-b-1 border-solid border-gray-3 w-full my-2;
  }
</style>
