<template>
  <Card class="flex flex-col items-start gap-3 px-4 pt-3 pb-5 h-fit">
    <Btn
      color="gray"
      link
      prepend-icon="arrow-left"
      @click="$emit('return-to-tact-details')"
    >
      {{ selectedTact.name }}
    </Btn>

    <div class="mt-2 font-semibold">
      {{ $t('adminEventsView.programTab.questionnaire.title') }}
    </div>

    <div class="flex justify-between items-center flex-wrap gap-3 w-full">
      <div
        class="flex gap-4 w-full text-gray-0 text-sm"
        @mouseenter="showDeleteQuestions = true"
        @mouseleave="showDeleteQuestions = false"
      >
        <div>
          {{ $t('adminEventsView.programTab.questionnaire.questionsTotal') }}:
          {{ totalQuestions }}
        </div>

        <transition name="delete-btn">
          <div
            v-show="showDeleteQuestions"
            class="flex items-center gap-1.5 text-secondary-red cursor-pointer"
            @click="deleteAllQuestions"
          >
            <SvgIcon
              name="x"
            />

            {{ $t('adminEventsView.programTab.questionnaire.removeAll') }}
          </div>
        </transition>
      </div>
    </div>

    <div class="divider" />

    <template v-for="question in selectedTact.questionnaire.questions">
      <AdminQuestionsListItem
        :key="`question-${question.order}`"
        :source-question="question"
        :totalQuestions="totalQuestions"
        @delete-question="deleteQuestion(question)"
        @update-questionnaire="updateQuestionnaire"
      />

      <div
        :key="`divider-${question.order}`"
        class="divider"
      />
    </template>

    <Btn
      :loading="isLoading.addQuestion"
      prepend-icon="plus"
      @click="addQuestion"
    >
      {{ $t('adminEventsView.programTab.questionnaire.addQuestionBtn') }}
    </Btn>
  </Card>
</template>

<script>
import AdminQuestionsListItem from '@/components/project/admin/events/tacts/AdminQuestionsListItem';
import Card from '@/components/ui/common/Card';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AdminQuestionnaireDetails',

  components: { AdminQuestionsListItem, Card },

  data() {
    return {
      isLoading: {
        addQuestion: false,
        createQuestionnaire: false,
        updateQuestionnaire: false,
      },
      showDeleteQuestions: false,
      questionDraft: {
        text: this.$t('adminEventsView.programTab.questionnaire.defaultQuestionText'),
        hint: '',
        question_type: 'text',
        options: [],
      },
    };
  },

  computed: {
    ...mapState('events', ['selectedEvent']),
    ...mapState('tacts', ['selectedTact']),

    totalQuestions() {
      return this.selectedTact.questionnaire.questions.length;
    },
  },

  methods: {
    ...mapActions('tacts', [
      'updateQuestionsAndOptions',
    ]),

    async updateQuestionnaire() {
      try {
        this.isLoading.updateQuestionnaire = true;

        await this.updateQuestionsAndOptions({
          event_id: this.selectedEvent.id,
          questionnaire_id: this.selectedTact.questionnaire.id,
          ...this.selectedTact.questionnaire,
        });
        this.$toast(this.$t('notification.success.successfullyUpdated'));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.updateQuestionnaire = false;
      }
    },

    addQuestion() {
      let order = 1;

      if (this.totalQuestions) {
        order = this.selectedTact.questionnaire.questions.at(-1).order + 1;
      }

      this.selectedTact.questionnaire.questions.push({ ...this.questionDraft, order });
      this.updateQuestionnaire();
    },

    deleteAllQuestions() {
      this.selectedTact.questionnaire.questions = [];
      this.updateQuestionnaire();
    },

    deleteQuestion(question) {
      const i = this.selectedTact.questionnaire.questions.findIndex(
        (q) => q.order === question.order,
      );

      if (i !== null && typeof i !== 'undefined') {
        this.selectedTact.questionnaire.questions.splice(i, 1);

        for (let j = 0; j < this.selectedTact.questionnaire.questions.length; j++) {
          this.selectedTact.questionnaire.questions[j].order = j + 1;
        }

        this.updateQuestionnaire();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .divider {
    @apply border-b-1 border-solid border-gray-3 w-full my-2;
  }

  .delete-btn-enter-active,
  .delete-btn-leave-active {
    @apply transition transition-all duration-300;
  }

  .delete-btn-enter,
  .delete-btn-leave-to {
    opacity: 0;
  }
</style>
