import '@/assets/styles/_toast.scss';
import Vue from 'vue';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

/* eslint-disable no-restricted-globals */
Vue.use(Toast, {
  timeout: 3000,
  position: location.origin === process.env.VUE_APP_ADMIN_BASE_URL ? 'bottom-center' : 'top-center',
  maxToasts: 3,
  toastClassName: 'spaakl',
  transition: 'Vue-Toastification__fade',
});
