<template>
  <Loader
    v-if="isLoading.tacts"
    class="!my-80"
  />

  <div
    v-else-if="tactsWithTasks.length"
    class="flex flex-col gap-2"
  >
    <div class="flex flex-col items-start gap-2 mx-3">
      <Btn
        link
        prepend-icon="arrow-left"
        color="gray"
        class="my-2"
        @click="$router.push({name: 'myGroup'})"
      >
        {{ myGroup.name }}
      </Btn>

      <div class="flex items-start gap-4 h2">
        <Avatar
          :image="member.photo"
          :is-moderator="isModerator"
          size="lg"
        />

        <div>
          <h2>{{ member.name }}</h2>

          <div class="text-gray-0">{{ member.position }}</div>
        </div>
      </div>
    </div>

    <div class="bg-white w-full pt-3 mt-3">
      <div class="flex gap-2 m-2 mr-3">
        <SvgIcon
          name="lightning"
          size="md"
          class="text-gray-1"
        />

        <span class="font-medium text-base flex-grow">
          {{ $t('membersPage.tasks.tasks') }}
        </span>

        <Tag
          v-if="member.skipped_tasks"

          color="error"
          size="sm"
        >
          {{ member.skipped_tasks }} {{ $t('membersPage.tasks.skipped') }}
        </Tag>
      </div>

      <div
        v-for="tact in tactsWithTasks"
        :key="tact.id"
        class="flex flex-col gap-2 py-3 mx-4 border-b-1 border-solid border-gray-3 last-of-type:border-none"
      >
        <router-link
          class="flex w-full items-center gap-1 cursor-pointer"
          :to="{ name: 'tasksByTactId', params: { memberId: $route.params.memberId, tactId: tact.id, sourceTact: tact }}"
        >
          <span class="font-monospace text-gray-0 basis-5">{{ tact.position + 1 }}</span>
          <span class="font-medium text-sm">{{ tact.name }}</span>
        </router-link>

        <div class="flex gap-2 ml-6">
          <Tag
            v-if="tact.need_photo"
            size="sm"
            class="text-gray-0 mb-2"
            :color="tact.member_photos.length ? 'success' : 'error'"
            :prepend-icon="tact.member_photos.length ? 'tick' : 'x'"
          >
            {{ $t('membersPage.tasks.tagPhoto') }}
          </Tag>

          <Tag
            v-if="tact.questionnaire.is_mandatory"
            size="sm"
            class="text-gray-0 mb-2"
            :color="tact.questionnaire.response.length ? 'success' : 'error'"
            :prepend-icon="tact.questionnaire.response.length ? 'tick' : 'x'"
          >
            {{ $t('membersPage.tasks.tagQuestionnaire') }}
          </Tag>
        </div>

        <div
          v-if="tact.need_photo"
          class="flex gap-4 pb-3 overflow-x-scroll ml-6"
        >
          <FileUploadInput
            v-if="isMe"
            :ref="`photo-${tact.id}`"
            @input="uploadPhotos($event, tact.id)"
          >
            <div
              class="drop-zone"
              @click="$refs[`photo-${tact.id}`][0].$refs.file.click()"
            >
              <SvgIcon
                v-if="isLoading.photoUploading === tact.id"
                name="spinner-solid"
                size="md"
                class="animate-spin"
              />
              <SvgIcon
                v-else
                name="photo"
                size="md"
              />
            </div>
          </FileUploadInput>

          <div
            v-for="(photo, key) in tact.member_photos"
            :key="photo"
            class="bg-cover bg-center flex-shrink-0 w-16 h-16 rounded-lg"
            :style="{'background-image': `url(${photo})`}"
            @click="showGallery(key, tact)"
          />
        </div>
      </div>
    </div>
  </div>

  <h2
    v-else
    class="flex justify-center items-center mt-32 mx-10 text-center"
  >
    {{ $t('membersPage.tasks.noTasks') }}
  </h2>
</template>

<script>
import Tag from '@/components/ui/buttons/Tag';
import Avatar from '@/components/ui/common/Avatar';
import Loader from '@/components/ui/common/Loader';
import FileUploadInput from '@/components/ui/inputs/FileUploadInput';
import tactTypes from '@/constatns/tact-types';
import userRoles from '@/constatns/user-roles';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'Tasks',

  components: { Avatar, Tag, Loader, FileUploadInput },

  props: {
    myGroup: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      tactsWithTasks: [],
      isLoading: {
        tacts: false,
        photoUploading: false,
      },
    };
  },

  computed: {
    ...mapState('auth', ['me']),
    ...mapState('events', ['selectedEvent']),

    member() {
      return this.myGroup.members.find((m) => +m.id === +this.$route.params.memberId);
    },

    isMe() {
      return this.member.user_id === this.me.user_id;
    },

    isModerator() {
      return this.member.role === userRoles.moderator;
    },
  },

  async mounted() {
    await this.fetchData();
  },

  methods: {
    ...mapActions('members', [
      'getCompletedTasks',
    ]),
    ...mapActions('tacts', [
      'uploadMemberPhotos',
    ]),

    async uploadPhotos(files, tactId) {
      try {
        this.isLoading.photoUploading = tactId;

        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
          let file = files.item(i);
          formData.append('uploaded_files', file);
        }

        const photos = await this.uploadMemberPhotos({
          event_id: this.selectedEvent.id,
          tact_id: tactId,
          formData,
        });

        const tact = this.tactsWithTasks.find((t) => t.id === tactId);
        tact.member_photos = photos;

        this.$toast(this.$t('notification.success.photoUploaded'));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.photoUploading = false;
      }
    },

    async fetchData() {
      try {
        this.isLoading.tacts = true;

        const response = await this.getCompletedTasks({
          event_id: this.selectedEvent.id,
          member_id: this.$route.params.memberId,
        });

        this.tactsWithTasks = response.filter(
          (tact) => (tact.tact_type !== tactTypes.rest)
            && (!!tact.need_photo || !!tact.questionnaire.is_mandatory),
        );
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.tacts = false;
      }
    },

    showGallery(selectedImageIndex, tact) {
      this.$modal.show('image-gallery', {
        header: tact.name,
        hint: this.member.name,
        images: tact.member_photos,
        selectedImageIndex,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
  .drop-zone {
    @apply w-16 h-16;
    @apply flex flex-col justify-center items-center;
    @apply border-1 border-dashed border-gray-1 rounded-lg;
    @apply bg-light text-gray-1;
    @apply cursor-pointer;
  }
</style>
