<template>
  <div>
    <Btn
      link
      prepend-icon="arrow-left"
      color="gray"
      class="absolute top-20"
      @click="$router.push({name: 'adminEventsList'})"
    >
      {{ $t('adminEventsView.backlink') }}
    </Btn>

    <loader
      v-if="isLoading.loadEvent"
      class="mt-24"
    />

    <div
      v-else-if="selectedEvent"
      class="flex flex-col"
    >
      <div class="flex justify-between items-center mt-3 mb-1.5">
        <Input
          v-model="selectedEvent.name"
          class="text-4xl font-medium"
          text
          auto-select
          auto-width
          max-width="800px"
          @focus="onEventChangeableFieldFocus"
          @blur="onEventChange"
          @submit="onEventChange"
        />

        <div class="flex gap-2">
          <Btn
            prepend-icon="download"
            color="secondary"
            class="invisible"
          >
            {{ $t('adminEventsView.downloadReportBtn') }}
          </Btn>
          <Btn
            color="gray"
            icon="duplicate"
            class="invisible"
          />
          <Dropdown
            v-if="selectedEvent.status !== eventStatuses.in_progress"
            :options="programPageActions"
            @click="handleAdminEventsViewMenuClick"
          >
            <template #activator>
              <Btn
                icon="overflow"
                color="gray"
              />
            </template>
          </Dropdown>
        </div>
      </div>

      <div class="flex justify-start items-center mb-8 flex-wrap">
        <template v-if="allTacts.length">
          <div class="flex gap-2 items-center pr-5">
            <SvgIcon
              name="calendar"
              color="gray"
            />

            {{ eventDates }}
          </div>

          <div class="divider" />

          <div class="flex gap-2 items-center px-5">
            <SvgIcon
              name="time-1"
              :color="eventStartTime ? 'gray' : 'red'"
            />

            <span v-if="eventStartTime">
              {{ $t('adminEventsView.startsAt') }} {{ eventStartTime }}
            </span>

            <span
              v-else
              class="text-secondary-red"
            >
              {{ $t('adminEventsView.noStartTime') }}
            </span>
          </div>

          <div class="divider" />
        </template>

        <div :class="allTacts.length ? 'px-3' : '-ml-2 pr-3'">
          <Input
            v-model="selectedEvent.place"
            prepend-icon="pin"
            gray-bg
            auto-select
            auto-width
            min-width="20px"
            max-width="300px"
            @focus="onEventChangeableFieldFocus"
            @blur="onEventChange"
            @submit="onEventChange"
          />
        </div>

        <div class="divider" />

        <div class="pl-3">
          <Input
            v-model="selectedEvent.client.name"
            prepend-icon="building"
            gray-bg
            auto-select
            auto-width
            min-width="20px"
            max-width="350px"
            @focus="onClientNameFocus"
            @blur="onClientChange"
            @submit="onClientChange"
          />
        </div>
      </div>

      <TabList
        :tabs="tabs"
        :active="activeTab"
        class="mb-8 -ml-1.5"
        clear
        @click="setActiveTab"
      />

      <transition
        name="tab"
        mode="out-in"
      >
        <component
          :is="activeTab"
          @change-date="fetchData"
        />
      </transition>
    </div>

    <div
      v-else
      class="flex flex-col justify-center items-center mt-24"
    >
      <h3>{{ $t('pageNotFound.text') }}</h3>
    </div>

    <ConfirmationModal ref="confirmationModal" />
  </div>
</template>

<script>
import AdminNotifications from '@/components/project/admin/events/eventsView/AdminNotifications';
import AdminParticipants from '@/components/project/admin/events/eventsView/AdminParticipants';
import AdminProgram from '@/components/project/admin/events/eventsView/AdminProgram';
import Dropdown from '@/components/ui/common/Dropdown';
import Loader from '@/components/ui/common/Loader';
import Input from '@/components/ui/inputs/Input';
import ConfirmationModal from '@/components/ui/modals/ConfirmationModal';
import TabList from '@/components/ui/selectors/TabList';
import eventStatuses from '@/constatns/event-statuses';
import { SET_SELECTED_DAY, SET_SELECTED_EVENT } from '@/store/events/mutation-types';
import { SET_SELECTED_TACT } from '@/store/tacts/mutation-types';
import moment from 'moment';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'AdminEventsView',

  components: {
    ConfirmationModal,
    TabList,
    Dropdown,
    Input,
    Loader,
    AdminProgram,
    AdminParticipants,
    AdminNotifications,
  },

  props: {
    createdEvent: {
      type: Object,
    },
  },

  data() {
    return {
      eventSnapshot: null,
      clientSnapshot: null,
      isLoading: {
        loadEvent: false,
        updateEvent: false,
        deleteEvent: false,
        updateClient: false,
      },
      programPageActions: [
        {
          name: 'removeEvent',
          title: this.$t('dropdownMenu.delete'),
          icon: 'bin',
          color: 'error',
        },
      ],
      tabs: [
        { name: 'AdminProgram', title: this.$t('adminEventsView.tabs.program'), icon: 'list' },
        { name: 'AdminParticipants', title: this.$t('adminEventsView.tabs.participants'), icon: 'team' },
        // { name: 'AdminNotifications', title: this.$t('adminEventsView.tabs.notifications'), icon: 'notifications' },
      ],
      activeTab: '',
      eventStatuses,
    };
  },

  computed: {
    ...mapState('events', ['selectedEvent']),
    ...mapState('tacts', ['days', 'allTacts']),

    eventDates() {
      if (!this.days.length) return '';

      const startDay = moment(this.days[0].date).date();
      const startMonth = moment(this.days[0].date).format('MMMM');

      if (this.days.length > 1) {
        const endDay = moment(this.days.at(-1).date).date();
        const endMonth = moment(this.days.at(-1).date).format('MMMM');

        if (startMonth !== endMonth) {
          return `${startDay} ${startMonth} – ${endDay} ${endMonth} `;
        }

        return `${startDay}-${endDay} ${startMonth}`;
      }

      return `${startDay} ${startMonth}`;
    },

    eventStartTime() {
      const firstTact = this.allTacts[0].is_before_start ? this.allTacts[1] : this.allTacts[0];
      if (!firstTact) return '';

      return moment(firstTact.start_time).format('HH:mm');
    },
  },

  async mounted() {
    if (!this.createdEvent) {
      await this.fetchData();
    } else {
      await this.setSelectedEvent(this.createdEvent);
    }

    this.setActiveTab(this.tabs[0]);
    document.title = `Spaakl – ${this.selectedEvent.name}`;
  },

  destroyed() {
    this.clearStore();
  },

  methods: {
    ...mapActions('clients', [
      'updateClientName',
    ]),
    ...mapActions('events', [
      'getEvent',
      'clearEvent',
      'updateEvent',
      'deleteEvent',
    ]),
    ...mapActions('tacts', [
      'clearTacts',
    ]),

    ...mapMutations({
      setSelectedEvent: `events/${SET_SELECTED_EVENT}`,
      setSelectedDay: `events/${SET_SELECTED_DAY}`,
      setSelectedTact: `tacts/${SET_SELECTED_TACT}`,
    }),

    async fetchData() {
      try {
        this.isLoading.loadEvent = true;

        await this.getEvent(this.$route.params.eventId);
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.loadEvent = false;
      }
    },

    clearStore() {
      this.clearEvent();
      this.clearTacts();
    },

    onEventChangeableFieldFocus() {
      this.eventSnapshot = JSON.parse(JSON.stringify(this.selectedEvent));
    },

    async onEventChange() {
      if (JSON.stringify(this.selectedEvent) !== JSON.stringify(this.eventSnapshot)) {
        if (this.selectedEvent.name === '' || this.selectedEvent.place === '') {
          this.$toast.error(this.$t('notification.error.canNotBeEmpty'));
          return;
        }

        try {
          this.isLoading.updateEvent = true;

          await this.updateEvent({
            event_id: this.selectedEvent.id,
            name: this.selectedEvent.name,
            place: this.selectedEvent.place,
          });

          this.eventSnapshot = JSON.parse(JSON.stringify(this.selectedEvent));
          this.$toast(this.$t('notification.success.successfullyUpdated'));
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading.updateEvent = false;
        }
      } else {
        this.eventSnapshot = null;
      }
    },

    onClientNameFocus() {
      this.clientSnapshot = JSON.parse(JSON.stringify(this.selectedEvent.client));
    },

    async onClientChange() {
      if (JSON.stringify(this.selectedEvent.client) !== JSON.stringify(this.clientSnapshot)) {
        if (this.selectedEvent.client.name === '') {
          this.$toast.error(this.$t('notification.error.canNotBeEmpty'));
          return;
        }

        try {
          this.isLoading.updateClient = true;

          await this.updateClientName({
            client_id: this.selectedEvent.client.id,
            name: this.selectedEvent.client.name,
          });

          this.clientSnapshot = JSON.parse(JSON.stringify(this.selectedEvent.client));
          this.$toast(this.$t('notification.success.successfullyUpdated'));
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading.updateClient = false;
        }
      } else {
        this.clientSnapshot = null;
      }
    },

    setActiveTab(tab) {
      this.activeTab = tab.name;
    },

    async handleAdminEventsViewMenuClick(e) {
      this[e]();
    },

    async removeEvent() {
      const ok = await this.$refs.confirmationModal.show({
        title: this.$t('eventDeleteModal.title'),
        message: this.$t('eventDeleteModal.message'),
        okButton: this.$t('eventDeleteModal.okBtn'),
      });

      if (ok) {
        try {
          this.isLoading.deleteEvent = true;

          await this.deleteEvent({
            event_id: this.selectedEvent.id,
          });

          this.$toast(this.$t(
            'notification.success.eventDeleted',
            { eventName: this.selectedEvent.name },
          ));
          this.$router.push({ name: 'adminEventsList' });
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading.deleteEvent = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .divider {
    width: 1px;
    height: 20px;
    @apply bg-gray-2;
  }

  .tab-enter-active,
  .tab-leave-active {
    @apply transition transition-all duration-300;
  }

  .tab-enter,
  .tab-leave-to {
    opacity: 0;
  }
</style>
