import FloatingVue, { VTooltip } from 'floating-vue';
import 'floating-vue/dist/style.css';
import Vue from 'vue';

Vue.directive('tooltip', VTooltip);

Vue.use(FloatingVue, {
  themes: {
    tooltip: {
      // Close tooltip on click on tooltip target
      hideTriggers: (events) => [...events],
    },
  },
});
