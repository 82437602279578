<template>
  <Loader
    v-if="isLoading.loadGroup"
    class="!my-80"
  />

  <router-view
    v-else-if="myGroup"
    :my-group="myGroup"
  />

  <h2
    v-else
    class="text-center mt-24"
  >
    {{ $t('membersPage.myGroup.notMemberError') }}
  </h2>
</template>

<script>
import Loader from '@/components/ui/common/Loader';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'MembersPage',

  components: { Loader },

  data() {
    return {
      isLoading: {
        loadGroup: false,
      },
      myGroup: null,
    };
  },

  computed: {
    ...mapState('events', ['selectedEvent']),
  },

  mounted() {
    this.fetchData();
  },

  methods: {
    ...mapActions('members', ['getCurrentGroup']),

    async fetchData() {
      try {
        this.isLoading.loadGroup = true;

        this.myGroup = await this.getCurrentGroup(this.selectedEvent.id);
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.loadGroup = false;
      }
    },
  },
};
</script>
