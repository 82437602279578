<template>
  <ul
    :class="computedListClasses"
    class="tab-list"
  >
    <li
      v-for="(tab, key) in tabs"
      :key="tab.name"
      :class="getItemClasses(tab)"
      class="tab-list__item"
      @click="clickToTabItem(tab)"
    >
      <div class="flex items-center gap-2">
        <SvgIcon
          v-if="tab.icon"
          :name="tab.icon"
        />

        {{ tab.title }}

        <template v-if="numbered">
          {{ key + 1 }}
        </template>
      </div>

      <v-date-picker
        v-if="tab.date"
        :value="tab.date"
        color="indigo"
        title-position="left"
        trim-weeks
        @dayclick="value => $emit('change-date', { name: tab.name, date: value.date })"
      >
        <template v-slot="{ togglePopover }">
          <Tag
            :append-icon="interactiveTag ? 'calendar' : ''"
            class="tab-list__item_date"
            color="transparent"
            :interactive="interactiveTag"
            @click.native.stop="interactiveTag ? togglePopover() : clickToTabItem(tab)"
          >
            {{ getShortDate(tab.date) }}
          </Tag>
        </template>
      </v-date-picker>
    </li>
  </ul>
</template>

<script>
import Tag from '@/components/ui/buttons/Tag';
import moment from 'moment';

export default {
  components: { Tag },
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    active: {
      type: [String, Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
    navLine: {
      type: Boolean,
      default: false,
    },
    grayPill: {
      type: Boolean,
      default: false,
    },
    numbered: {
      type: Boolean,
      default: false,
    },
    interactiveTag: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedListClasses() {
      return [
        { 'tab-list_clear': this.clear },
        { 'tab-list_nav-line': this.navLine },
        { 'tab-list_gray-pill': this.grayPill },
      ];
    },
  },

  methods: {
    getItemClasses(item) {
      return [
        item.class ? item.class : '',
        { 'tab-list_clear': this.clear },
        { 'tab-list_nav-line': this.navLine },
        { 'tab-list_gray-pill': this.grayPill },
        { 'tab-list__item_active': item.name === this.active },
        { 'tab-list__item_disabled': item.disabled },
      ];
    },

    getShortDate(date) {
      return moment(date).format('DD.MM');
    },

    clickToTabItem(item) {
      if (!Object.prototype.hasOwnProperty.call(item, 'disabled') || !item.disabled) {
        this.$emit('click', item);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .tab-list {
    position: relative;
    display: flex;
    gap: 4px;
    list-style: none;
    -webkit-tap-highlight-color: transparent;
    -ms-overflow-style: none;
    overflow-y: visible;

    &__item {
      min-height: 32px;
      border-radius: 6px;
      @apply text-gray-1;
      font-weight: 500;
      font-size: 11px;
      line-height: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      padding: 8px 10px;

      letter-spacing: 0.03em;
      text-transform: uppercase;
      font-feature-settings: 'ss02' on;
      cursor: pointer;
      transition: all 0.3s ease-out;

      &:hover {
        @apply bg-gray-5;
      }

      &_active {
        @apply text-white;
        @apply bg-secondary-blue;

        &:hover {
          @apply text-white;
          @apply bg-secondary-blue;
        }
      }

      &_disabled {
        opacity: .5;
        pointer-events: none;

        &:hover {
          background-color: transparent;
        }
      }
    }

    &_clear {
      gap: 8px;

      &.tab-list__item {
        min-height: 40px;
        padding: 0 8px 0 6px;
        font-size: 14px;
        text-transform: none;
        background-color: transparent;

        &:hover {
          @apply text-gray-0;
        }

        &.tab-list__item_active {
          @apply text-dark;
        }
      }
    }

    &_nav-line {
      gap: 16px;

      &.tab-list__item {
        padding: 10px 0 0 0;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 10px;
        font-size: 16px;
        text-transform: none;
        border-top: 2px solid transparent;
        border-radius: 0;
        background-color: transparent;

        &:hover {
          @apply border-t-2 border-solid border-gray-3 text-gray-0;
        }

        &.tab-list__item_active {
          @apply border-t-2 border-solid border-dark text-dark;
        }

        & .tab-list__item_date {
          font-size: 11px;
          @apply text-gray-1 -mx-1.5;
        }
      }
    }

    &_gray-pill {
      gap: 2px;
      border-radius: 100px;
      @apply bg-light;

      &.tab-list__item {
        height: 28px;
        padding: 6px 8px;
        display: flex;
        align-items: center;
        gap: 4px;
        font-size: 14px;
        font-weight: 400;
        text-transform: none;
        border: none;
        border-radius: 100px;

        &:hover {
          @apply bg-gray-5;
        }

        &.tab-list__item_active {
          @apply bg-gray-3 text-dark;
        }
      }
    }
  }
</style>
