import {
  changeEventDates,
  changeTactInterval,
  changeTactPosition,
  createEventQuestionnaire,
  createTact,
  deleteMemberPhotos,
  deleteTact,
  getEventQuestionnaire,
  getTact,
  getTacts,
  respondToQuestionnaire,
  updateQuestionsAndOptions,
  updateTact,
  updateTactContents,
  uploadMemberPhotos,
} from '@/api/modules/tacts';
import tactTypes from '@/constatns/tact-types';
import userRoles from '@/constatns/user-roles';
import i18n from '@/plugins/i18n';
import authState from '@/store/auth/state';
import eventsState from '@/store/events/state';
import * as Types from '@/store/tacts/mutation-types';
import state from '@/store/tacts/state';
import moment from 'moment';
import objectAssignDeep from 'object-assign-deep';
import Vue from 'vue';

export default {
  async getTacts({ commit }, payload) {
    // получили все такты
    const { data } = await getTacts(payload);
    // вынули из них уникальные даты
    const eventDates = [...new Set(data.map((t) => moment(t.start_time).format('YYYY-MM-DD')))];

    // засэтили дни сессии из уникальных дат
    const days = [];
    for (let dayNum = 1; dayNum <= eventDates.length; dayNum++) {
      const dayData = eventDates[dayNum - 1];

      days.push({
        name: dayData,
        title: i18n.messages[i18n.locale].adminEventsView.programTab.day,
        date: dayData,
        num: dayNum,
      });
    }
    commit(Types.SET_DAYS, days);

    // если не выбран день - сэтим текущий день, если не находим текущий - сэтим первый
    if (!state.selectedDay && days.length) {
      const today = moment().format('YYYY-MM-DD');
      const currentDay = days.find((d) => d.date === today);

      commit(Types.SET_SELECTED_DAY, currentDay || days[0]);
    }

    // сохраняем все такты
    commit(Types.SET_ALL_TACTS, data);

    // сохраняем такты для выбранного дня
    const selectedDayTacts = data.filter(
      (tact) => tact.start_time.includes(state.selectedDay.date),
    );
    commit(Types.SET_SELECTED_DAY_TACTS, selectedDayTacts);

    const currentTime = moment();

    const currentTact = data.find(
      (tact) => currentTime.format() > tact.start_time && currentTime.format() < tact.end_time,
    );

    // сэтим текущий такт
    if (selectedDayTacts.length && currentTact) {
      commit(Types.SET_SELECTED_TACT, currentTact);
    } else {
      commit(Types.SET_SELECTED_TACT, null);
    }
  },

  clearTacts({ commit }) {
    commit(Types.CLEAR_TACTS_STORE);
  },

  async getTact({ commit }, payload) {
    const { data } = await getTact(payload);
    return data;
  },

  async addDay({ commit }) {
    const lastDay = state.days.at(-1);

    const newDayDate = lastDay
      ? moment(lastDay.date).add(1, 'day').format('YYYY-MM-DD')
      : moment().add(1, 'day').format('YYYY-MM-DD');

    const newTactData = {
      event_id: eventsState.selectedEvent.id,
      name: i18n.messages[i18n.locale].adminEventsView.programTab.newTact.name,
      start_time: moment(newDayDate).hour(9).minute(0).second(0).format(),
      end_time: moment(newDayDate).hour(9).minute(30).second(0).format(),
      status: tactTypes.tact,
      role: authState.me?.role || userRoles.guest,
    };

    const { data } = await createTact(newTactData);

    const newDay = {
      name: newDayDate,
      title: `${i18n.messages[i18n.locale].adminEventsView.programTab.day}`,
      date: newDayDate,
    };

    commit(Types.SET_DAYS, [...state.days, newDay]);
    commit(Types.SET_ALL_TACTS, [...state.allTacts, data]);
    commit(Types.SET_SELECTED_DAY, newDay);
    commit(Types.ADD_TACT_TO_SELECTED_DAY, data);
    commit(Types.SET_SELECTED_TACT, data);
  },

  async createTact({ commit }, payload) {
    const { data } = await createTact(payload);

    if (state.days.length) {
      commit(Types.SET_ALL_TACTS, [...state.allTacts, data]);
      commit(Types.ADD_TACT_TO_SELECTED_DAY, data);
      commit(Types.SET_SELECTED_TACT, data);
    } else {
      await this.getTacts();
    }
  },

  async insertTact({ commit }, payload) {
    const { data } = await createTact(payload);

    commit(Types.SET_ALL_TACTS, [...state.allTacts, data]);
    commit(Types.INSERT_TACT_INTO_SELECTED_DAY, data);
  },

  async updateTact({ commit }, payload) {
    const { data } = await updateTact(payload);
    commit(Types.REPLACE_TACT_IN_ALL_TACTS, data);
    commit(Types.REPLACE_TACT_IN_SELECTED_DAY, data);
    if (state.selectedTact?.id === data.id) {
      commit(Types.SET_SELECTED_TACT, data);
    }
  },

  async deleteTact({ commit }, payload) {
    await deleteTact(payload);

    Vue.$toast(i18n.t('notification.success.tactDeleted', { tactName: state.selectedTact.name }));
    commit(Types.REMOVE_TACT_FROM_SELECTED_DAY, state.selectedTact);
    commit(Types.REMOVE_TACT_FROM_ALL_TACTS, state.selectedTact);
    commit(Types.SET_SELECTED_TACT, null);

    if (!state.selectedDayTacts.length) {
      const i = state.days.findIndex((d) => d.name === state.selectedDay.name);

      if (i !== null && typeof i !== 'undefined') {
        state.days.splice(i, 1);

        if (state.days.length) {
          commit(Types.SET_SELECTED_DAY, state.days[0]);
          commit(
            Types.SET_SELECTED_DAY_TACTS,
            state.allTacts.filter((tact) => tact.start_time.includes(state.selectedDay.date)),
          );
        } else {
          commit(Types.SET_SELECTED_DAY, null);
        }
      } else {
        commit(Types.SET_SELECTED_DAY, null);
        commit(Types.SET_DAYS, []);
      }
    }
  },

  async updateTactContents({ commit }, payload) {
    await updateTactContents(payload);
  },

  async changeEventDates({ commit }, payload) {
    await changeEventDates(payload);

    if (payload.from_date.slice(0, 10) === state.selectedDay.date) {
      commit(Types.SET_SELECTED_DAY, null);
    }
  },

  async changeTactPosition({ commit }, payload) {
    const { data } = await changeTactPosition(payload);

    const allTacts = [...new Map([...state.allTacts, ...data].map((tact) => [tact.id, tact])).values()];

    commit(Types.SET_ALL_TACTS, allTacts);
    commit(Types.SET_SELECTED_DAY_TACTS, data);
  },

  async changeTactInterval({ commit }, payload) {
    const { data } = await changeTactInterval(payload);

    const allTacts = [...new Map([...state.allTacts, ...data].map((tact) => [tact.id, tact])).values()];

    commit(Types.SET_ALL_TACTS, allTacts);
    commit(Types.SET_SELECTED_DAY_TACTS, data);
  },

  async uploadMemberPhotos({ commit }, payload) {
    const { data } = await uploadMemberPhotos(payload);
    return data;
  },

  async deleteMemberPhotos({ commit }, payload) {
    const { data } = await deleteMemberPhotos(payload);
    return data;
  },

  async getEventQuestionnaire({ commit }, payload) {
    const { data } = await getEventQuestionnaire(payload);
    const mergedObjects = objectAssignDeep(state.selectedTact, { questionnaire: data });
    commit(Types.SET_SELECTED_TACT, mergedObjects);
  },

  async createEventQuestionnaire({ commit }, payload) {
    const { data } = await createEventQuestionnaire(payload);
    const mergedObjects = objectAssignDeep(state.selectedTact, { questionnaire: data });
    commit(Types.SET_SELECTED_TACT, mergedObjects);
  },

  async updateQuestionsAndOptions({ commit }, payload) {
    const { data } = await updateQuestionsAndOptions(payload);
    const mergedObjects = objectAssignDeep(state.selectedTact, { questionnaire: data });
    commit(Types.SET_SELECTED_TACT, mergedObjects);
  },

  async respondToQuestionnaire({ commit }, payload) {
    await respondToQuestionnaire(payload);
  },
};
