<template>
  <Loader
    v-if="isLoading"
    class="!my-80"
  />

  <div v-else-if="events.length">
    <EventListItem
      v-for="event in events"
      :key="event.id"
      :event="event"
      @click.native="$router.push({ name: 'eventView', params: { eventId: event.id }})"
    />
  </div>

  <h2
    v-else
    class="flex justify-center items-center mt-32 mx-10 text-center"
  >
    {{ $t('eventList.noActiveEvents') }}
  </h2>
</template>
<script>
import EventListItem from '@/components/project/mobile/eventList/EventListItem';
import Loader from '@/components/ui/common/Loader';
import eventStatuses from '@/constatns/event-statuses';
import userRoles from '@/constatns/user-roles';
import { SET_SELECTED_EVENT } from '@/store/events/mutation-types';
import { mapActions, mapMutations, mapState } from 'vuex';

export default {
  name: 'EventList',

  components: { EventListItem, Loader },

  data() {
    return {
      events: [],
      isLoading: false,
    };
  },

  computed: {
    ...mapState('auth', ['isAdmin']),
  },

  async created() {
    this.setSelectedEvent(null);
    await this.fetchData();
  },

  methods: {
    ...mapActions('events', ['getAndReturnEvents']),
    ...mapActions('auth', ['getMyRole']),

    ...mapMutations({
      setSelectedEvent: `events/${SET_SELECTED_EVENT}`,
    }),

    async fetchData() {
      try {
        this.isLoading = true;

        const futureEvents = await this.getAndReturnEvents({ status: eventStatuses.future });

        for (const event of futureEvents) {
          // eslint-disable-next-line no-await-in-loop
          event.myRole = await this.getMyRole({ event_id: event.id });
        }
        const iAmModeratorInFutureEvents = futureEvents.filter(
          (event) => event.myRole !== userRoles.guest,
        );

        let currentEvents = await this.getAndReturnEvents({ status: eventStatuses.in_progress });
        currentEvents = currentEvents.filter((event) => event.myRole !== null);

        this.events = [...iAmModeratorInFutureEvents, ...currentEvents];
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
