<template>
  <div
    v-if="tact"
    class="flex flex-col items-start gap-5 h-fit"
  >
    <div class="flex flex-col items-start gap-1.5 mx-3">
      <Btn
        link
        prepend-icon="arrow-left"
        color="gray"
        class="mb-2"
        @click="$router.go(-1)"
      >
        {{ $t('currentTactPage.questionnaireRespond.backLink') }}
      </Btn>

      <h2 class="break-words">
        {{ tact.name }}
      </h2>

      <div class="flex items-center gap-2 text-gray-0">
        <template v-if="!tact.is_before_start">
          <SvgIcon name="time-1" />

          {{ tact.interval }} {{ $t('tactDetails.minutes') }}

          <div class="bg-gray-0 w-1 h-1 rounded-full" />
        </template>

        <div v-if="tact.is_before_start">
          {{ $t('tactDetails.before') }} {{ endTimeFormatted }}
        </div>

        <div v-else>
          {{ startTimeFormatted }}–{{ endTimeFormatted }}
        </div>

        <div class="bg-gray-0 w-1 h-1 rounded-full" />

        {{ currentDay.title }} {{ currentDay.num }}
      </div>
    </div>

    <div class="flex px-3 gap-2">
      <Tag
        v-if="tact.need_photo"
        size="sm"
        prepend-icon="photo"
        class="text-gray-0"
        color="secondary"
      >
        {{ $t('tactDetails.tagPhoto') }}
      </Tag>

      <Tag
        v-if="tact.questionnaire.is_mandatory"
        size="sm"
        prepend-icon="edit"
        class="text-gray-0"
        color="secondary"
      >
        {{ $t('tactDetails.tagQuestionnaire') }}
      </Tag>
    </div>

    <div class="bg-white px-3 w-full mb-24">
      <template v-for="contentItem in tact.contents">
        <div
          v-if="showItem(contentItem)"
          :key="`item-${contentItem.id}`"
          class="flex flex-col gap-2 text-sm py-3 border-b-1 border-solid border-gray-3 last-of-type:border-none"
        >
          <div class="flex items-center gap-2 font-medium">
            <SvgIcon
              :name="getIconName(contentItem.type)"
              class="text-gray-1"
            />

            {{ $t(`tactDetails.content.${contentItem.type}`) }}
          </div>

          <p class="ml-6 break-words font-sans whitespace-pre-line">{{ contentItem.text }}</p>
        </div>
      </template>
    </div>

    <CurrentTactTimer
      v-if="selectedTact && selectedTact.id === tact.id && iAmModerator"
      :current-tact="tact"
    />
  </div>

  <h2
    v-else
    class="flex justify-center items-center mt-32 mx-10 text-center"
  >
    {{ $t('pageNotFound.text') }}
  </h2>
</template>

<script>
import CurrentTactTimer from '@/components/project/mobile/currentTact/CurrentTactTimer';
import Tag from '@/components/ui/buttons/Tag';
import tactContentTypes from '@/constatns/tact-content-types';
import userRoles from '@/constatns/user-roles';
import moment from 'moment';
import { mapState } from 'vuex';

export default {
  name: 'TactDetails',

  components: { CurrentTactTimer, Tag },

  computed: {
    ...mapState('auth', ['me', 'isAdmin']),
    ...mapState('tacts', ['selectedTact', 'allTacts', 'days']),
    ...mapState('events', ['selectedEvent']),

    currentDay() {
      return this.days.find((day) => this.tact.start_time.includes(day.date));
    },

    startTimeFormatted() {
      return moment(this.tact.start_time).format('HH:mm');
    },

    endTimeFormatted() {
      return moment(this.tact.end_time).format('HH:mm');
    },

    tact() {
      return this.allTacts.find((t) => +t.id === +this.$route.params.tactId);
    },

    iAmModerator() {
      return this.me.role === userRoles.moderator || this.isAdmin;
    },
  },

  methods: {
    getIconName(type) {
      switch (type) {
        case tactContentTypes.result:
          return 'target';
        case tactContentTypes.instruction:
          return 'info';
        case tactContentTypes.moderator:
          return 'point';
        case tactContentTypes.notes:
          return 'pen';
        default:
          return 'x';
      }
    },

    showItem(item) {
      if (item.text) {
        if (item.type === tactContentTypes.notes) {
          return this.isAdmin;
        }

        if (item.type === tactContentTypes.moderator) {
          return this.me.role === userRoles.moderator || this.isAdmin;
        }
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
