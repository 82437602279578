<template>
  <div class="bg-primary-light h-screen flex justify-center items-center">
    <loader v-if="isLoading" />

    <h1
      v-else
      class="w-1/2"
    >
      {{ $t('joinGroupPage.notJoined') }}
    </h1>
  </div>
</template>

<script>
import Loader from '@/components/ui/common/Loader';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'LoginWithInviteCode',

  components: { Loader },

  props: {
    userId: String,
    inviteCode: String,
  },

  data() {
    return {
      isLoading: true,
    };
  },

  computed: {
    ...mapGetters('auth', ['isAdmin']),
  },

  async mounted() {
    await this.loginWithCode();
  },

  methods: {
    ...mapActions('auth', [
      'loginWithInviteCode',
      'getMe',
    ]),

    /* eslint-disable no-restricted-globals */
    async loginWithCode() {
      try {
        this.isLoading = true;

        await this.loginWithInviteCode({
          token: this.inviteCode,
          user: this.userId,
        });
        await this.getMe();

        if (location.origin === process.env.VUE_APP_ADMIN_BASE_URL) {
          this.isAdmin
            ? await this.$router.push({ name: 'adminEventsList' })
            : await this.$router.push({ name: 'accessDenied' });
        }

        if (location.origin === process.env.VUE_APP_APP_BASE_URL) {
          await this.$router.push({ name: 'eventView' });
        }
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
