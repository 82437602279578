import http from '@/api/axios';
import ApiRoutes from '@/api/routes';

const {
  CreateInviteCode,
  ActivateInviteCode,
  JoinGroupViaCodeIdentifier,
  ChangeMemberRole,
  AddMember,
  GetMembersOfEvent,
  UpdateMembersOfGroup,
  UpdateSelfInfo,
  GetCompletedTasks,
  GetGroupsOfEvent,
  CreateGroup,
  UpdateGroup,
  DeleteGroups,
  GetCurrentGroup,
  CreateSetGroup,
  DeleteSetGroup,
} = ApiRoutes.Members;

export const createInviteCode = ({ event_id, access_level }) => http.post(CreateInviteCode(event_id), null, { params: { access_level } });

export const activateInviteCode = ({ event_id, code }) => http.post(ActivateInviteCode(event_id), null, { params: { code } });

export const changeMemberRole = ({ event_id, member_id, access_level }) => http.post(ChangeMemberRole(event_id), null, { params: { member_id, access_level } });

export const addMember = ({ event_id, group_id, ...data }) => http.post(AddMember(event_id, group_id), data);

export const getMembersOfEvent = ({ event_id, q }) => http.get(GetMembersOfEvent(event_id), { params: { q } });

export const getGroupsOfEvent = ({ event_id, group_set_id, q }) => http.get(GetGroupsOfEvent(event_id), { params: { group_set_id, q } });

export const createGroup = ({ event_id, group_set_id, name }) => http.post(CreateGroup(event_id), { name }, { params: { group_set_id } });

export const updateGroup = ({ event_id, group_id, name }) => http.patch(UpdateGroup(event_id, group_id), { name });

export const deleteGroups = ({ event_id, group_set_id, group_ids }) => http.delete(DeleteGroups(event_id), { params: { group_set_id, group_ids } });

export const getCurrentGroup = (event_id) => http.get(GetCurrentGroup(event_id));

export const updateMembersOfGroup = ({ event_id, group_id, ...data }) => http.put(UpdateMembersOfGroup(event_id, group_id), data.member_ids);

export const updateSelfInfo = ({ event_id, ...data }) => http.patch(UpdateSelfInfo(event_id), data);

export const getCompletedTasks = ({ event_id, member_id }) => http.get(GetCompletedTasks(event_id, member_id));

export const createSetGroup = ({ event_id, start_datetime }) => http.post(CreateSetGroup(event_id), { start_datetime });

export const deleteSetGroup = ({ event_id, group_set_id }) => http.delete(DeleteSetGroup(event_id, group_set_id));

export const joinGroupViaCodeIdentifier = ({ event_id, invite_code }) => http.post(JoinGroupViaCodeIdentifier(event_id, invite_code));
