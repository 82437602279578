export default {
  GetTacts: (event_id) => `/events/${event_id}/tacts`,
  GetTact: (event_id, tact_id) => `/events/${event_id}/tacts/${tact_id}`,
  CreateTact: (event_id) => `/events/${event_id}/tacts`,
  UpdateTact: (event_id, tact_id) => `/events/${event_id}/tacts/${tact_id}`,
  DeleteTact: (event_id, tact_id) => `/events/${event_id}/tacts/${tact_id}`,

  UpdateTactContents: (event_id, tact_id) => `/events/${event_id}/tacts/${tact_id}/contents`,
  ChangeEventDates: (event_id) => `/events/${event_id}/change-dates`,
  ChangeTactPosition: (event_id, tact_id) => `/events/${event_id}/${tact_id}/reorder`,
  ChangeTactInterval: (event_id, tact_id) => `/events/${event_id}/${tact_id}/change-interval`,

  UploadMemberPhotos: (event_id, tact_id) => `/events/${event_id}/${tact_id}/photos`,
  DeleteMemberPhotos: (event_id, tact_id) => `/events/${event_id}/${tact_id}/photos`,

  GetEventQuestionnaire: (event_id, questionnaire_id) => `/events/${event_id}/questionnaires/${questionnaire_id}`,
  CreateEventQuestionnaire: (event_id) => `/events/${event_id}/questionnaires`,
  UpdateQuestionsAndOptions: (event_id, questionnaire_id) => `/events/${event_id}/questionnaires/${questionnaire_id}`,
  RespondToQuestionnaire: (event_id, questionnaire_id) => `/events/${event_id}/questionnaires/${questionnaire_id}/respond`,
};
