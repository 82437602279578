const appendParamsToCookie = (cookie, params) => {
  let newCookie = cookie;

  if (params) {
    Object.keys(params).forEach((key) => {
      newCookie += ` ${key}=${params[key]};`;
    });
  }

  return newCookie;
};

export const getCookie = (cookieName) => {
  const cookies = document.cookie
    .split(';')
    .reduce((result, cookie) => {
      const [name, value] = cookie.split('=');
      result[name.trimStart()] = value;
      return result;
    }, {});

  return cookies[cookieName];
};

export const setCookie = (name, value, params) => {
  document.cookie = appendParamsToCookie(`${name}=${value};`, params);
};

export const removeCookie = (name, params) => {
  document.cookie = appendParamsToCookie(`${name}= ;`, {
    ...params,
    'max-age': '0',
  });
};
