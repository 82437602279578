<template>
  <svg
    :class="classes"
    xmlns="http://www.w3.org/2000/svg"
    v-on="$listeners"
  >
    <use :xlink:href="'#' + name" />
  </svg>
</template>

<script>
const sizes = {
  xs: '12px',
  sm: '16px',
  md: '24px',
  lg: '40px',
};

const colors = [
  'light',
  'dark',
  'gray',
  'red',
  'green',
  'primary',
];

const requireIcon = require.context('@/assets/icons/', false, /\.svg$/);
const iconKeys = requireIcon.keys();
const iconNames = iconKeys.map((key) => key.slice(2, -4));

iconKeys.forEach(requireIcon);

export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      validator: (v) => {
        if (iconNames.includes(v)) {
          return true;
        }
        console.error(`Invalid x-icon name: "${v}"`);
        return false;
      },
    },
    size: {
      type: String,
      default: 'sm',
      validator: (val) => Object.keys(sizes).includes(val),
    },
    selfSize: {
      type: Boolean,
      default: false,
    },
    color: String,
  },

  computed: {
    classes() {
      return [
        'svg-icon',
        `svg-icon-${this.name}`,
        !this.selfSize && `svg-icon--${this.size}`,
        this.color && `svg-icon--${this.color}`,
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
  .svg-icon {
    flex-shrink: 0;

    & svg {
      fill: currentColor;
      stroke: currentColor;
    }

    &--xs {
      width: 12px;
      height: 12px;
    }

    &--sm {
      width: 16px;
      height: 16px;
    }

    &--md {
      width: 24px;
      height: 24px;
    }

    &--lg {
      width: 40px;
      height: 40px;
    }

    &--white {
      @apply fill-white stroke-white text-white
    }

    &--gray {
      @apply fill-gray-0 stroke-gray-0 text-gray-0
    }

    &--red {
      @apply fill-secondary-red stroke-secondary-red text-secondary-red
    }

    &--green {
      @apply fill-secondary-green-dark stroke-secondary-green-dark text-secondary-green-dark
    }

    &--black {
      @apply fill-dark stroke-dark text-dark
    }

    &--primary {
      @apply fill-primary stroke-primary text-primary
    }
  }
</style>
