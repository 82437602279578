<template>
  <div id="app">
    <component :is="layout">
      <keep-alive include="AdminEventsList">
        <router-view />
      </keep-alive>
    </component>
  </div>
</template>

<script>
export default {
  computed: {
    layout() {
      if (this.$route.meta.layout) {
        return `${this.$route.meta.layout}Layout`;
      }

      return 'DefaultLayout';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
