import Auth from '@/api/routes/modules/auth';
import Clients from '@/api/routes/modules/clients';
import Email from '@/api/routes/modules/email';
import Events from '@/api/routes/modules/events';
import Files from '@/api/routes/modules/files';
import Members from '@/api/routes/modules/members';
import Tacts from '@/api/routes/modules/tacts';

export default {
  Auth,
  Clients,
  Email,
  Events,
  Files,
  Members,
  Tacts,
};
