<template>
  <VDropdown>
    <slot name="activator" />
    <template #popper>
      <div class="dropdown__list">
        <Btn
          v-for="option in options"
          :key="option.name"
          v-close-popper
          :disabled="option.disabled"
          :color="option.color ? option.color : ''"
          :prepend-icon="option.icon"
          class="dropdown__list-item"
          :class="{disabled: option.disabled}"
          @click="$emit('click', option.name)"
        >
          {{ option.title }}
        </Btn>
      </div>
    </template>
  </VDropdown>
</template>

<script>
export default {
  name: 'Dropdown',

  props: {
    options: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss">
  .dropdown {
    &__list {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      font-size: 14px;
    }

    &__list-item {
      justify-content: flex-start !important;
      width: 100%;
      @apply border-b-1 border-solid border-gray-4;
      @apply transition transition-all duration-300;

      &:hover {
        @apply bg-light;

        &.disabled {
          @apply bg-white;
        }
      }

      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  .v-popper--theme-dropdown {
    .v-popper__inner {
      @apply bg-white;
      color: inherit;
      @apply border-1 border-solid border-gray-3;
      box-shadow: 0 6px 12px rgba(31, 37, 74, 0.1);
      border-radius: 10px;
    }
  }
</style>
