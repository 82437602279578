<template>
  <component
    :is="componentTag"
    :class="computedClasses"
    v-bind="linkProps"
    v-on="$listeners"
  >
    <div class="btn__content">
      <SvgIcon
        v-if="prependIcon"
        :name="prependIcon"
        :size="size"
      />
      <SvgIcon
        v-if="icon"
        :name="icon"
        :size="size"
        class="btn_icon"
      />
      <slot v-else />
      <SvgIcon
        v-if="appendIcon"
        :name="appendIcon"
        :size="size"
      />
    </div>
  </component>
</template>

<script>
const colors = ['primary', 'secondary', 'tertiary', 'error', 'gray', 'gray-light', ''];
const sizes = ['sm', 'md'];

export default {
  props: {
    color: {
      type: String,
      default: 'primary',
      validator: (v) => colors.includes(v),
    },
    size: {
      type: String,
      default: 'sm',
      validator: (v) => sizes.includes(v),
    },
    to: [String, Object],
    href: String,
    target: {
      type: String,
      validator: (v) => ['_self', '_blank', '_top'].includes(v),
    },
    download: [String, Boolean],
    loading: Boolean,
    icon: String,
    prependIcon: String,
    appendIcon: String,
    link: Boolean,
  },

  computed: {
    componentTag() {
      if (this.to) return 'router-link';
      if (this.href) return 'a';
      return 'button';
    },

    linkProps() {
      if (this.disabled) {
        return null;
      }
      if (this.to) {
        return { to: this.to };
      }
      if (this.href) {
        return {
          href: this.href,
          target: this.target || (
            (
              this.download || this.download === ''
            ) ? null : '_blank'
          ),
          download: this.download,
        };
      }
      return null;
    },

    computedClasses() {
      return [
        'btn',
        `btn_${this.size}`,
        `btn_${this.color}`,
        this.link && this.color ? `btn_link_${this.color}` : '',
        {
          btn_block: this.block,
          btn_icon: this.icon,
          btn_loading: this.loading,
          btn_link: this.link,
        },
      ];
    },
  },
};
</script>

<style lang="scss" scoped>
  .btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    box-sizing: border-box;
    text-decoration: none;
    outline: none;
    font-weight: 500;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    @apply transition transition-all duration-300;

    &[disabled] {
      @apply bg-gray-2;
      cursor: default;
      opacity: .5;
      pointer-events: none;
    }

    &__content {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 6px;
      height: 100%;
    }

    &_sm {
      height: 40px;
      padding: 13px 16px;
      border-radius: 8px;
      font-size: 14px;
    }

    &_md {
      height: 48px;
      padding: 13px 24px;
      border-radius: 10px;
    }

    &_primary {
      @apply bg-primary;
      box-shadow: 0 4px 14px -1px rgba(219, 214, 117, 0.48);

      &:hover,
      &:focus {
        @apply bg-primary-dark;
      }

      &.btn_loading {
        @include loading-btn(theme('colors.primary.DEFAULT'), theme('colors.primary.dark'));
      }

      &.btn_icon {
        border-radius: 100px;
        width: 40px;
      }
    }

    &_secondary {
      @apply bg-light;
      @apply border-1 border-solid border-gray-3;
      box-shadow: 0 4px 14px -1px rgba(31, 37, 74, 0.1);

      &:hover,
      &:focus {
        box-shadow: none;
      }

      &.btn_loading {
        @include loading-btn(theme('colors.light'), theme('colors.gray.3'));
      }

      &.btn_icon {
        border-radius: 100px;
        width: 40px;

        &.btn_md {
          border-radius: 10px;
        }
      }
    }

    &_tertiary {
      @apply bg-light;
      @apply border-1 border-solid border-gray-3;
      @apply text-gray-0;

      &:hover,
      &:focus {
        @apply bg-secondary-blue-light-2;
        @apply border-1 border-solid border-secondary-blue;
        @apply text-secondary-blue;
      }

      &.btn_loading {
        @apply border-1 border-solid border-secondary-blue;
        @apply text-secondary-blue;
        @include loading-btn(theme('colors.secondary.blue-light'), theme('colors.secondary.blue-light-2'));
      }

      &.btn_icon {
        border-radius: 100px;
        width: 40px;
      }
    }

    &_gray {
      @apply text-gray-0;

      &:hover {
        @apply text-dark;
      }

      &.btn_icon {
        border-radius: 100px;
        width: 40px;
      }
    }

    &_gray-light {
      @apply text-gray-2;

      &:hover {
        @apply text-gray-1;
      }

      &.btn_icon {
        border-radius: 100px;
        width: 40px;
      }
    }

    &_error {
      @apply text-secondary-red;
    }

    &_link {
      background-color: transparent;
      box-shadow: none;
      border: none;
      font-weight: 500;
      font-size: 16px;
      padding: 0;
      height: fit-content;
    }

    &_loading {
      cursor: wait;
      pointer-events: none;
      background-size: 50px 50px;
      animation: load 2s linear infinite;
    }

    @keyframes load {
      0% {
        background-position: 0 0;
      }

      100% {
        background-position: 50px 50px;
      }
    }
  }
</style>
