<template>
  <div>
    <div
      v-if="selectedDayTacts.length"
      id="program-top"
      class="flex gap-4 mb-4 flex-wrap"
    >
      <TabList
        v-if="selectedDay"
        :active="selectedDay.date"
        :tabs="days"
        class="flex-wrap"
        nav-line
        numbered
        interactive-tag
        @click="setSelectedDay"
        @change-date="changeDate"
      />

      <Btn
        v-if="selectedEvent.status !== eventStatuses.completed"
        class="mt-2"
        color="gray-light"
        link
        prepend-icon="plus"
        @click="addNewDay"
      >
        {{ $t('adminEventsView.programTab.day') }}
      </Btn>
    </div>

    <div class="flex gap-6">
      <div class="w-7/12 flex flex-col relative">
        <ListWithLoader
          :empty-list-text="$t('adminEventsView.noTactsAtAll')"
          :is-loading="isLoading.loadTacts"
          :length="selectedDayTacts.length"
        >
          <draggable
            :list="selectedDayTacts"
            handle=".handle"
            v-bind="dragOptions"
            :move="checkTactMoving"
            @change="handleTactMove"
          >
            <transition-group
              name="flip-list"
              type="transition"
            >
              <AdminTactListItem
                v-for="(tact, key) in selectedDayTacts"
                :key="tact.id"
                :is-first="key === 0"
                :source-tact="tact"
                @click.native="selectTact(tact)"
                @swap-tacts="swapTacts"
              />
            </transition-group>
          </draggable>
        </ListWithLoader>

        <Btn
          v-if="selectedEvent.status !== eventStatuses.completed"
          :loading="isLoading.createTact"
          class="w-full"
          color="tertiary"
          prepend-icon="plus"
          @click="createNewTact"
        >
          {{ $t('adminEventsView.programTab.tact') }}
        </Btn>

        <div
          v-if="isLoading.changeTactPosition"
          class="absolute flex justify-center items-center rounded-lg w-full h-full bg-primary-light-50 z-10"
        >
          <Loader />
        </div>
      </div>

      <transition
        mode="out-in"
        name="details"
      >
        <component
          :is="rightPanel"
          v-if="selectedTact"
          class="w-5/12 sticky top-4 bottom-4"
          @open-questionnaire="rightPanel = rightPanelOptions.QUESTIONNAIRE"
          @return-to-tact-details="rightPanel = rightPanelOptions.TACT"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import AdminQuestionnaireDetails from '@/components/project/admin/events/tacts/AdminQuestionnaireDetails';
import AdminTactDetails from '@/components/project/admin/events/tacts/AdminTactDetails';
import AdminTactListItem from '@/components/project/admin/events/tacts/AdminTactListItem';
import ListWithLoader from '@/components/ui/common/ListWithLoader';
import Loader from '@/components/ui/common/Loader';
import TabList from '@/components/ui/selectors/TabList';
import eventStatuses from '@/constatns/event-statuses';
import tactTypes from '@/constatns/tact-types';
import userRoles from '@/constatns/user-roles';
import {
  SET_SELECTED_TACT,
  SET_SELECTED_DAY,
  SET_SELECTED_DAY_TACTS,
} from '@/store/tacts/mutation-types';
import moment from 'moment';
import draggable from 'vuedraggable';
import { mapActions, mapMutations, mapState } from 'vuex';

const rightPanelOptions = {
  TACT: 'AdminTactDetails',
  QUESTIONNAIRE: 'AdminQuestionnaireDetails',
};

export default {
  name: 'AdminProgram',

  components: {
    Loader,
    draggable,
    AdminTactDetails,
    AdminQuestionnaireDetails,
    AdminTactListItem,
    ListWithLoader,
    TabList,
  },

  data() {
    return {
      rightPanel: rightPanelOptions.TACT,
      isLoading: {
        loadTacts: false,
        createTact: false,
        changeTactPosition: false,
      },
      rightPanelOptions,
      eventStatuses,
    };
  },

  watch: {
    async selectedDay() {
      if (this.selectedDay === null) return;

      this.setSelectedDayTacts([]);
      this.setSelectedTact(null);

      await this.$nextTick();

      const selectedDayTacts = this.allTacts.filter(
        (tact) => tact.start_time.includes(this.selectedDay.date),
      );

      this.setSelectedDayTacts(selectedDayTacts);
      this.setSelectedTact(selectedDayTacts[0]);

      await this.$nextTick();

      this.rightPanel = rightPanelOptions.TACT;
    },

    'selectedTact.id'() {
      this.rightPanel = rightPanelOptions.TACT;
    },
  },

  computed: {
    ...mapState('events', ['selectedEvent']),
    ...mapState('tacts', ['days', 'selectedDay', 'allTacts', 'selectedDayTacts', 'selectedTact']),
    ...mapState('auth', ['me']),

    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
  },

  async mounted() {
    await this.fetchData();
  },

  methods: {
    ...mapActions('tacts', [
      'getTacts',
      'createTact',
      'addDay',
      'changeEventDates',
      'changeTactPosition',
    ]),

    ...mapMutations({
      setSelectedDay: `tacts/${SET_SELECTED_DAY}`,
      setSelectedDayTacts: `tacts/${SET_SELECTED_DAY_TACTS}`,
      setSelectedTact: `tacts/${SET_SELECTED_TACT}`,
    }),

    async fetchData() {
      try {
        this.isLoading.loadTacts = true;

        await this.getTacts(this.selectedEvent.id);
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.loadTacts = false;
      }
    },

    async createNewTact() {
      try {
        this.isLoading.createTact = true;

        // обновить такты, время могло быть изменено модератором с мобилки
        await this.getTacts(this.selectedEvent.id);

        if (this.allTacts.length) {
          const previousTact = this.selectedDayTacts.at(-1);

          let newTactStartTime;
          let newTactEndTime;

          if (previousTact) {
            newTactStartTime = moment(previousTact.end_time).second(0);
            newTactEndTime = moment(newTactStartTime).add(30, 'minutes');

            if (newTactEndTime.date() !== newTactStartTime.date()) {
              this.$toast.error(this.$t('notification.error.cantCreateTactInCurrentDay'));
              return;
            }
          } else {
            newTactStartTime = moment().hour(9).minute(0).second(0);
            newTactEndTime = moment(newTactStartTime).add(60, 'minutes');
          }

          await this.createTact({
            event_id: this.selectedEvent.id,
            name: this.$t('adminEventsView.programTab.newTact.name'),
            start_time: newTactStartTime.format(),
            end_time: newTactEndTime.format(),
            tact_type: tactTypes.tact,
            role: this.me?.role || userRoles.guest,
            // interval: 0,
            // position: 0,
            is_before_start: false,
            need_photo: false,
          });
        } else {
          await this.addDay();
        }
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.createTact = false;
      }
    },

    async selectTact(tact) {
      if (tact.id === this.selectedTact?.id) return;

      this.setSelectedTact(null);

      await new Promise((resolve) => {
        setTimeout(resolve, 200);
      });

      this.setSelectedTact(tact);
    },

    async addNewDay() {
      await this.addDay();
    },

    async changeDate(value) {
      if (this.days.map((d) => d.date).includes(moment(value.date).format('YYYY-MM-DD'))) {
        this.$toast.error(this.$t('notification.error.cantChangeDayDate'));
        return;
      }

      try {
        await this.changeEventDates({
          event_id: this.selectedEvent.id,
          from_date: moment(value.name).format('YYYY-MM-DDT00:00:00+00:00'),
          to_date: moment(value.date).format('YYYY-MM-DDT00:00:00+00:00'),
        });
        await this.fetchData();
      } catch (e) {
        // console.log(e);
      }
    },

    checkTactMoving(evt) {
      return !(this.selectedDayTacts[evt.draggedContext.index].is_locked
        || this.selectedDayTacts[evt.draggedContext.index].is_before_start
        || this.selectedDayTacts[evt.draggedContext.futureIndex].is_before_start);
    },

    async handleTactMove(evt) {
      try {
        this.isLoading.changeTactPosition = true;

        await this.changeTactPosition({
          event_id: this.selectedEvent.id,
          tact_id: evt.moved.element.id,
          position: evt.moved.newIndex,
        });
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.changeTactPosition = false;
      }
    },

    async swapTacts(movingTact, isMoveUp) {
      try {
        this.isLoading.changeTactPosition = true;

        let newPosition;

        if (isMoveUp) {
          if (movingTact.position === 0
            || (movingTact.position === 1 && this.selectedDayTacts.at(0).is_before_start)
          ) {
            this.$toast.error(this.$t('notification.error.cantMoveThisTact'));
            return;
          }
          newPosition = movingTact.position - 1;
        } else {
          if (movingTact.id === this.selectedDayTacts.at(-1).id) {
            this.$toast.error(this.$t('notification.error.cantMoveThisTact'));
            return;
          }
          newPosition = movingTact.position + 1;
        }

        await this.changeTactPosition({
          event_id: this.selectedEvent.id,
          tact_id: movingTact.id,
          position: newPosition,
        });
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.changeTactPosition = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .details-enter-active,
  .details-leave-active {
    @apply transition transition-all duration-300;
  }

  .details-enter,
  .details-leave-to {
    opacity: 0;
  }

  .flip-list-move {
    transition: transform 0.5s;
  }

  .ghost {
    opacity: 0.5;
    @apply bg-gray-4;
  }
</style>
