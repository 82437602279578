<template>
  <main class="w-full h-screen">
    <slot />
  </main>
</template>

<script>
export default {};
</script>

<style lang="scss">
</style>
