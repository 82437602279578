<template>
  <Loader
    v-if="isLoading.loadEvent"
    class="!my-80"
  />

  <div
    v-else-if="selectedEvent"
    class="flex flex-col"
  >
    <h2 class="px-3">
      {{ $t('programPage.program') }}
    </h2>

    <div class="flex justify-between items-center px-3 mt-3 mb-8 text-gray-0">
      {{ selectedEvent.name }}, {{ selectedEvent.place }}
    </div>

    <TabList
      v-if="selectedDayTacts.length"
      :active="selectedDay.name"
      :tabs="days"
      class="flex-wrap px-3 py-2 mb-3 !sticky top-0 bg-light"
      nav-line
      numbered
      @click="handleClickOnDay"
    />

    <template v-for="(dayTacts, key) in tactsSplitByDays">
      <div
        :ref="`day-${key}`"
        :key="`day-${key}`"
      />

      <div
        :key="`divider-${key}`"
        class="day-divider"
      >
        {{ `${$t('programPage.day')} ${key + 1}` }}
      </div>

      <TactListItem
        v-for="tact in dayTacts"
        :key="`tact-${tact.id}`"
        :tact="tact"
        class="cursor-pointer"
        @click.native="$router.push({name: 'tactDetails', params: {tactId: tact.id}})"
      />
    </template>
  </div>

  <h2
    v-else
    class="flex flex-col justify-center items-center mt-24"
  >
    {{ $t('pageNotFound.text') }}
  </h2>
</template>

<script>
import TactListItem from '@/components/project/mobile/program/TactListItem';
import Loader from '@/components/ui/common/Loader';
import TabList from '@/components/ui/selectors/TabList';
import { SET_SELECTED_DAY } from '@/store/events/mutation-types';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'ProgramPage',

  components: {
    TactListItem,
    TabList,
    Loader,
  },

  data() {
    return {
      isLoading: {
        loadEvent: false,
      },
    };
  },

  computed: {
    ...mapState('events', ['selectedEvent']),
    ...mapState('tacts', ['days', 'selectedDay', 'allTacts', 'selectedDayTacts', 'selectedTact']),
    ...mapState('auth', ['me']),

    tactsSplitByDays() {
      let daysWithTacts = [];

      for (const i in this.days) {
        if (this.days[i]) {
          const dayTacts = this.allTacts.filter(
            (tact) => tact.start_time.includes(this.days[i].date),
          );
          daysWithTacts.push(dayTacts);
        }
      }

      return daysWithTacts;
    },
  },

  async mounted() {
    await new Promise((resolve) => {
      setTimeout(resolve, 300);
    });

    if (this.selectedDay) {
      this.handleClickOnDay(this.selectedDay);
    }
  },

  methods: {
    ...mapMutations({
      setSelectedDay: `tacts/${SET_SELECTED_DAY}`,
    }),

    handleClickOnDay(selectedDay) {
      this.setSelectedDay(selectedDay);

      const i = this.days.findIndex((day) => day.date === this.selectedDay.date);

      if (this.$refs[`day-${i}`] && i !== null && typeof i !== 'undefined') {
        this.$refs[`day-${i}`][0].scrollIntoView({ behavior: 'smooth' });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .day-divider {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 78px;
    @apply bg-transparent text-gray-1 text-xs uppercase;
  }
</style>
