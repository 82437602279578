<template>
  <div class="admin-tact-list-item">
    <Card
      v-if="tact"
      :class="{'!bg-secondary-blue text-gray-4': isRest}"
      class="admin-tact-list-item__card"
      @mouseenter.native="hovered = true"
      @mouseleave.native="hovered = false"
    >
      <div
        class="admin-tact-list-item__side-line"
        :class="sideLineColor"
      />

      <div
        v-if="tact.is_before_start || tact.is_locked"
        class="w-5 mr-0.5"
      />
      <div
        v-else
        class="flex flex-col justify-between mt-3 ml-1.5 mb-2"
      >
        <Btn
          class="-mt-1 handle cursor-grab"
          color="gray-light"
          link
          prepend-icon="drag"
          @click.stop
        />

        <div class="flex flex-col gap-1 -mb-1">
          <Btn
            color="gray-light"
            link
            prepend-icon="lift-up"
            @click.stop="$emit('swap-tacts', sourceTact, true)"
          />

          <Btn
            color="gray-light"
            link
            prepend-icon="lift-down"
            @click.stop="$emit('swap-tacts', sourceTact, false)"
          />
        </div>
      </div>

      <div
        :class="isRest ? 'text-white' : 'text-gray-0'"
        class="w-44 my-3 pr-3 h-fit flex-shrink-0 border-r-1 border-solid border-gray-4"
      >
        <Checkbox
          v-if="isFirst && selectedDayTacts.length > 1 && !isRest"
          :model-value="tact.is_before_start"
          class="mb-3"
          switcher
          @change="setIsBeforeStart"
          @click.native.stop
        >
          {{ $t('adminEventsView.programTab.tactListItem.beforeStart') }}
        </Checkbox>

        <div
          v-if="tact.is_before_start"
          class="flex items-center gap-2 text-gray-1"
        >
          <span class="text-xs">
            {{ $t('adminEventsView.programTab.tactListItem.before') }}
          </span>

          <span class="font-monospace text-sm">
            {{ endTimeFormatted }}
          </span>
        </div>

        <div
          v-else
          class="flex items-center gap-2"
        >
          <Tag
            :color="tact.is_locked ? 'error' : 'light'"
            icon="elephant"
            @click.native.stop="setIsLocked"
          />

          <div class="flex items-center gap-2 font-monospace text-sm">
            <span
              v-if="tact.is_locked || !isFirst"
              class="ml-2"
            >
              {{ startTimeFormatted }}
            </span>

            <TimeInput
              v-else
              :value="startTimeFormatted"
              class="-mr-2"
              @change="value => changeStartTimeWithDebounce(value)"
              @input="value => setStartTime(value)"
              @click.stop
            />
            –
            <span :class="{'text-secondary-red': gap < 0}">
              {{ endTimeFormatted }}
            </span>
          </div>
        </div>

        <Tag
          v-if="gap < 0"
          size="sm"
          color="error"
          class="!w-full mt-2 uppercase"
        >
          {{ $t('adminEventsView.programTab.tactListItem.overlap') }}
          {{ gap * -1 }}
          {{ $t('adminEventsView.programTab.tactListItem.minutes') }}
        </Tag>

        <div
          v-if="!tact.is_before_start"
          class="flex items-center gap-1.5 mt-3"
        >
          <span
            v-if="tact.is_before_start"
            class="font-monospace"
          >
            {{ tact.interval }}
          </span>

          <Input
            v-else
            :value="tact.interval"
            type="number"
            min="1"
            max="999"
            size="xs"
            prepend-icon="time-1"
            class="font-monospace"
            :class="isRest ? 'text-dark' : 'text-gray-0'"
            max-width="50px"
            min-width="50px"
            auto-width
            @click.stop
            @change="changeIntervalWithDebounce"
            @input="e => tact.interval = e"
          />

          {{ $t('adminEventsView.programTab.tactListItem.minutes') }}
        </div>
      </div>

      <div class="flex flex-col flex-grow gap-1.5 my-3">
        <Textarea
          v-model="tact.name"
          text
          class="font-semibold flex-grow"
          @mousedown="handleTextareaClick"
          @focus="onTextareaFocus"
          @blur="editTact"
          @submit="editTact"
        />

        <div class="flex items-center gap-2">
          <Tag
            v-if="sourceTact.need_photo"
            color="success"
          >
            {{ $t('adminEventsView.programTab.tactListItem.tagPhoto') }}
          </Tag>

          <Tag
            v-if="sourceTact.questionnaire.is_mandatory"
            v-tooltip="sourceTact.questionnaire.questions.length ? '' : $t('adminEventsView.programTab.tactDetails.tagNoQuestions')"
            :color="sourceTact.questionnaire.questions.length ? 'success' : 'error'"
            :prepend-icon="sourceTact.questionnaire.questions.length ? '' : 'exclamation'"
          >
            {{ $t('adminEventsView.programTab.tactListItem.tagQuestionnaire') }}
          </Tag>
        </div>
      </div>

      <div class="w-16 h-4 gap-1 flex justify-end items-center mt-4 pr-2">
        <div
          v-if="selectedTact && (tact.id === selectedTact.id)"
          v-tooltip="$t('adminEventsView.programTab.tactListItem.selectedTactDotTooltip')"
          :class="isRest? 'bg-light' : 'bg-secondary-blue'"
          class="w-2 h-2 rounded-full"
        />

        <transition
          mode="out-in"
          name="chevron"
        >
          <SvgIcon
            v-if="hovered"
            name="chevron-right"
          />
          <div
            v-else
            class="w-4"
          />
        </transition>
      </div>
    </Card>

    <div
      v-if="gap > 0"
      class="flex justify-center items-center gap-2 mb-4"
    >
      <SvgIcon
        name="chevrons-up-down"
        class="inline text-gray-0"
      />

      <span class="text-gray-0">
        {{ $t('adminEventsView.programTab.tactListItem.gap') }}
        {{ gap }}
        {{ $t('adminEventsView.programTab.tactListItem.minutes') }}.
      </span>

      <Btn
        link
        append-icon="arrow-right"
        color="gray"
        :loading="isLoading.addBreak"
        @click="addBreak"
      >
        {{ $t('adminEventsView.programTab.tactListItem.addBreakBtn') }}
      </Btn>
    </div>
  </div>
</template>

<script>
import Tag from '@/components/ui/buttons/Tag';
import Card from '@/components/ui/common/Card';
import Input from '@/components/ui/inputs/Input';
import Textarea from '@/components/ui/inputs/Textarea';
import TimeInput from '@/components/ui/inputs/TimeInput';
import Checkbox from '@/components/ui/selectors/Checkbox';
import tactTypes from '@/constatns/tact-types';
import userRoles from '@/constatns/user-roles';
import moment from 'moment';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AdminTactListItem',

  components: { Textarea, TimeInput, Input, Checkbox, Card, Tag },

  props: {
    sourceTact: {
      type: Object,
      required: true,
    },
    isFirst: Boolean,
  },

  data() {
    return {
      tact: null,
      tactSnapshot: null,
      hovered: false,
      isElephant: false,
      isLoading: {
        updateTact: false,
        changeTactInterval: false,
        addBreak: false,
      },
      timeout: null,
    };
  },

  watch: {
    sourceTact: {
      deep: true,
      handler() {
        this.setInnerTact();
      },
    },
  },

  computed: {
    ...mapState('tacts', ['selectedTact', 'selectedDayTacts']),
    ...mapState('events', ['selectedEvent']),

    startTimeFormatted() {
      return moment(this.tact.start_time).format('HH:mm');
    },

    endTimeFormatted() {
      return moment(this.tact.end_time).format('HH:mm');
    },

    sideLineColor() {
      const currentTime = moment().format();

      if (currentTime > this.tact.end_time) {
        return 'bg-secondary-green';
      }
      if (currentTime > this.tact.start_time && currentTime < this.tact.end_time) {
        return 'bg-primary';
      }
      return '';
    },

    isRest() {
      return this.sourceTact.tact_type === tactTypes.rest;
    },

    nextTact() {
      const currentTactIndex = this.selectedDayTacts.findIndex((t) => t.id === this.sourceTact.id);

      return this.selectedDayTacts[currentTactIndex + 1];
    },

    gap() {
      if (!this.nextTact) return 0;

      return moment(this.nextTact.start_time).diff(this.sourceTact.end_time, 'minutes');
    },
  },

  mounted() {
    this.setInnerTact();
  },

  methods: {
    ...mapActions('tacts', [
      'insertTact',
      'updateTact',
      'changeTactInterval',
    ]),

    setInnerTact() {
      this.tact = JSON.parse(JSON.stringify(this.sourceTact));
    },

    changeIntervalWithDebounce(e) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.tact.interval = e.target.value;

      this.timeout = setTimeout(() => {
        this.changeInterval();
      }, 1000);
    },

    async changeInterval() {
      try {
        this.isLoading.changeTactInterval = true;

        const payload = {
          event_id: this.selectedEvent.id,
          tact_id: this.tact.id,
          interval: +this.tact.interval,
        };

        if (!this.tact.is_locked) {
          payload.start_time = moment(this.tact.start_time).format('YYYY-MM-DDTHH:mm:ssZ');
        }

        await this.changeTactInterval(payload);

        this.$toast(this.$t('notification.success.successfullyUpdated'));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.changeTactInterval = false;
      }
    },

    setStartTime(time) {
      if (typeof time !== 'string') return;

      const date = moment(this.tact.start_time).format('YYYY-MM-DD');

      this.tact.start_time = moment().format(`${date}T${time}:00Z`);
    },

    changeStartTimeWithDebounce(time) {
      if (this.timeout) {
        clearTimeout(this.timeout);
      }

      this.setStartTime(time);

      this.timeout = setTimeout(() => {
        this.changeInterval();
      }, 1000);
    },

    async setIsLocked() {
      this.tact.is_locked = !this.tact.is_locked;

      await this.updateTact();
    },

    async setIsBeforeStart() {
      this.tact.is_before_start = !this.tact.is_before_start;

      await this.updateTact();
    },

    handleTextareaClick(e) {
      if (this.tact.id !== this.selectedTact.id) {
        e.preventDefault();
      }
    },

    onTextareaFocus() {
      this.tactSnapshot = JSON.parse(JSON.stringify(this.tact));
    },

    async editTact() {
      if (JSON.stringify(this.tact) !== JSON.stringify(this.tactSnapshot)) {
        try {
          this.isLoading.updateTact = true;

          await this.updateTact({
            event_id: this.selectedEvent.id,
            tact_id: this.tact.id,
            name: this.tact.name,
            start_time: this.tact.start_time,
            end_time: this.tact.end_time,
            interval: this.tact.interval,
            position: this.tact.position,
            is_before_start: this.tact.is_before_start,
            is_locked: this.tact.is_locked,
          });

          this.tactSnapshot = JSON.parse(JSON.stringify(this.tact));
          this.$toast(this.$t('notification.success.successfullyUpdated'));
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading.updateTact = false;
        }
      } else {
        this.tactSnapshot = null;
      }
    },

    async addBreak() {
      try {
        this.isLoading.addBreak = true;

        await this.insertTact({
          event_id: this.selectedEvent.id,
          name: this.$t('adminEventsView.programTab.tactDetails.tabs.rest'),
          start_time: moment(this.tact.end_time).format(),
          end_time: moment(this.nextTact.start_time).format(),
          position: this.tact.position + 1,
          tact_type: tactTypes.rest,
          role: this.me?.role || userRoles.guest,
        });
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.addBreak = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .admin-tact-list-item {
    &__card {
      @apply relative flex gap-4 cursor-pointer mb-4;
      @apply transition transition-all duration-150;

      &:hover {
        box-shadow: 0 5px 8px rgba(31, 37, 74, 0.06);
        transform: translateY(-2px);
      }
    }

    &__side-line {
      position: absolute;
      left: -14px;
      height: 100%;
      width: 6px;
      border-radius: 2px;
    }
  }

  .chevron-enter-active,
  .chevron-leave-active {
    @apply transition transition-all duration-150;
  }

  .chevron-enter,
  .chevron-leave-to {
    opacity: 0;
  }
</style>
