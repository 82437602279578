<template>
  <div
    class="flex justify-between items-start gap-2 py-3 mx-3 bg-white border-b-1 border-solid border-gray-3 last-of-type:border-none"
    :class="{'cursor-pointer': isMe || iAmModerator}"
    @click="pushToTasksPage"
  >
    <Avatar
      :image="member.photo"
      :is-moderator="isModerator"
    />

    <div class="flex flex-col flex-grow">
      <div class="font-medium">{{ member.name }}</div>
      <div class="text-gray-0 text-sm">
        {{ isModerator ? $t('membersPage.myGroup.isModerator') : member.position }}

        <span v-if="isMe">
          ({{ $t('membersPage.myGroup.me') }}<span v-if="isAdmin">, {{ $t('membersPage.myGroup.isAdmin') }}</span>)
        </span>
      </div>

      <Tag
        v-if="iAmModerator && member.skipped_tasks"
        color="error"
        prepend-icon="lightning"
        class="mt-2"
      >
        {{ $t('membersPage.myGroup.tasksSkipped') }}: {{ member.skipped_tasks }}
      </Tag>
    </div>

    <Btn
      v-if="isMe || iAmModerator"
      color="gray"
      icon="chevron-right"
    />
  </div>
</template>

<script>
import Tag from '@/components/ui/buttons/Tag';
import Avatar from '@/components/ui/common/Avatar';
import userRoles from '@/constatns/user-roles';
import { mapState } from 'vuex';

export default {
  name: 'MyGroupMemberListItem',
  components: { Tag, Avatar },
  props: {
    member: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {};
  },

  computed: {
    ...mapState('auth', ['me', 'isAdmin']),

    isModerator() {
      return this.member.role === userRoles.moderator;
    },

    isMe() {
      return this.member.user_id === this.me.user_id;
    },

    iAmModerator() {
      return this.me.role === userRoles.moderator || this.isAdmin;
    },
  },

  methods: {
    pushToTasksPage() {
      if (this.isMe || this.iAmModerator) {
        this.$router.push({ name: 'tasks', params: { memberId: this.member.id } });
      }
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
