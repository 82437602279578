<template>
  <Card
    v-if="group"
    class="flex flex-col gap-1 p-4 mb-4"
  >
    <div class="flex justify-between text-gray-0">
      <div class="flex justify-start items-center gap-2 uppercase text-xs">
        <div class="pr-1.5">
          {{ $t('adminEventsView.participantsTab.group') + ' ' + group.key }}
        </div>

        <div class="dot-divider" />

        <div class="px-1.5">
          {{ group.short_identifier }}
        </div>

        <div class="dot-divider" />

        <Tag
          v-tooltip="isCopied"
          color="transparent"
          interactive
          @click.native="copyToClipboard(inviteLink)"
        >
          {{ $t('adminEventsView.participantsTab.groupListItem.inviteLink') }}
        </Tag>
      </div>

      <Dropdown
        :options="groupListItemActions"
        @click="handleGroupMenuItemClick"
      >
        <template #activator>
          <Btn
            color="gray"
            link
            prepend-icon="overflow"
          />
        </template>
      </Dropdown>
    </div>

    <Input
      v-model="group.name"
      class="font-semibold -ml-2"
      invisible
      @blur="onGroupNameChange"
      @submit="onGroupNameChange"
    />

    <div class="members-list">
      <div class="members-list-item members-list-header">
        <div>
          {{ $t('adminEventsView.participantsTab.groupListItem.membersListHeader.name') }}
        </div>
        <div>
          {{ $t('adminEventsView.participantsTab.groupListItem.membersListHeader.email') }}
        </div>
        <div>
          {{ $t('adminEventsView.participantsTab.groupListItem.membersListHeader.position') }}
        </div>
        <div />
        <div />
      </div>

      <component
        :is="member.state"
        v-for="member in group.members"
        :key="member.id"
        :source-member="member"
        :group="group"
        :class="member.state === memberComponentOptions.VIEW ? 'members-list-item' : 'members-edit-form'"
        @edit-member="member.state = memberComponentOptions.EDIT"
        @hide-form="member.state = memberComponentOptions.VIEW"
      />

      <template v-if="!hasModerator">
        <MembersEditForm
          v-if="isShowModeratorForm"
          class="members-edit-form"
          :group="group"
          :role="userRoles.moderator"
          @hide-form="isShowModeratorForm = false"
        />

        <div
          v-else
          class="flex items-center gap-4 py-2 border-b-1 border-solid border-gray-4 cursor-pointer"
          @click="isShowModeratorForm = true"
        >
          <Btn
            color="gray"
            icon="plus"
          />

          <div class="font-medium text-gray-1">
            {{ $t('userRoles.moderator') }}
          </div>
        </div>
      </template>

      <MembersEditForm
        v-if="isShowGuestForm"
        class="members-edit-form"
        :group="group"
        :role="userRoles.guest"
        @hide-form="isShowGuestForm = false"
      />

      <div
        v-else
        class="flex items-center gap-4 py-2 cursor-pointer"
        @click="isShowGuestForm = true"
      >
        <Btn
          color="gray"
          icon="plus"
        />

        <div class="font-medium text-gray-1">
          {{ $t('userRoles.guest') }}
        </div>
      </div>
    </div>
  </Card>
</template>

<script>
import MembersEditForm
from '@/components/project/admin/members/groupsList/members/AdminMembersEditForm';
import MembersListItem
from '@/components/project/admin/members/groupsList/members/AdminMembersListItem';
import Tag from '@/components/ui/buttons/Tag';
import Card from '@/components/ui/common/Card';
import Dropdown from '@/components/ui/common/Dropdown';
import Input from '@/components/ui/inputs/Input';
import userRoles from '@/constatns/user-roles';
import copyToClipBoardMixin from '@/mixins/copyToClipBoardMixin';
import { mapActions, mapState } from 'vuex';

const memberComponentOptions = {
  VIEW: 'MembersListItem',
  EDIT: 'MembersEditForm',
};

export default {
  name: 'AdminGroupsListItem',

  mixins: [copyToClipBoardMixin],

  components: { MembersEditForm, MembersListItem, Input, Tag, Dropdown, Card },

  props: {
    sourceGroup: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      group: null,
      groupListItemActions: [
        {
          name: 'deleteTeam',
          title: this.$t('dropdownMenu.deleteTeam'),
          icon: 'delete-team',
          color: 'error',
        },
        {
          name: 'deleteGroup',
          title: this.$t('dropdownMenu.deleteGroup'),
          icon: 'bin',
          color: 'error',
        },
      ],
      isLoading: {
        deleteTeam: false,
        deleteGroup: false,
        duplicateGroup: false,
      },
      isShowModeratorForm: false,
      isShowGuestForm: false,
      memberComponentOptions,
      userRoles,
    };
  },

  watch: {
    sourceGroup: {
      deep: true,
      handler() {
        this.prepareGroup();
      },
    },
  },

  computed: {
    ...mapState('events', ['selectedEvent']),
    ...mapState('members', ['selectedSet']),

    inviteLink() {
      return `${process.env.VUE_APP_APP_BASE_URL}/event/${this.selectedEvent.id}/join/${this.group.invite_identifier}`;
    },

    hasModerator() {
      return this.group.members.findIndex((m) => m.role === userRoles.moderator) !== -1;
    },
  },

  mounted() {
    this.prepareGroup();
  },

  methods: {
    ...mapActions('members', [
      'updateGroup',
      'deleteGroups',
      'updateMembersOfGroup',
    ]),

    prepareGroup() {
      this.group = JSON.parse(JSON.stringify(this.sourceGroup));
      this.group.members.forEach(
        (member) => this.$set(member, 'state', memberComponentOptions.VIEW),
      );
    },

    async handleGroupMenuItemClick(e) {
      this[e]();
    },

    async deleteTeam() {
      try {
        this.isLoading.deleteMember = true;

        await this.updateMembersOfGroup({
          event_id: this.selectedEvent.id,
          group_id: this.group.id,
          member_ids: [],
        });

        this.$toast(this.$t('notification.success.teamDeleted'));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.deleteMember = false;
      }
    },

    async deleteGroup() {
      try {
        this.isLoading.deleteGroup = true;

        await this.deleteGroups({
          event_id: this.selectedEvent.id,
          group_set_id: this.selectedSet.id,
          group_ids: this.group.id,
        });

        this.$toast(this.$t(
          'notification.success.groupDeleted',
          { groupName: this.group.name },
        ));
        this.$emit('reload-groups');
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.deleteGroup = false;
      }
    },

    async onGroupNameChange() {
      if (this.group.name !== this.sourceGroup.name) {
        if (this.group.name === '') {
          this.$toast.error(this.$t('notification.error.canNotBeEmpty'));
          return;
        }

        try {
          this.isLoading.updateGroup = true;

          await this.updateGroup({
            event_id: this.selectedEvent.id,
            group_id: this.group.id,
            name: this.group.name,
          });

          this.$toast(this.$t('notification.success.successfullyUpdated'));
        } catch (e) {
          console.log(e);
        } finally {
          this.isLoading.updateGroup = false;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .dot-divider {
    width: 3px;
    height: 3px;
    border-radius: 100%;
    @apply bg-gray-1
  }

  .members-list {
    margin-top: 12px;
    display: flex;
    flex-direction: column;
  }

  .members-list-header {
    font-size: 12px;
    line-height: 140%;
    @apply text-gray-1;
    height: 32px;
    align-items: center !important;
  }

  .members-list-item {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 70px;
    align-items: center;
    gap: 32px;
    padding: 8px 0;
    @apply border-b-1 border-solid border-gray-4;

    &:first-of-type {
      @apply border-none
    }
  }

  .members-edit-form {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 36px 36px;
    align-items: center;
    gap: 10px;
    padding: 8px 0;
    @apply border-b-1 border-solid border-gray-4;

    &:last-of-type {
      @apply border-none
    }
  }
</style>
