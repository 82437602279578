import http from '@/api/axios';
import ApiRoutes from '@/api/routes';

const {
  Register,
  Login,
  LoginWithInviteCode,
  RequestPasswordReset,
  ResetPassword,
  Me,
} = ApiRoutes.Auth;

export const register = ({ event_id, invite_code, ...data }) => http.post(Register, data, { params: { event_id, invite_code } });

export const login = (data) => http.post(Login, data, { headers: { 'content-type': 'application/x-www-form-urlencoded' } });

export const loginWithInviteCode = ({ token, user }) => http.post(LoginWithInviteCode(token), null, { params: { user } });

export const requestPasswordReset = (email) => http.post(RequestPasswordReset, { email });

export const resetPassword = (data) => http.post(ResetPassword, data);

export const me = (data) => http.get(Me, { params: data });
