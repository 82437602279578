<template>
  <div
    :class="computedClasses"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <div class="tag__content">
      <SvgIcon
        v-if="prependIcon"
        :name="prependIcon"
        :size="size"
      />
      <SvgIcon
        v-if="icon"
        :name="icon"
        :size="size"
        class="tag__icon"
      />
      <slot v-else />
      <SvgIcon
        v-if="appendIcon"
        :name="appendIcon"
        :size="size"
      />
    </div>
  </div>
</template>

<script>
const colors = ['primary', 'secondary', 'success', 'error', 'light', 'green', 'transparent'];
const sizes = ['xs', 'sm', 'md'];

export default {
  name: 'Tag',

  props: {
    color: {
      type: String,
      default: 'primary',
      validator: (v) => colors.includes(v),
    },
    size: {
      type: String,
      default: 'xs',
      validator: (v) => sizes.includes(v),
    },
    to: [String, Object],
    href: String,
    target: {
      type: String,
      validator: (v) => ['_self', '_blank', '_top'].includes(v),
    },
    download: [String, Boolean],
    interactive: Boolean,
    icon: String,
    prependIcon: String,
    appendIcon: String,
  },

  data() {
    return {
      hovered: false,
    };
  },

  computed: {
    computedClasses() {
      return [
        'tag',
        `tag_${this.size}`,
        `tag_${this.color}`,
        {
          tag_hovered: this.hovered,
          tag_icon: this.icon,
          tag_interactive: this.interactive,
        },
      ];
    },
  },

  methods: {
    onMouseEnter() {
      this.hovered = true;
    },
    onMouseLeave() {
      this.hovered = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .tag {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    flex-shrink: 0;
    min-width: fit-content;
    width: fit-content;
    @apply transition transition-all duration-300;

    &__content {
      display: flex;
      align-items: center;
      flex-shrink: 0;
      gap: 6px;
    }

    &_xs {
      padding: 2px 6px;
      height: 24px;
      border-radius: 4px;
      font-size: 12px;
    }

    &_sm {
      padding: 4px 8px;
      height: 28px;
      border-radius: 4px;
      font-size: 14px;
    }

    &_md {
      padding: 6px 8px;
      height: 32px;
      border-radius: 100px;
    }

    &_primary {
      @apply bg-primary-dark;
    }

    &_secondary {
      @apply bg-gray-4;
    }

    &_green {
      @apply bg-secondary-green;
    }

    &_light {
      @apply bg-light text-gray-0;
    }

    &_success {
      @apply bg-secondary-green-light text-secondary-green;
    }

    &_error {
      @apply bg-secondary-red-light text-secondary-red;
    }

    &_transparent {
      background-color: transparent;
    }

    &_interactive {
      &.tag_hovered {
        @apply bg-gray-5;
        border-radius: 4px;
        cursor: pointer;
      }
    }

    &_icon {
      width: 24px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 0;
      gap: 0;
    }
  }
</style>
