import http from '@/api/axios';
import ApiRoutes from '@/api/routes';

const {
  CreateClient,
  UpdateClientName,
} = ApiRoutes.Clients;

export const createClient = (data) => http.post(CreateClient, data);

export const updateClientName = ({ client_id, name }) => http.patch(UpdateClientName(client_id), null, { params: { name } });
