<template>
  <Loader
    v-if="isLoading.tacts"
    class="!my-80"
  />

  <div
    v-else-if="tact"
    class="flex flex-col gap-2"
  >
    <div class="flex flex-col items-start gap-2 mx-3">
      <Btn
        link
        prepend-icon="arrow-left"
        color="gray"
        class="my-2"
        @click="$router.push({ name: 'tasks' })"
      >
        {{ $t('membersPage.tasksByTactId.back') }}
      </Btn>

      <h2>{{ tact.name }}</h2>

      <div class="text-gray-0">
        {{ $t('membersPage.tasksByTactId.headerHint') }}
      </div>
    </div>

    <div class="bg-white w-full py-3 mt-3">
      <template v-if="tact.need_photo">
        <div class="flex justify-between items-center gap-2 mb-2 mx-3">
          <div class="flex gap-2">
            <SvgIcon
              name="photo"
              size="md"
              class="text-gray-1"
            />

            <span class="font-medium text-base flex-grow">
              {{ $t('membersPage.tasksByTactId.photo') }}
            </span>
          </div>

          <FileUploadInput
            v-if="isMe"
            ref="photo"
            @input="uploadPhotos"
          >
            <Btn
              color="secondary"
              size="sm"
              prepend-icon="plus"
              :loading="isLoading.photoUploading"
              @click="$refs.photo.$refs.file.click()"
            >
              {{ $t('membersPage.tasksByTactId.add') }}
            </Btn>
          </FileUploadInput>
        </div>

        <div
          v-if="tact.member_photos.length"
          class="flex flex-wrap gap-5 my-4 mx-4"
        >
          <div
            v-for="(photo, key) in tact.member_photos.slice(0,4)"
            :key="photo"
            class="bg-cover bg-center w-5/12 aspect-square rounded-lg"
            :style="{'background-image': `url(${photo})`}"
            @click="showGallery(key)"
          />

          <Btn
            v-if="tact.member_photos.length > 4"
            link
            color="gray"
            @click="showGallery(4)"
          >
            {{
              $t(
                'membersPage.tasksByTactId.hiddenPhotos',
                { count: tact.member_photos.length - 4 },
              )
            }}...
          </Btn>
        </div>

        <div
          v-else
          class="mb-4 ml-11 text-gray-2"
        >
          {{ $t('membersPage.tasksByTactId.photoNotUploaded') }}
        </div>
      </template>

      <template v-if="tact.questionnaire.is_mandatory">
        <div class="flex gap-2 mb-4 mx-3">
          <SvgIcon
            name="form"
            size="md"
            class="text-gray-1"
          />

          <span class="font-medium text-base flex-grow">
            {{ $t('membersPage.tasksByTactId.questionnaire') }}
          </span>
        </div>

        <template v-if="tact.questionnaire.response.length">
          <div
            v-for="question in tact.questionnaire.questions"
            :key="question.id"
            class="mb-4 mx-4"
          >
            <div class="flex gap-3">
              <div class="font-monospace text-gray-0">{{ question.order }}</div>
              <div class="font-medium">{{ question.text }}</div>
            </div>

            <div
              v-if="question.question_type === questionTypes.text"
              class="mt-2 ml-5 break-words"
            >
              {{ getTextReply(question) || '' }}
            </div>

            <template v-else>
              <div
                v-for="answerId in question.selectedOptions"
                :key="answerId"
                class="flex items-center gap-3 mt-2 ml-5"
              >
                <SvgIcon
                  name="tick"
                  class="text-gray-1"
                />

                {{ getSelectedOptionText(question.id, answerId) || '' }}
              </div>
            </template>
          </div>
        </template>

        <div
          v-else
          class="ml-11"
        >
          <Btn
            v-if="isMe"
            prepend-icon="edit"
            @click="isShowQuestionnaire = true"
          >
            {{ $t('membersPage.tasksByTactId.fillQuestionnaireBtn') }}
          </Btn>

          <span
            v-else
            class="text-gray-2"
          >
            {{ $t('membersPage.tasksByTactId.questionnaireNotFilled') }}
          </span>
        </div>
      </template>

      <QuestionnaireRespond
        v-if="isShowQuestionnaire"
        :questions="tact.questionnaire.questions"
        @close="isShowQuestionnaire = false"
        @success-form-submit="onSuccessFormFilling"
      />
    </div>
  </div>

  <h2
    v-else
    class="flex justify-center items-center mt-32 mx-10 text-center"
  >
    {{ $t('pageNotFound.text') }}
  </h2>
</template>

<script>
import QuestionnaireRespond from '@/components/project/mobile/currentTact/QuestionnaireRespond';
import Loader from '@/components/ui/common/Loader';
import FileUploadInput from '@/components/ui/inputs/FileUploadInput';
import questionTypes from '@/constatns/question-types';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'TasksByTactId',
  components: { QuestionnaireRespond, FileUploadInput, Loader },
  props: {
    sourceTact: {
      type: Object,
      required: false,
    },
  },

  data() {
    return {
      tact: null,
      isLoading: {
        tacts: false,
        photoUploading: false,
      },
      isShowQuestionnaire: false,
      questionTypes,
    };
  },

  computed: {
    ...mapState('auth', ['me']),
    ...mapState('events', ['selectedEvent']),

    isMe() {
      return +this.$route.params.memberId === +this.me.member_id;
    },
  },

  async mounted() {
    if (!this.sourceTact) {
      await this.fetchData();
    } else {
      this.tact = JSON.parse(JSON.stringify(this.sourceTact));
    }

    const answers = this.tact.questionnaire.response;
    const questions = this.tact.questionnaire.questions;

    if (answers.length) {
      for (const a of answers) {
        if (a.options.length) {
          const correspondingQuestion = questions.find((q) => +q.id === +a.question_id);

          this.$set(correspondingQuestion, 'selectedOptions', a.options);
        }
      }
    }
  },

  methods: {
    ...mapActions('members', [
      'getCompletedTasks',
    ]),
    ...mapActions('tacts', [
      'uploadMemberPhotos',
    ]),

    async fetchData() {
      try {
        this.isLoading.tacts = true;

        const tactsWithTasks = await this.getCompletedTasks({
          event_id: this.selectedEvent.id,
          member_id: this.$route.params.memberId,
        });

        this.tact = tactsWithTasks.find(
          (tact) => +tact.id === +this.$route.params.tactId,
        );
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.tacts = false;
      }
    },

    async uploadPhotos(files) {
      try {
        this.isLoading.photoUploading = true;

        const formData = new FormData();

        for (let i = 0; i < files.length; i++) {
          let file = files.item(i);
          formData.append('uploaded_files', file);
        }

        this.tact.member_photos = await this.uploadMemberPhotos({
          event_id: this.selectedEvent.id,
          tact_id: this.$route.params.tactId,
          formData,
        });

        this.$toast(this.$t('notification.success.photoUploaded'));
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.photoUploading = false;
      }
    },

    showGallery(selectedImageIndex) {
      this.$modal.show('image-gallery', {
        header: this.tact.name,
        images: this.tact.member_photos,
        selectedImageIndex,
      });
    },

    getTextReply(question) {
      return this.tact.questionnaire.response
        .find((r) => r.question_id === question.id)
        .answer_text;
    },

    getSelectedOptionText(questionId, answerId) {
      return this.tact.questionnaire.questions
        .find((q) => q.id === questionId)
        .options
        .find((o) => o.id === answerId)
        .value;
    },

    onSuccessFormFilling() {
      this.isShowQuestionnaire = false;
      this.fetchData();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
