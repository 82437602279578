<template>
  <div class="bg-primary-light h-screen flex flex-col justify-center items-center">
    <h2>
      {{ $t('resetPassword.heading') }}
    </h2>

    <Card class="mt-4 py-8 px-6 max-w-md w-11/12 md:w-7/12 lg:w-1/3 relative">
      <div class="absolute w-full -top-60 left-0 flex justify-center">
        <SvgIcon
          name="logo"
          self-size
        />
      </div>

      <form
        class="flex flex-col gap-6"
        @submit.prevent="onSubmit"
      >
        <Input
          v-if="step === 2"
          v-model="form.reset_code"
          required
          :placeholder="$t('resetPassword.resetCode')"
        />
        <Input
          v-model="form.email"
          type="email"
          required
          :placeholder="$t('resetPassword.email')"
        />
        <Input
          v-if="step === 2"
          v-model="form.password"
          type="password"
          required
          :placeholder="$t('resetPassword.password')"
        />
        <Input
          v-if="step === 2"
          v-model="form.password_confirm"
          type="password"
          required
          :placeholder="$t('resetPassword.passwordConfirm')"
        />

        <Btn
          type="submit"
          :loading="isLoading"
        >
          {{ step === 1 ? $t('resetPassword.sendCode') : $t('resetPassword.updatePassword') }}
        </Btn>

        <div
          v-if="step === 2"
          class="inline-flex items-center gap-2 text-gray-0 hover:text-dark cursor-pointer"
          @click="step = 1"
        >
          <SvgIcon name="arrow-left" />
          {{ $t('resetPassword.backToFirstStep') }}
        </div>
      </form>

      <div class="mt-6">
        <RouterLink
          :to="{name: 'login'}"
          class="text-secondary-blue hover:text-gray-0 hover:underline"
        >
          {{ $t('resetPassword.backToLogin') }}
        </RouterLink>
      </div>
    </Card>

    <img
      src="@/assets/images/x.png"
      class="absolute bottom-0 left-0 invisible lg:visible"
      alt="logo"
    >
  </div>
</template>

<script>
import Card from '@/components/ui/common/Card';
import Input from '@/components/ui/inputs/Input';
import { mapActions } from 'vuex';

export default {
  name: 'ResetPassword',
  components: { Input, Card },
  data() {
    return {
      form: {
        reset_code: '',
        email: '',
        password: '',
        password_confirm: '',
      },
      step: 1,
      error: '',
      isLoading: false,
    };
  },
  methods: {
    ...mapActions('auth', [
      'requestPasswordReset',
      'resetPassword',
    ]),

    async onSubmit() {
      try {
        this.isLoading = true;

        if (this.step === 1) {
          await this.requestPasswordReset(this.form.email);
          this.step = 2;
          return;
        }

        if (this.step === 2) {
          if (this.form.password !== this.form.password_confirm) {
            this.$toast.error(this.$t('notification.error.passwordsNotEqual'));
            return;
          }

          await this.resetPassword(this.form);
          this.step = 1;
          this.$toast(this.$t('notification.success.passwordHasBeenReset'));
          this.$router.push({ name: 'login', params: { email: this.form.email } });
        }
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
