<template>
  <div>
    <h2 class="mx-3">
      {{ myGroup.name }}
    </h2>

    <div class="flex items-center gap-1 mt-3 mx-3 mb-10">
      <SvgIcon name="team" />

      <span class="text-gray-0">
        {{ myGroup.members.length }} {{ $t('membersPage.myGroup.peopleCount') }}
      </span>
    </div>

    <div class="bg-white">
      <MyGroupMemberListItem
        v-for="member in myGroup.members"
        :key="member.id"
        :member="member"
      />
    </div>

    <!--    <div class="bg-white px-3 my-6">-->
    <!--      <router-link-->
    <!--        class="flex gap-2 py-6 cursor-pointer"-->
    <!--        :to="{ name: 'allMembersTasks' }"-->
    <!--      >-->
    <!--        <SvgIcon-->
    <!--          name="lightning"-->
    <!--          size="md"-->
    <!--          class="text-gray-1"-->
    <!--        />-->

    <!--        <span class="font-medium text-base flex-grow">-->
    <!--          {{ $t('membersPage.myGroup.allMembersTasks') }}-->
    <!--        </span>-->

    <!--        <SvgIcon-->
    <!--          size="md"-->
    <!--          name="chevron-right"-->
    <!--        />-->
    <!--      </router-link>-->
    <!--    </div>-->
  </div>
</template>

<script>
import MyGroupMemberListItem from '@/components/project/mobile/members/MyGroupMemberListItem';

export default {
  name: 'MyGroup',

  components: { MyGroupMemberListItem },

  props: {
    myGroup: {
      type: Object,
      required: true,
    },
  },
};
</script>
