import * as Types from '@/store/members/mutation-types';
import Vue from 'vue';

export default {
  [Types.SET_GROUPS](state, payload) {
    payload.forEach((group, key) => {
      Vue.set(group, 'key', key + 1);
    });

    state.groups = payload;
  },

  [Types.SET_SELECTED_SET](state, payload) {
    state.selectedSet = payload;
  },

  [Types.ADD_MEMBER](state, payload) {
    const i = state.groups.findIndex((g) => g.id === payload.groupId);

    if (i !== null && typeof i !== 'undefined' && i !== -1) {
      const j = state.groups[i].members.findIndex((m) => m.id === payload.member.id);

      if (j !== null && typeof j !== 'undefined' && j !== -1) {
        state.groups[i].members.splice(j, 1, payload.member);
      } else {
        state.groups[i].members.push(payload.member);
      }
    }
  },

  [Types.UPDATE_GROUP](state, payload) {
    const i = state.groups.findIndex((g) => g.id === payload.id);

    if (i !== null && typeof i !== 'undefined' && i !== -1) {
      Vue.set(payload, 'key', state.groups[i].key);

      state.groups.splice(i, 1, payload);
    }
  },
};
