import {
  createEvent,
  deleteEvent,
  getAllEvents,
  getEvent,
  updateEvent,
} from '@/api/modules/events';
import * as Types from '@/store/events/mutation-types';

export default {
  async getEvents({ commit }, payload) {
    const { data: { items } } = await getAllEvents(payload);
    commit(Types.SET_EVENTS, items);
  },

  async getAndReturnEvents({ commit }, payload) {
    const { data: { items } } = await getAllEvents(payload);
    return items;
  },

  async getEvent({ commit }, payload) {
    const { data } = await getEvent(payload);
    commit(Types.SET_SELECTED_EVENT, data);
  },

  clearEvent({ commit }) {
    commit(Types.SET_SELECTED_EVENT, null);
  },

  async createEvent({ commit }, payload) {
    const { data } = await createEvent(payload);
    return data;
  },

  async updateEvent({ commit }, payload) {
    await updateEvent(payload);
  },

  async deleteEvent({ commit }, payload) {
    await deleteEvent(payload);
    commit(Types.REMOVE_EVENT_FROM_LIST, payload);
  },
};
