import * as Types from '@/store/auth/mutation-types';

export default {
  [Types.SET_ME](state, payload) {
    state.me = payload;
  },

  [Types.SET_IS_ADMIN](state, payload) {
    state.isAdmin = payload;
  },
};
