<template>
  <form @submit.prevent="onSubmit">
    <div class="flex gap-2 items-center">
      <Avatar />

      <Input
        v-model="member.name"
        class="flex-grow"
        gray-bg
        :placeholder="$t('adminEventsView.participantsTab.groupListItem.membersEditForm.name')"
      />
    </div>

    <Input
      v-model="member.email"
      class="flex-grow"
      type="email"
      gray-bg
      :placeholder="$t('adminEventsView.participantsTab.groupListItem.membersEditForm.email')"
    />

    <Tag
      v-if="role === userRoles.moderator"
      size="sm"
    >
      {{ $t('userRoles.moderator') }}
    </Tag>

    <Input
      v-else
      v-model="member.position"
      class="flex-grow"
      gray-bg
      :placeholder="$t('adminEventsView.participantsTab.groupListItem.membersEditForm.position')"
    />

    <div class="flex gap-1">
      <Btn
        icon="tick"
        :loading="isLoading"
        type="submit"
      />

      <Btn
        color="secondary"
        icon="x"
        @click="$emit('hide-form')"
      />
    </div>
  </form>
</template>

<script>
import Tag from '@/components/ui/buttons/Tag';
import Avatar from '@/components/ui/common/Avatar';
import Input from '@/components/ui/inputs/Input';
import userRoles from '@/constatns/user-roles';
import { mapActions, mapState } from 'vuex';

export default {
  name: 'AdminMembersEditForm',

  components: { Tag, Avatar, Input },

  props: {
    sourceMember: {
      type: Object,
      required: false,
    },
    group: {
      type: Object,
      required: true,
    },
    role: {
      type: String,
      required: false,
    },
  },

  data() {
    return {
      member: {
        name: '',
        email: '',
        position: '',
        role: '',
      },
      isLoading: false,
      userRoles,
    };
  },

  computed: {
    ...mapState('events', ['selectedEvent']),
  },

  mounted() {
    if (this.sourceMember) {
      this.member.name = this.sourceMember.name;
      this.member.email = this.sourceMember.email;
      this.member.position = this.sourceMember.position;
      this.member.photo = this.sourceMember.photo;
      this.member.role = this.sourceMember.role;
    } else if (this.role) {
      this.member.role = this.role;
    } else {
      this.member.role = userRoles.guest;
    }
  },

  methods: {
    ...mapActions('members', [
      'addMember',
    ]),

    async onSubmit() {
      try {
        this.isLoading = true;

        await this.addMember({
          event_id: this.selectedEvent.id,
          group_id: this.group.id,
          ...this.member,
        });

        if (this.sourceMember) {
          this.$toast(this.$t(
            'notification.success.memberUpdated',
            { memberName: this.sourceMember.name },
          ));
        } else {
          this.$toast(this.$t(
            'notification.success.memberAdded',
            { memberName: this.member.name },
          ));
        }
        this.$emit('hide-form');
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
