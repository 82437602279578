]<template>
  <label
    :class="computedClasses"
    class="radio"
  >
    <span class="radio__in">
      <input
        class="radio__input hidden"
        type="radio"
        :value="value"
        :name="name"
        :checked="shouldBeChecked"
        :disabled="disabled"
        @change="updateInput"
      >
      <span class="radio__pseudo" />
      <span class="radio__text">
        <slot />
      </span>
    </span>
  </label>
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    modelValue: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    computedClasses() {
      return [
        {
          radio_disabled: this.disabled,
        },
      ];
    },

    shouldBeChecked() {
      return this.modelValue === this.value;
    },
  },

  methods: {
    updateInput() {
      this.$emit('change', this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
  .radio {
    display: block;
    line-height: 18px;
    white-space: nowrap;
    text-align: left;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    &__in {
      position: relative;
      display: flex;
      align-items: center;
      vertical-align: top;
      @apply transition transition-all duration-300;
    }

    &__input {
      &:checked {
        ~ .radio__pseudo {
          @apply border-secondary-blue bg-secondary-blue;

          &:after {
            opacity: 1;
          }
        }
      }
    }

    &__pseudo {
      position: relative;
      display: inline-block;
      box-sizing: border-box;
      min-width: 18px;
      width: 22px;
      height: 22px;
      vertical-align: top;
      background-color: transparent;
      @apply border-2 border-solid border-gray-2;
      border-radius: 100px;
      backface-visibility: hidden;
      transition: background-color ease .1s, border-color ease .1s;

      &:hover {
        @apply border-secondary-blue;
      }

      &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 18px;
        height: 18px;
        @apply border-[3px] border-white rounded-full bg-secondary-blue;
        opacity: 0;
        transition: opacity linear .1s;
        content: "";
      }
    }

    &__text {
      display: inline-block;
      flex-shrink: 1;
      padding-left: 6px;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      white-space: normal;
      vertical-align: middle;
      user-select: none;
      @apply text-gray-0;
    }

    &_disabled {
      cursor: auto;

      input {
        cursor: auto;
      }

      .radio__text {
        @apply text-gray-1;
      }

      .radio__pseudo {
        @apply border-gray-3;
      }
    }
  }
</style>
