import {
  activateInviteCode,
  addMember,
  changeMemberRole,
  createGroup,
  createInviteCode,
  createSetGroup,
  deleteGroups,
  deleteSetGroup,
  getCompletedTasks,
  getCurrentGroup,
  getGroupsOfEvent,
  getMembersOfEvent,
  joinGroupViaCodeIdentifier,
  updateGroup,
  updateMembersOfGroup,
  updateSelfInfo,
} from '@/api/modules/members';
import * as Types from '@/store/members/mutation-types';
import { setCookie } from '@/utils/cookies';

export default {
  async createInviteCode({ commit }, payload) {
    await createInviteCode(payload);
  },

  async activateInviteCode({ commit }, payload) {
    await activateInviteCode(payload);
  },

  async addMember({ commit }, payload) {
    const { data } = await addMember(payload);
    commit(Types.ADD_MEMBER, { member: data, groupId: payload.group_id });
  },

  async changeMemberRole({ commit }, payload) {
    await changeMemberRole(payload);
  },

  async getMembersOfEvent({ commit }, payload) {
    await getMembersOfEvent(payload);
  },

  async getGroupsOfEvent({ commit }, payload) {
    const { data } = await getGroupsOfEvent(payload);
    commit(Types.SET_GROUPS, data);
  },

  async createGroup({ commit }, payload) {
    await createGroup(payload);
  },

  async updateGroup({ commit }, payload) {
    const { data } = await updateGroup(payload);
    commit(Types.UPDATE_GROUP, data);
  },

  async deleteGroups({ commit }, payload) {
    await deleteGroups(payload);
  },

  async getCurrentGroup({ commit }, payload) {
    const { data } = await getCurrentGroup(payload);
    return data;
  },

  async updateMembersOfGroup({ commit }, payload) {
    const { data } = await updateMembersOfGroup(payload);
    commit(Types.UPDATE_GROUP, data);
  },

  async updateSelfInfo({ commit }, payload) {
    await updateSelfInfo(payload);
  },

  async getCompletedTasks({ commit }, payload) {
    const { data } = await getCompletedTasks(payload);
    return data;
  },

  async createSetGroup({ commit }, payload) {
    const { data } = await createSetGroup(payload);
    commit(Types.SET_SELECTED_SET, data);

    return data;
  },

  async deleteSetGroup({ commit }, payload) {
    await deleteSetGroup(payload);
  },

  async joinGroup({ commit }, payload) {
    setCookie('join_group_data', JSON.stringify(payload), { path: '/', 'max-age': 3600 });

    await joinGroupViaCodeIdentifier(payload);
  },
};
