<template>
  <Card>
    <div
      :title="event.name"
      class="font-medium break-words max-h-28 overflow-hidden"
    >
      {{ cutField(event.name) }}
    </div>

    <div
      :title="event.client.name"
      class="break-words max-h-28 overflow-hidden"
    >
      {{ cutField(event.client.name) }}
    </div>

    <div class="text-sm">
      <div>
        {{ eventDates }}
      </div>
      <div
        :title="event.place"
        class="text-gray-0 break-words max-h-20 overflow-hidden"
      >
        {{ cutField(event.place) }}
      </div>
    </div>

    <Tag
      :color="eventStatus.color"
      :prepend-icon="eventStatus.icon"
    >
      {{ eventStatus.title }}
    </Tag>

    <Dropdown
      :options="eventListItemActions"
      @click="handleEventListItemMenuClick"
    >
      <template #activator>
        <Btn
          class="mt-1"
          color="gray"
          link
          prepend-icon="overflow"
          @click.stop
        />
      </template>
    </Dropdown>
  </Card>
</template>

<script>
import Tag from '@/components/ui/buttons/Tag';
import Card from '@/components/ui/common/Card';
import Dropdown from '@/components/ui/common/Dropdown';
import eventStatuses from '@/constatns/event-statuses';
import moment from 'moment/moment';

export default {
  name: 'AdminEventsListItem',

  components: { Dropdown, Tag, Card },

  props: {
    event: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      statuses: [
        {
          name: eventStatuses.draft,
          title: this.$t(`adminEventsList.statuses.${eventStatuses.draft}`),
          icon: 'pen',
          color: 'secondary',
        },
        {
          name: eventStatuses.future,
          title: this.$t(`adminEventsList.statuses.${eventStatuses.future}`),
          icon: 'play',
          color: 'primary',
        },
        {
          name: eventStatuses.in_progress,
          title: this.$t(`adminEventsList.statuses.${eventStatuses.in_progress}`),
          icon: 'play',
          color: 'primary',
        },
        {
          name: eventStatuses.completed,
          title: this.$t(`adminEventsList.statuses.${eventStatuses.completed}`),
          icon: 'tick',
          color: 'green',
        },
      ],
      eventListItemActions: [
        // {
        //   name: 'duplicateEvent',
        //   title: this.$t('dropdownMenu.duplicate'),
        //   icon: 'duplicate',
        // },
        {
          name: 'deleteEvent',
          title: this.$t('dropdownMenu.delete'),
          icon: 'bin',
          color: 'error',
        },
      ],
      isLoading: {
        deleteEvent: false,
        duplicateEvent: false,
      },
    };
  },

  computed: {
    eventDates() {
      const startDay = moment(this.event.event_start_datetime).date();
      const startMonth = moment(this.event.event_start_datetime).format('MMMM');

      const endDay = moment(this.event.event_end_datetime).date();
      const endMonth = moment(this.event.event_end_datetime).format('MMMM');

      if (startMonth !== endMonth) {
        return `${startDay} ${startMonth} – ${endDay} ${endMonth} `;
      }
      if (startDay !== endDay) {
        return `${startDay}-${endDay} ${startMonth}`;
      }
      return `${startDay} ${startMonth}`;
    },
    eventStatus() {
      return this.statuses.find((s) => s.name === this.event.status);
    },
  },

  methods: {
    cutField(field) {
      return field.length < 90 ? field : `${field.slice(0, 90)}...`;
    },

    async handleEventListItemMenuClick(e) {
      this[e]();
    },

    async deleteEvent() {
      this.$emit('delete-event', this.event);
    },

    async duplicateEvent() {
      try {
        this.isLoading.duplicateEvent = true;

        console.log('duplicate');
      } catch (e) {
        // console.log(e);
      } finally {
        this.isLoading.duplicateEvent = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .events-list-item {
    padding-top: 16px;
    padding-bottom: 20px;
    cursor: pointer;
    @apply transition duration-300;

    &:hover {
      box-shadow: 0 5px 10px rgba(31, 37, 74, 0.08);
      transform: translateY(-3px);
    }
  }
</style>
