<template>
  <div class="flex flex-col justify-center items-center gap-8 h-full">
    <h1>{{ $t('accessDenied.header') }}</h1>

    <div class="flex flex-col items-center gap-2">
      <Btn
        link
        color="gray"
        :href="switchAppLink"
      >
        {{ $t('accessDenied.goTo') }} {{ switchAppLink }}
      </Btn>

      {{ $t('accessDenied.or') }}

      <Btn
        link
        color="gray"
        @click="logOut"
      >
        {{ $t('logout.header') }}
      </Btn>

      {{ $t('accessDenied.toLogin') }}.
    </div>
  </div>
</template>

<script>
/* eslint-disable no-restricted-globals */
import { mapActions } from 'vuex';

export default {
  name: 'AccessDenied',

  computed: {
    switchAppLink() {
      if (location.origin === process.env.VUE_APP_ADMIN_BASE_URL) {
        return process.env.VUE_APP_APP_BASE_URL;
      }
      if (location.origin === process.env.VUE_APP_APP_BASE_URL) {
        return process.env.VUE_APP_ADMIN_BASE_URL;
      }
      return process.env.VUE_APP_LANDING_URL;
    },
  },

  methods: {
    ...mapActions('auth', ['logout']),

    logOut() {
      this.logout();
      this.$router.push({ name: 'login' });
    },
  },
};
</script>
