import ApiRoutes from '@/api/routes';
import responseCodes from '@/constatns/response-codes';
import i18n from '@/plugins/i18n';
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
} from '@/utils/system';
import axios from 'axios';
import Vue from 'vue';

function isNetworkError(err) {
  return !!err.isAxiosError && !err.response;
}

const baseURL = `${process.env.VUE_APP_API_BASE_URL}/v${process.env.VUE_APP_API_VERSION}`;

const http = axios.create({ baseURL });

http.interceptors.request.use(
  (config) => {
    const token = getAccessToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    config.headers['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;

    return config;
  },

  (error) => Promise.reject(error),
);

http.interceptors.response.use(
  (response) => response,

  async (error) => {
    if (isNetworkError(error)) {
      Vue.$toast.error(i18n.messages[i18n.locale].notification.error.networkError);
      return Promise.reject(error);
    }

    if (error.response) {
      if (
        error.response.status === responseCodes.unauthorized
        && error.config
        && !error.config._isRetry
      ) {
        removeAccessToken();
        const refreshToken = getRefreshToken();
        const originalRequest = error.config;

        try {
          originalRequest._isRetry = true;

          const response = await axios.post(
            `${baseURL}${ApiRoutes.Auth.RefreshToken}`,
            null,
            { headers: { Authorization: `Bearer ${refreshToken}` } },
          );
          setAccessToken(response.data.access_token);

          return http.request(originalRequest);
        } catch {
          removeAccessToken();
          removeRefreshToken();
          // eslint-disable-next-line no-restricted-globals
          location.assign(`${location.origin}/login`);

          return;
        }
      }

      const err = error.response?.data;
      const errorMessage = err?.detail ? err.detail : (err.errorMessage || err.message);
      if (errorMessage && typeof errorMessage === 'string') {
        Vue.$toast.error(errorMessage);
      }
    }

    // eslint-disable-next-line consistent-return
    return Promise.reject(error);
  },
);

export default http;
