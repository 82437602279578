<template>
  <div class="admin-header flex justify-between items-center h-10">
    <div class="flex items-center gap-2">
      <SvgIcon name="logo-x" />
      {{ $t('adminHeader.heading') }}
    </div>
    <Btn
      :title="$t('adminHeader.logoutBtn')"
      color="gray"
      link
      icon="logout"
      @click="onLogout"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'AdminHeader',

  methods: {
    ...mapActions('auth', ['logout']),

    onLogout() {
      this.logout();
      this.$router.push({ name: 'login' });
    },
  },
};
</script>

<style lang="scss" scoped>
  .admin-header {
    @apply text-gray-0;
    font-weight: 500;
    font-size: 11px;
    line-height: 100%;
    /* identical to box height, or 11px */

    letter-spacing: 0.03em;
    text-transform: uppercase;
  }
</style>
