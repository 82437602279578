import VCalendar from 'v-calendar';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import Vue from 'vue';

Vue.use(VCalendar, {
  masks: {
    input: ['YYYY-MM-DD'],
  },
});

Vue.component('v-date-picker', DatePicker);
