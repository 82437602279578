export default {
  data() {
    return {
      isCopied: '',
    };
  },

  mounted() {
    this.isCopied = this.$t('copyToClipBoard.copy');
  },

  methods: {
    copyToClipboard(data) {
      if (typeof data === 'object' || Array.isArray(data)) {
        return;
      }

      const clipboardData = window.clipboardData || navigator.clipboard;

      clipboardData.writeText(data);

      this.isCopied = this.$t('copyToClipBoard.copied');

      setTimeout(() => {
        this.isCopied = this.$t('copyToClipBoard.copy');
      }, 2000);
    },
  },
};
