<template>
  <loader v-if="isLoading" />

  <div v-else-if="length">
    <slot />
  </div>

  <empty-list
    v-else
    :text="emptyListText"
  />
</template>

<script>
import EmptyList from '@/components/ui/common/EmptyList';
import Loader from '@/components/ui/common/Loader';

export default {
  name: 'ListWithLoader',

  components: {
    EmptyList,
    Loader,
  },

  props: {
    length: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    emptyListText: {
      type: String,
      required: false,
    },
  },
};
</script>
