/* eslint-disable no-restricted-globals */
import i18n from '@/plugins/i18n';
import store from '@/store';
import { getAccessToken as hasToken } from '@/utils/system';
import AccessDenied from '@/views/AccessDenied';
import AdminEventsList from '@/views/admin/AdminEventsList';
import AdminEventsView from '@/views/admin/AdminEventsView';
import Login from '@/views/auth/Login';
import Registration from '@/views/auth/Registration';
import ResetPassword from '@/views/auth/ResetPassword';
import JoinGroupByInviteLink from '@/views/JoinGroupByInviteLink';
import LoginWithInviteCode from '@/views/LoginWithInviteCode';
import EventList from '@/views/mobile/EventList';
import EventView from '@/views/mobile/EventView';
import CurrentTactPage from '@/views/mobile/eventView/CurrentTactPage';
import MembersPage from '@/views/mobile/eventView/MembersPage';
import AllMembersTasks from '@/views/mobile/eventView/membersPage/AllMembersTasks';
import MyGroup from '@/views/mobile/eventView/membersPage/MyGroup';
import Tasks from '@/views/mobile/eventView/membersPage/Tasks';
import TasksByTactId from '@/views/mobile/eventView/membersPage/TasksByTactId';
import ProgramPage from '@/views/mobile/eventView/ProgramPage';
import TactDetails from '@/views/mobile/eventView/TactDetails';
import PageNotFound from '@/views/PageNotFound';
import VerifyEmail from '@/views/VerifyEmail';
import moment from 'moment';
import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: location.origin === process.env.VUE_APP_ADMIN_BASE_URL
        ? { name: 'adminEventsList' }
        : { name: 'eventView' },
    },

    // auth
    {
      path: '/login',
      name: 'login',
      component: Login,
      meta: {
        layout: 'Blank',
        title: i18n.messages[i18n.locale].login.title,
      },
    },
    {
      path: '/registration',
      name: 'registration',
      component: Registration,
      props: true,
      meta: {
        layout: 'Blank',
        title: i18n.messages[i18n.locale].registration.title,
      },
    },
    {
      path: '/reset-password',
      name: 'resetPassword',
      component: ResetPassword,
      meta: {
        layout: 'Blank',
        title: i18n.messages[i18n.locale].resetPassword.title,
      },
    },

    // admin
    {
      path: '/events',
      name: 'adminEventsList',
      component: AdminEventsList,
      meta: {
        layout: 'Admin',
        title: i18n.messages[i18n.locale].adminEventsList.title,
      },
    },
    {
      path: '/events/:eventId',
      name: 'adminEventsView',
      component: AdminEventsView,
      props: true,
      meta: {
        layout: 'Admin',
        title: i18n.messages[i18n.locale].adminEventsView.title,
      },
    },

    // mobile
    {
      path: '/event-list',
      name: 'eventList',
      component: EventList,
      meta: {
        title: i18n.messages[i18n.locale].eventList.title,
      },
    },
    {
      path: '/event',
      name: 'eventView',
      component: EventView,
      props: true,
      redirect: { name: 'currentTactPage' },
      children: [
        {
          path: 'block/current',
          name: 'currentTactPage',
          component: CurrentTactPage,
          props: true,
        },
        {
          path: 'block/:tactId/details',
          name: 'tactDetails',
          component: TactDetails,
          props: true,
        },
        {
          path: 'program',
          name: 'programPage',
          component: ProgramPage,
          props: true,
        },
        {
          path: 'members',
          name: 'membersPage',
          component: MembersPage,
          props: true,
          redirect: { name: 'myGroup' },
          children: [
            {
              path: 'my-group',
              name: 'myGroup',
              component: MyGroup,
              props: true,
            },
            {
              path: 'all-members-tasks',
              name: 'allMembersTasks',
              component: AllMembersTasks,
              props: true,
            },
            {
              path: ':memberId/tasks',
              name: 'tasks',
              component: Tasks,
              props: true,
            },
            {
              path: ':memberId/:tactId/tasks',
              name: 'tasksByTactId',
              component: TasksByTactId,
              props: true,
            },
          ],
        },
      ],
    },

    // common
    {
      path: '/event/:eventId/join/:inviteCode',
      name: 'joinGroup',
      component: JoinGroupByInviteLink,
      props: true,
      meta: {
        layout: 'Blank',
      },
    },
    {
      path: '/:userId/:inviteCode',
      name: 'loginWithInviteCode',
      component: LoginWithInviteCode,
      props: true,
      meta: {
        layout: 'Blank',
      },
    },
    {
      path: '/verify',
      name: 'verifyEmail',
      component: VerifyEmail,
      props: true,
      meta: {
        layout: 'Blank',
      },
    },
    {
      path: '/access-denied',
      name: 'accessDenied',
      component: AccessDenied,
      meta: {
        layout: 'Blank',
        title: i18n.messages[i18n.locale].accessDenied.title,
      },
    },
    {
      path: '*',
      name: '404',
      component: PageNotFound,
      meta: {
        layout: 'Blank',
        title: i18n.messages[i18n.locale].pageNotFound.title,
      },
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { top: 0, left: 0 };
  },
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `Spaakl – ${to.meta.title}` : 'Spaakl';

  // todo сделать проверку если будем делать свитч для языков

  // let language = to.params.lang;
  //
  // if (!language) {
  //   language = 'en';
  // }
  //
  // i18n.locale = language;

  if (i18n.locale === 'ru') {
    moment.updateLocale(i18n.locale, {
      months: [
        'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня',
        'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря',
      ],
    });
  }

  if (location.origin === process.env.VUE_APP_ADMIN_BASE_URL) { // for manager.spaakl.com
    if (!hasToken() && to.meta.layout !== 'Blank') {
      next({ name: 'login' });
    } else if (hasToken() && to.meta.layout !== 'Admin' && to.name !== 'accessDenied') {
      next({ name: 'adminEventsList' });
    } else if (hasToken() && !store.getters['auth/isAdmin'] && to.name !== 'accessDenied' && to.name !== 'login') {
      next({ name: 'accessDenied' });
    } else {
      next();
    }
  } else if (location.origin === process.env.VUE_APP_APP_BASE_URL) { // for app.spaakl.com
    if (!hasToken() && to.meta.layout !== 'Blank') {
      next({ name: 'login' });
    } else if (hasToken() && to.meta.layout === 'Admin') {
      next({ name: 'eventView' });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
