import {
  login,
  loginWithInviteCode,
  me,
  register,
  requestPasswordReset,
  resetPassword,
} from '@/api/modules/auth';
import * as Types from '@/store/auth/mutation-types';
import {
  parseJwt,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '@/utils/system';

export default {
  logout({ commit }) {
    commit(Types.SET_ME, null);
    commit(Types.SET_IS_ADMIN, false);
    removeAccessToken();
    removeRefreshToken();
  },

  async register({ commit }, payload) {
    await register(payload);
  },

  async login({ commit }, payload) {
    const { data: { access_token, refresh_token } } = await login(payload);

    setAccessToken(access_token);
    setRefreshToken(refresh_token);
    const parsedToken = parseJwt(access_token);

    commit(Types.SET_IS_ADMIN, parsedToken.is_superuser);
  },

  async loginWithInviteCode({ commit }, payload) {
    const { data: { access_token, refresh_token } } = await loginWithInviteCode(payload);

    setAccessToken(access_token);
    setRefreshToken(refresh_token);
    const parsedToken = parseJwt(access_token);

    commit(Types.SET_IS_ADMIN, parsedToken.is_superuser);
  },

  async requestPasswordReset({ commit }, payload) {
    await requestPasswordReset(payload);
  },

  async resetPassword({ commit }, payload) {
    await resetPassword(payload);
  },

  async getMe({ commit }, payload) {
    const { data } = await me(payload);
    commit(Types.SET_ME, data);
  },

  async getMyRole({ commit }, payload) {
    const { data: { role } } = await me(payload);
    return role;
  },
};
