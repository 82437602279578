<template>
  <span>
    <slot @click="$refs.file.click()" />

    <input
      ref="file"
      class="hidden"
      type="file"
      name="imagesArray"
      multiple
      accept="image/*"
      @change="$emit('input', $refs.file.files)"
    >
  </span>
</template>

<script>
export default {
  name: 'FileUploadInput',
};
</script>

<style lang="scss" scoped>

</style>
